import "../ApiClient/TaskApiClient";
import {
    canSubmit as canSubmit_shared,
    canSwitchToGui as canSwitchToGui_shared
} from "./sharedStrategies";
import {
    init as init_taskApi,
    checkRequiredQueryParams as checkRequiredQueryParams_taskApi,
    fetchTasks as fetchTasks_taskApi,
    getInitialGuiType as getInitialGuiType_taskApi,
    submitTaskResults as submitTaskResults_taskApi,
    createTaskOutput as createTaskOutput_taskApi,
    createResultsSubmitObject as createResultsSubmitObject_taskApi,
    reconfigureStrategiesBasedOnGuiType as reconfigureStrategiesBasedOnGuiType_taskApi,
    hasImagesToPreload as hasImagesToPreload_taskApi,
    getQuestionsAmount as getQuestionsAmount_taskApi
} from "./taskApiWithMTurkSupportStrategies";
import {
    init as init_goliat,
    checkRequiredQueryParams as checkRequiredQueryParams_goliat,
    fetchTasks as fetchTasks_goliat,
    getInitialGuiType as getInitialGuiType_goliat,
    submitTaskResults as submitTaskResults_goliat,
    createTaskOutput as createTaskOutput_goliat,
    createResultsSubmitObject as createResultsSubmitObject_goliat,
    reconfigureStrategiesBasedOnGuiType as reconfigureStrategiesBasedOnGuiType_goliat,
    hasImagesToPreload as hasImagesToPreload_goliat,
    getQuestionsAmount as getQuestionsAmount_goliat,
    handleTasksSDKResult as handleTasksSDKResult_goliat
} from "./goliatStrategies";
import TaskUIContext from "./taskUIContext";

/**
 * When the request is from mturk (decided by queryparam "from_mturk") then build a context with methods that use the python task-api backend.
 * Otherwise it's assumed that the goliat api backend is being used and a context with methods that use that backend will be built.
 *
 * @param {URLSearchParams | MockQueryParams} params
 */
const taskUIContextFactory = params => {
    const fromMTurk = params.has("from_mturk");

    if (fromMTurk) {
        return createTaskApiTaskUIContext(params);
    } else {
        return createGoliatTaskUIContext(params);
    }
};

export const createTaskApiTaskUIContext = params => {
    return new TaskUIContext(
        params,
        fetchTasks_taskApi,
        submitTaskResults_taskApi,
        checkRequiredQueryParams_taskApi,
        init_taskApi,
        getInitialGuiType_taskApi,
        createTaskOutput_taskApi,
        createResultsSubmitObject_taskApi,
        reconfigureStrategiesBasedOnGuiType_taskApi,
        hasImagesToPreload_taskApi,
        canSubmit_shared,
        canSwitchToGui_shared,
        getQuestionsAmount_taskApi,
        () => {},
        () => ({}),
        () => {}
    );
};

export const createGoliatTaskUIContext = params => {
    return new TaskUIContext(
        params,
        fetchTasks_goliat,
        submitTaskResults_goliat,
        checkRequiredQueryParams_goliat,
        init_goliat,
        getInitialGuiType_goliat,
        createTaskOutput_goliat,
        createResultsSubmitObject_goliat,
        reconfigureStrategiesBasedOnGuiType_goliat,
        hasImagesToPreload_goliat,
        canSubmit_shared,
        canSwitchToGui_shared,
        getQuestionsAmount_goliat,
        handleTasksSDKResult_goliat,
        () => ({}),
        () => {}
    );
};

export default taskUIContextFactory;
