import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialShouldShowInstructions = true;

export const shouldShowInstructionsReducer = generateReducer(
    initialShouldShowInstructions,
    {
        [ActionTypes.SET_SHOULD_SHOW_INSTRUCTIONS]: (
            shouldShowInstructions,
            action
        ) => {
            return action.shouldShowInstructions;
        }
    }
);

export const getShouldShowInstructions = state =>
    state.global.shouldShowInstructions;
