import React from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";

import { WIDGETS } from "../../guiFactory";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import addTaskUIProps from "../TaskUI/addTaskUIProps";
import { createViewWithInstructions } from "../TaskUI/ViewWithInstructions";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

import "bootstrap/dist/css/bootstrap.min.css";
import "./PointcloudProjectionView.css";
import "rc-slider/assets/index.css";

import {
    AiOutlineArrowDown,
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineArrowUp
} from "react-icons/ai";

import { BsArrowUpRight, BsArrowDownLeft } from "react-icons/bs";
import { FaRedoAlt } from "react-icons/fa";

import PointcloudProjection from "../../components/PointcloudProjection/PointcloudProjection";
import addSubmitFunctionality from "../Submit/addSubmitFunctionality";
import { LOADING_TIME_RESOURCE_PREFIX } from "../../lib/TaskUIStrategy/goliatStrategies";
import { copyTextToClipboard } from "../../lib/qm_cs_lib";

/**
 * @augments {React.Component<Props, State>}
 */
class PointcloudProjectionView extends React.Component {
    constructor(props) {
        super(props);
        /**
         * @type {import("../TaskUI/addTaskUIProps").ConnectedTaskUIViewProps
         * & import("../TaskUI/ViewWithInstructions").ViewWithInstructionsProps
         * & import("../Submit/addSubmitFunctionality").SubmitFunctionalityProps}
         */
        // eslint-disable-next-line
        this.props;

        this.initialBevZoom = this.props.guiSettings.initial_bev_zoom
            ? this.props.guiSettings.initial_bev_zoom
            : 180;
        // we have to compute that here, because this is where we define the initial box dimensions
        const bboxUnit = 1024 / this.initialBevZoom / 8;

        // the changeable slider values
        this.defaultState = {
            submitDisabled: false,
            triggerSubmitOnUpdate: false,
            boxOrientation: 0,
            cloudOrientation: 0,
            boxWidthLeft: bboxUnit,
            boxWidthRight: bboxUnit,
            boxHeightTop: 1,
            boxHeightBot: 0,
            boxLengthTop: bboxUnit * 2,
            boxLengthBot: bboxUnit * 2,
            boxGround: 1,
            centerU: 0,
            centerV: 0,
            objView: null,
            objectMovment: true,
            showBox: { rgbView: false, bevView: false },
            guiType: null,
            guiInitialized: false,
            readyTimestamp: 0,
            guiIsResetting: false,
            showBev: true,
            title: "Hover around!",
            useBevActionCanvas: false,
            pointcloudView: "Bev",
            pointVisibility: "All"
        };
        this.state = { ...this.defaultState };

        this.boxDataAccessor = null;

        // must not mutate
        this.defaultAdditionalData = {
            object_is_corrupted: false,
            object_not_found: false
            // good_fit: false
        };

        // just for demonstration purposes
        this.direction = 1;
        this.addedRotation = 0;
    }

    resetState() {
        this.setState({ ...this.defaultState, guiIsResetting: true });
    }

    componentDidMount() {
        this.initGuiType();
        this.setState({ guiIsResetting: false });
    }

    componentDidUpdate() {
        if (this.state.guiIsResetting) {
            this.initGuiType();
            this.setState({ guiIsResetting: false });
        }

        // we have to submit on next render cycle because the finalSubmit prop
        // depends on data from redux store as props (taskResult), but the props are only updated
        // in the next cycle
        if (
            this.state.triggerSubmitOnUpdate &&
            this.props.taskResult !== null
        ) {
            this.setState({ triggerSubmitOnUpdate: false });
            this.props
                .finalSubmit()
                .then(() => {
                    this.setState({ submitDisabled: false });
                    this.props.setNextGuiType(WIDGETS.THANK_YOU);
                })
                .catch(err => console.error(err));
        }

        // handle timestamp update
        if (this.isReady() && this.state.readyTimestamp === 0) {
            // set only once when ready
            this.setState({ readyTimestamp: Date.now() });
        } else if (!this.isReady() && this.state.readyTimestamp !== 0) {
            // reset when not ready
            this.setState({ readyTimestamp: 0 });
        }
    }

    getCurrentTaskInput() {
        return this.props.task.task_inputs[this.props.currentQuestionIdx];
    }

    getInstructionsButton() {
        // the callback openInstructions and the bool hasInstructions will be injected by the higher order component ViewWithInstructions
        if (this.props.hasInstructions) {
            return (
                <button
                    className="button is-sm"
                    style={{ backgroundColor: "white", color: "black" }}
                    onClick={this.props.openInstructions}
                >
                    Instructions
                </button>
            );
        } else {
            return <></>;
        }
    }

    isReady() {
        return (
            this.props.task !== null &&
            this.props.guiSettings !== null &&
            this.props.taskUIContext.allResourcesForCurrentTaskInputInResourceCache(
                this.props.resourceCache,
                this.getCurrentTaskInput()
            )
        );
    }

    getCurrentImageObject() {
        const ti = this.getCurrentTaskInput();
        return this.props.resourceCache[ti.image_url];
    }

    // return a sum of the total resource loading time for the current task_input
    getResourceLoadingTimeOfCurrentTaskInput() {
        const ti = this.getCurrentTaskInput();
        const loadingTimes = [];
        loadingTimes.push(
            this.props.resourceCache[
                LOADING_TIME_RESOURCE_PREFIX + ti.pb_data_url
            ]
        );
        loadingTimes.push(
            this.props.resourceCache[
                LOADING_TIME_RESOURCE_PREFIX + ti.image_url
            ]
        );
        return loadingTimes.reduce((a, b) => a + b);
    }

    /**
     * Returns a callback function with injected additionalData.
     * Depends on whether submit should be instant or not.
     */
    getAnswerCallback(additionalData) {
        if (this.props.questionsAmount === 1) {
            return () => this.onSubmit(additionalData);
        }
        return () => this.addAnnotation(additionalData);
    }

    addAnnotation(additionalData) {
        if (this.props.currentQuestionIdx < this.props.questionsAmount) {
            const box = this.boxDataAccessor();
            const latestAnswer = {
                duration: Date.now() - this.state.readyTimestamp,
                load_duration: this.getResourceLoadingTimeOfCurrentTaskInput(),
                ...box,
                ...additionalData
            };
            this.props.addAnswerToStore(latestAnswer);
            console.log("Added Answer", latestAnswer);

            if (
                this.props.currentQuestionIdx + 1 ===
                this.props.questionsAmount
            ) {
                const resultsSubmitObject = this.props.taskUIContext.createResultsSubmitObject(
                    {
                        globalState: this.props.globalState,
                        answers: [...this.props.answers, latestAnswer]
                    }
                );
                this.props.setSubmitResultsObject(resultsSubmitObject);

                // when there is only one task, then SubmitView is not necessary!
                if (this.props.questionsAmount > 1) {
                    this.props.setNextGuiType(WIDGETS.SUBMIT);
                }
            } else {
                this.resetState();
                this.props.questionNext();
            }
        }
    }

    onBackClicked() {
        if (this.props.answers.length > 0) {
            const removedAnswer = this.props.answers[
                this.props.answers.length - 1
            ];
            console.log("Removed Answer", removedAnswer);
            this.props.popAnswerFromStore();
            this.resetState();
            this.props.questionPrevious();
        }
    }

    setBoxDataAccessor(boxDataAccessor) {
        this.boxDataAccessor = boxDataAccessor;
    }

    getProgressBar() {
        if (this.props.questionsAmount === 1) {
            return <></>;
        }
        return (
            <ProgressBar
                index={this.isReady() ? this.props.currentQuestionIdx + 1 : 0}
                amount={this.props.questionsAmount || 0}
            />
        );
    }

    onSubmit(additionalData) {
        this.setState({ submitDisabled: true });
        this.addAnnotation(additionalData);
        this.setState({ triggerSubmitOnUpdate: true });
    }

    getBadDataAnswerButtons() {
        return (
            <>
                <button
                    className="button is-sm "
                    style={{ backgroundColor: "#3c91a6", color: "#EEE" }}
                    onClick={this.getAnswerCallback({
                        ...this.defaultAdditionalData,
                        object_not_found: true
                    })}
                    disabled={this.state.submitDisabled}
                >
                    Object Not Found
                </button>
                <button
                    className="button is-sm "
                    style={{ backgroundColor: "#4087B0", color: "#EEE" }}
                    onClick={this.getAnswerCallback({
                        ...this.defaultAdditionalData,
                        object_is_corrupted: true
                    })}
                    disabled={this.state.submitDisabled}
                >
                    Corrupt Data
                </button>
            </>
        );
    }

    getSubmitButton() {
        // reduced functionality with only submit and instructions
        if (this.props.questionsAmount === 1) {
            return (
                <div className="submit_actions_container">
                    <button
                        className="button is-lg is-primary "
                        style={{
                            backgroundColor: " #009240",
                            color: "#EEE"
                        }}
                        onClick={() =>
                            this.onSubmit({ ...this.defaultAdditionalData })
                        }
                        disabled={this.state.submitDisabled}
                    >
                        Submit
                    </button>
                </div>
            );
        }

        // full functionality with back, next and instructions
        return (
            <>
                <div className="submit_actions_container">
                    <div>
                        <button
                            className="button is-lg"
                            style={{
                                backgroundColor: " #009240",
                                color: "#EEE"
                            }}
                            onClick={() =>
                                this.addAnnotation({
                                    ...this.defaultAdditionalData
                                })
                            }
                        >
                            Next
                        </button>
                    </div>
                    <div>
                        <button
                            className="button is-lg"
                            style={{
                                backgroundColor: "#E11",
                                color: "#EEE"
                            }}
                            onClick={this.onBackClicked.bind(this)}
                        >
                            Undo
                        </button>
                    </div>
                </div>
            </>
        );
    }

    initGuiType() {
        const guiType = this.props.guiSettings.sub_gui_type;
        let box = null;

        switch (guiType) {
            case "cog_gui":
                this.setState({
                    guiType: guiType,
                    renderGui: this.cogGuiFactory(),
                    objView: "cross",
                    objectMovment: true,
                    title:
                        "Hover around and mark the centre point of the object in the image on the right!"
                });
                break;

            case "slider_gui":
                box = this.getCurrentTaskInput().box_para;
                const sliderConfig = this.getCurrentTaskInput().slider_config;

                // all sliders deactivated --> use draggable box
                if (
                    Object.keys(sliderConfig).find(
                        key => sliderConfig[key] === true
                    ) === undefined
                ) {
                    this.setState({
                        centerW: box.center_w,
                        centerL: box.center_l,
                        showBox: { rgbView: true, bevView: true },
                        title:
                            "Adjust the proportions (height in left view) and orientation of the cube so it fits the object!"
                    });
                } else if (sliderConfig.height === false) {
                    // without height slider
                    this.setState({
                        centerW: box.center_w,
                        centerL: box.center_l,
                        showBox: { rgbView: false, bevView: true },
                        title:
                            "Adjust the sides of the cube so it fits the object!"
                    });
                } else if (
                    sliderConfig.height &&
                    sliderConfig.width === false
                ) {
                    // only height slider
                    this.setState({
                        centerW: box.center_w,
                        centerL: box.center_l,
                        boxWidthLeft: box.width / 2,
                        boxWidthRight: box.width / 2,
                        boxLengthBot: box.length / 2,
                        boxLengthTop: box.length / 2,
                        boxOrientation: (box.orientation_rad * 180) / Math.PI,
                        showBev: true,
                        showBox: { rgbView: true, bevView: true },
                        pointcloudView: "Front",
                        pointVisibility: "Box",
                        title:
                            "Adjust the height of the cube so it fits the object!"
                    });
                } else {
                    // full slider GUI
                    this.setState({
                        centerW: box.center_w,
                        centerL: box.center_l,
                        showBox: { rgbView: true, bevView: true },
                        title: "Adjust the cube so it fits the object!"
                    });
                }
                this.setState({
                    objectMovment: false,
                    objView: "box",
                    renderGui: this.sliderGuiFactory(sliderConfig),
                    guiType: guiType
                });
                break;

            case "button_gui":
                box = this.getCurrentTaskInput().box_para;
                this.setState({
                    renderGui: this.buttonGuiFactory(),
                    objectMovment: false,
                    objView: "box",
                    centerW: box.center_w,
                    centerL: box.center_l,
                    boxWidthLeft: box.width / 2,
                    boxWidthRight: box.width / 2,
                    boxLengthBot: box.length / 2,
                    boxLengthTop: box.length / 2,
                    boxHeightBot: box.height_0 / 2,
                    boxOrientation: (box.orientation_rad * 180) / Math.PI,
                    guiType: guiType,
                    showBox: { rgbView: true, bevView: true }
                });
                break;
            default:
                this.setState({
                    guiType: "default",
                    renderGui: this.sliderGuiFactory(),
                    objView: "box",
                    objectMovment: true
                });
                break;
        }
        console.log("Gui initialized");
        this.setState({ guiInitialized: true });
    }

    cogGuiFactory() {
        return (
            <>
                {this.getSubmitButton()}
                <div className="actions_container_pc">
                    {this.getBadDataAnswerButtons()}
                    {this.getInstructionsButton()}
                </div>
            </>
        );
    }

    buttonGuiFactory() {
        return (
            <>
                <div className="submit_actions_container">
                    <div>
                        <button
                            className="button is-sm"
                            style={{
                                backgroundColor: "#008811",
                                color: "#EEE"
                            }}
                            disabled={this.state.submitDisabled}
                            onClick={this.getAnswerCallback({
                                ...this.defaultAdditionalData
                                // good_fit: true
                            })}
                        >
                            Good
                        </button>
                    </div>
                    <div>
                        <button
                            className="button is-sm"
                            style={{ backgroundColor: "#E11", color: "#EEE" }}
                            onClick={this.getAnswerCallback({
                                ...this.defaultAdditionalData
                            })}
                        >
                            Bad
                        </button>
                    </div>
                    <div>
                        <button
                            className="button is-sm"
                            onClick={this.onBackClicked.bind(this)}
                        >
                            Undo
                        </button>
                    </div>
                </div>

                <div className="actions_container_pc">
                    {this.getBadDataAnswerButtons()}
                    {this.getInstructionsButton()}
                </div>
            </>
        );
    }

    getHeightSliders() {
        return [
            <div className="slider_container" key="slider_height_bottom">
                <div className="icon_container ">
                    <AiOutlineArrowDown />
                </div>
                <Slider
                    min={-200}
                    max={500}
                    defaultValue={this.state.boxHeightBot * 100}
                    onChange={value =>
                        this.setState({
                            boxHeightBot: value / 100
                        })
                    }
                />{" "}
            </div>,
            <div className="slider_container" key="slider_height_top">
                <div className="icon_container ">
                    <AiOutlineArrowUp />
                </div>
                <Slider
                    min={-200}
                    max={300}
                    defaultValue={this.state.boxHeightTop * 100}
                    onChange={value =>
                        this.setState({
                            boxHeightTop: value / 100
                        })
                    }
                />{" "}
            </div>
        ];
    }

    getWidthSliders() {
        return [
            <div className="slider_container" key="slider_width_left">
                <div className="icon_container ">
                    <AiOutlineArrowLeft />
                </div>
                <Slider
                    min={-500}
                    max={500}
                    defaultValue={this.state.boxWidthLeft * 100}
                    onChange={value =>
                        this.setState({
                            boxWidthLeft: value / 100
                        })
                    }
                />{" "}
            </div>,
            <div className="slider_container" key="slider_width_right">
                <div className="icon_container ">
                    <AiOutlineArrowRight />
                </div>
                <Slider
                    min={-500}
                    max={500}
                    defaultValue={this.state.boxWidthRight * 100}
                    onChange={value =>
                        this.setState({
                            boxWidthRight: value / 100
                        })
                    }
                />{" "}
            </div>
        ];
    }

    getLengthSliders() {
        return [
            <div className="slider_container" key="slider_length_front">
                <div className="icon_container ">
                    <BsArrowUpRight />
                </div>
                <Slider
                    min={-1100}
                    max={1100}
                    defaultValue={this.state.boxLengthTop * 100}
                    onChange={value =>
                        this.setState({
                            boxLengthTop: value / 100
                        })
                    }
                />{" "}
            </div>,
            <div className="slider_container" key="slider_length_back">
                <div className="icon_container ">
                    <BsArrowDownLeft />
                </div>
                <Slider
                    min={-1100}
                    max={1100}
                    defaultValue={this.state.boxLengthBot * 100}
                    onChange={value =>
                        this.setState({
                            boxLengthBot: value / 100
                        })
                    }
                />{" "}
            </div>
        ];
    }

    getOrientationSlider() {
        return (
            <div className="slider_container" key="slider_orientation">
                <div className="icon_container ">
                    <FaRedoAlt />
                </div>
                <Slider
                    min={-180}
                    max={180}
                    defaultValue={this.state.boxOrientation}
                    onChange={value =>
                        this.setState({
                            boxOrientation: value
                        })
                    }
                />
            </div>
        );
    }

    sliderGuiFactory(sliderConf) {
        let sliders = [];
        if (sliderConf.orientation) {
            sliders = sliders.concat(this.getOrientationSlider());
        }
        if (sliderConf.width) {
            sliders = sliders.concat(this.getWidthSliders());
        }
        if (sliderConf.length) {
            sliders = sliders.concat(this.getLengthSliders());
        }
        if (sliderConf.height) {
            sliders = sliders.concat(this.getHeightSliders());
        }

        if (sliders.length === 0) {
            this.setState({ useBevActionsCanvas: true });
        }

        return (
            <>
                {sliders}
                {this.getSubmitButton()}
                <div className="actions_container_pc">
                    {this.getBadDataAnswerButtons()}
                    {this.getInstructionsButton()}
                </div>
            </>
        );
    }

    enforceBoxUpdatesMinMax(update) {
        let adjustedUpdate = { ...update };
        const stateToUpdate = Object.keys(update)[0];
        const val = update[stateToUpdate];
        // boxOrientation doesn't need checked values
        switch (stateToUpdate) {
            case "boxWidthLeft":
            case "boxWidthRight":
                if (val > 5) {
                    adjustedUpdate[stateToUpdate] = 5;
                } else if (val < 0.1) {
                    adjustedUpdate[stateToUpdate] = 0.1;
                }
                break;
            case "boxLengthBot":
            case "boxLengthTop":
                if (val > 11) {
                    adjustedUpdate[stateToUpdate] = 11;
                } else if (val < 0.1) {
                    adjustedUpdate[stateToUpdate] = 0.1;
                }
                break;
            case "boxHeightBot":
                if (val > 5) {
                    adjustedUpdate[stateToUpdate] = 5;
                } else if (val < -2) {
                    adjustedUpdate[stateToUpdate] = -2;
                }
                break;
            case "boxHeightTop":
                if (val > 3) {
                    adjustedUpdate[stateToUpdate] = 3;
                } else if (val < -2) {
                    adjustedUpdate[stateToUpdate] = -2;
                }
                break;
            default:
                break;
        }
        return adjustedUpdate;
    }

    getDebugElements() {
        if (this.props.taskUIContext._params.has("debug_mode")) {
            return (
                <div className="debug_container_pc">
                    <button
                        onClick={e => {
                            const ti = this.getCurrentTaskInput();
                            copyTextToClipboard(JSON.stringify(ti, null, 4));
                        }}
                    >
                        task_input --> clipboard
                    </button>
                </div>
            );
        }
        return null;
    }

    render() {
        if (!this.isReady()) {
            return <></>;
        }

        const pb = this.getCurrentTaskInput().pb_data_url;
        const pcData = this.props.resourceCache[pb];

        if (this.state.guiInitialized) {
            // HACK otherwise it would load the init values before guit init
            return (
                <>
                    {this.getDebugElements()}
                    <div className={"container-qm container_pc"}>
                        <div className="image_container_pc">
                            <PointcloudProjection
                                // needs to be the same key while working on the same task_input
                                key={this.props.currentQuestionIdx}
                                pcData={pcData}
                                currentImageObject={this.getCurrentImageObject()}
                                boxWidthLeft={this.state.boxWidthLeft}
                                boxWidthRight={this.state.boxWidthRight}
                                boxHeightTop={this.state.boxHeightTop}
                                boxHeightBot={this.state.boxHeightBot}
                                boxLengthTop={this.state.boxLengthTop}
                                boxLengthBot={this.state.boxLengthBot}
                                boxOrientation={this.state.boxOrientation}
                                boxGround={this.state.boxGround}
                                objVis={this.state.objView}
                                centerW={this.state.centerW}
                                centerL={this.state.centerL}
                                objectMovement={this.state.objectMovment}
                                pointcloudView={this.state.pointcloudView}
                                setBoxDataAccessor={this.setBoxDataAccessor.bind(
                                    this
                                )}
                                showBox={this.state.showBox}
                                pointVisibility={this.state.pointVisibility}
                                guiType={this.state.guiType}
                                bevClickEnabled={
                                    this.state.guiType === "cog_gui"
                                }
                                showBev={this.state.showBev}
                                setSliderValue={update => {
                                    this.setState(update);
                                }}
                                useBevActionsCanvas={
                                    this.state.useBevActionsCanvas
                                }
                                bevZoomEnabled
                                bevZoomSettings={{
                                    initial: this.initialBevZoom,
                                    min: 10,
                                    max: 500
                                }}
                            />
                        </div>
                        <div className="progress_container_pc">
                            {this.getProgressBar()}
                        </div>
                        <div className="title_pc">
                            <h1>{this.state.title}</h1>
                        </div>

                        {this.state.renderGui}
                    </div>
                </>
            );
        } else {
            return (
                <div className={"container-qm container_pc"}>
                    <div className="progress_container_pc">
                        {this.getProgressBar()}
                    </div>
                    <div className="title_pc">
                        <h1>{this.state.title}</h1>
                    </div>

                    {this.state.renderGui}
                </div>
            );
        }
    }
}

PointcloudProjectionView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

PointcloudProjectionView = addTaskUIProps(PointcloudProjectionView);
PointcloudProjectionView = createViewWithInstructions(PointcloudProjectionView);
PointcloudProjectionView = addSubmitFunctionality(PointcloudProjectionView);

export default PointcloudProjectionView;
