import { JSONSerializable } from "../JSONSerializable";

/**
 * @class
 * @augments {JSONSerializable}
 */
export default class AudioPlayerRecordTaskOutput extends JSONSerializable {
    /**
     * id of the record
     *
     * @param {String} id
     * @returns {AudioPlayerRecordTaskOutput}
     */
    setId(id) {
        this.id = id;
        return this;
    }

    /**
     * spotify preview url of the record
     *
     * @param {String} url
     * @returns {AudioPlayerRecordTaskOutput}
     */
    setSpotifyPreviewUrl(url) {
        this.spotify_url = url;
        return this;
    }

    /**
     * answer key
     *
     * @param {String} answer
     * @returns {AudioPlayerRecordTaskOutput}
     */
    setAnswer(answer) {
        this.answer = answer;
        return this;
    }

    /**
     * duration in milliseconds for giving the answer
     *
     * @param {int} duration
     * @returns {AudioPlayerRecordTaskOutput}
     */
    setDuration(duration) {
        this.duration = duration;
        return this;
    }

    /**
     * how often the record was played in full
     *
     * @param {int} cnt
     * @returns {AudioPlayerRecordTaskOutput}
     */
    setFullyPlayedCount(cnt) {
        this.fully_played_cnt = cnt;
        return this;
    }

    /**
     * how often the playback for the record was started
     *
     * @param {int} cnt
     * @returns {AudioPlayerRecordTaskOutput}
     */
    setPlaybackStartedCount(cnt) {
        this.playback_started_cnt = cnt;
        return this;
    }
}
