import { ImageCache } from "../../../lib/ImageCache";
// eslint-disable-next-line
import { TaskApiClient } from "../../../lib/ApiClient/TaskApiClient";
// eslint-disable-next-line
import { MTurkSubmittedAssignment } from "../../../lib/ApiClient/models/MTurkSubmittedAssignment";

import {
    allImagesLoaded,
    mturkSubmittedAssignment,
    mturkSubmittedAssignmentSuccess,
    mturkSubmittedAssignmentError
} from ".";
// eslint-disable-next-line
import TaskUIContext from "../../../lib/TaskUIStrategy/taskUIContext";
// eslint-disable-next-line
import { JSONSerializable } from "../../../lib/ApiClient/JSONSerializable";

/**
 * @param {TaskUIContext} taskUIContext
 * @returns {Promise<String[]>} resolves to an array of image urls that can be used for caching
 */
export const fetchTasksWithTaskUIContext = taskUIContext => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            taskUIContext.fetchTasks(resolve, reject, dispatch);
        }).catch(err => console.log(err));
    };
};

/**
 * @param {String[]} imgUrls
 * @returns {Promise<Object[]>}
 */
export const loadImagesAction = imgUrls => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const imgCache = new ImageCache();
            imgCache
                .init(imgUrls)
                .then(imgObjects => {
                    dispatch(allImagesLoaded(imgCache.getCacheObject()));
                    resolve(imgObjects);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    };
};

/**
 * @param {TaskUIContext} taskUIContext
 * @param {JSONSerializable} taskResults - the exact taskResults and answer schemas depends on the guiType and guiSettings
 * @returns {Promise<Object>}
 */
export const submitTasksWithTaskUIContext = (
    taskUIContext,
    taskResults,
    mturkInfos
) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            taskUIContext.submitTaskResults(
                resolve,
                reject,
                dispatch,
                taskResults,
                mturkInfos
            );
        });
    };
};

/**
 * @param {TaskApiClient} apiClient
 * @param {MTurkSubmittedAssignment} mturkAssignment
 */
export const submitMTurkSubmittedAssignmentAction = (
    apiClient,
    mturkAssignment
) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(mturkSubmittedAssignment);
            apiClient.submitMTurkAssignment(mturkAssignment, (err, data) => {
                if (err) {
                    dispatch(mturkSubmittedAssignmentError);
                    console.error(err);
                    reject(err);
                } else if (data.ok) {
                    dispatch(mturkSubmittedAssignmentSuccess);
                    resolve(data);
                }
            });
        });
    };
};
