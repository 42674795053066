import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

import ActionButton from "../../components/TaskUI/ActionButtons/ActionButton";

const getButtonText = buttonConf => {
    let buttonText = "";
    if (buttonConf.action_type === "back") {
        buttonText = <FontAwesomeIcon icon={faUndo} />;
    } else {
        buttonText = buttonConf.text;
    }
    return buttonText;
};

const actionButtonFactory = (buttonConf, answerTypeCallbackMap, key) => {
    let onClick = e => {};
    if (buttonConf.action_type === "answer") {
        onClick = e => answerTypeCallbackMap.answer(buttonConf.answer_key);
    } else if (buttonConf.action_type === "back") {
        onClick = e => answerTypeCallbackMap.back();
    }

    const button = (
        <ActionButton
            key={key || 0}
            actionKey={buttonConf.action_key || null}
            onClick={onClick}
            style={{
                background: buttonConf.background_color,
                color: buttonConf.text_color,
                border: buttonConf.border || "none"
            }}
            className={buttonConf.class_name}
        >
            {getButtonText(buttonConf)}
        </ActionButton>
    );

    return button;
};

export default actionButtonFactory;
