import React from "react";
import PropTypes from "prop-types";

import "./SelectableSquare.css";

const classNames = require("classnames");

const SelectableSquare = ({ id, text, isBlue, isSelected, onSelect }) => {
    const squareClasses = classNames("testSquare", {
        blue: isBlue,
        selected: isSelected
    });

    const onSelectInner = () => {
        onSelect(id);
    };

    return (
        <div className={squareClasses} onClick={onSelectInner}>
            <div className="testSquareText">{text}</div>
        </div>
    );
};

SelectableSquare.defaultProps = {
    id: 0,
    text: "Hello world",
    isBlue: false,
    isSelected: false,
    onSelect: () => {}
};

SelectableSquare.propTypes = {
    id: PropTypes.number,
    text: PropTypes.string.isRequired,
    isBlue: PropTypes.bool,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func
};

export default SelectableSquare;
