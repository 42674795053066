import React from "react";

const findConnectingEdges = (object, point) => {
    const frontEdges = new Set([]);
    const edgeArray = [];
    object.forEach((element, index) => {
        if (point[2] === element[0][2]) {
            frontEdges.add(index);
            edgeArray.push(element[1]);
        } else if (point[2] === element[1][2]) {
            frontEdges.add(index);
            edgeArray.push(element[0]);
        }
    });

    // find the adjacent horizontal lines
    const verticalEdges = new Set([]);
    object.forEach((element, indx) => {
        edgeArray.forEach(edgePoint => {
            const startpoint = edgePoint;
            const endpoint = [edgePoint[0], 0, edgePoint[2], 1];

            if (
                element[0][2] === startpoint[2] &&
                element[1][2] === endpoint[2]
            ) {
                verticalEdges.add(indx);
            }
        });
    });

    const connectingEdges = [...frontEdges];
    // exclude the case where 2 lines have the same z value
    if (verticalEdges.size <= 6) {
        return [...connectingEdges, ...verticalEdges];
    }
    return connectingEdges;
};

export const defaultEdgeStyleSettings = {
    edgeInBackground: {
        color: "#C90000",
        opacity: 0.6,
        strokeWidth: 1,
        dasharray: ""
    },
    edgeInForeground: {
        color: "#F00",
        opacity: 1,
        strokeWidth: 1.5,
        dasharray: ""
    }
};

export const styleObjectEdges = (
    object,
    edgeStyleSettings = { ...defaultEdgeStyleSettings }
) => {
    const endpointArr = [];
    const edgeStyles = [...new Array(object.length)].map(() => ({
        ...edgeStyleSettings.edgeInBackground
    }));
    // exclude arrow
    if (object.length > 3 && object[0][0][0]) {
        object.forEach((element, index) => {
            endpointArr.push(element[1][2]);
        });
        // find point with max z (world coord)
        const n = endpointArr.indexOf(Math.max(...endpointArr));
        const endpoint = object[n][1];
        // get adjacent edges of point
        findConnectingEdges(object, endpoint).forEach(idx => {
            if (idx < 16) {
                edgeStyles[idx] = { ...edgeStyleSettings.edgeInForeground };
            } else {
                // seperate visualization for plane crosses
                edgeStyles[idx] = {
                    color: "red",
                    strokeWidth: 1,
                    opacity: 0.25,
                    dasharray: ""
                };
            }
        });
    }
    return edgeStyles;
};

export const createObjectSVGDynamic = (object, edgeStyles) => {
    const vis_grid = [];
    object.forEach((element, idx) =>
        vis_grid.push(
            <line
                key={idx}
                x1={Math.floor(element[0][0] || 0)}
                y1={Math.floor(element[0][1] || 0)}
                x2={Math.floor(element[1][0] || 0)}
                y2={Math.floor(element[1][1] || 0)}
                stroke={edgeStyles[idx].color}
                strokeWidth={edgeStyles[idx].strokeWidth}
                opacity={edgeStyles[idx].opacity}
                strokeLinejoin="round"
                strokeDasharray={edgeStyles[idx].dasharray}
            />
        )
    );
    return vis_grid;
};

export const createObjectSVG = (object, stroke, strokeWidth, opacity) => {
    const vis_grid = [];
    object.forEach((element, idx) =>
        vis_grid.push(
            <line
                key={idx}
                x1={Math.floor(element[0][0] || 0)}
                y1={Math.floor(element[0][1] || 0)}
                x2={Math.floor(element[1][0] || 0)}
                y2={Math.floor(element[1][1] || 0)}
                stroke={stroke}
                strokeWidth={strokeWidth}
                opacity={opacity}
            />
        )
    );
    return vis_grid;
};

export const drawObjectCanvas = (geometry, ctx) => {
    geometry.forEach(element => {
        ctx.beginPath();
        ctx.moveTo(element[0][0], element[0][1]);
        ctx.lineTo(element[1][0], element[1][1]);
        ctx.stroke();
    });
    return ctx;
};

export const svgCircle = (point, color) => {
    return (
        <circle
            cx={point[0]}
            cy={point[1]}
            r="5"
            stroke={color}
            strokeWidth="3"
        />
    );
};
