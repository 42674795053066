import "bootstrap/dist/css/bootstrap.min.css";
import "./AudioPlayerUIView.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";

import ProgressBar from "../../components/ProgressBar/ProgressBar";

import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";

import {
    captureTimestamp,
    setNextGuiType,
    addAnswerForQuestion,
    questionNext,
    setTaskResult,
    popAnswerForQuestion,
    questionPrevious
} from "../../modules/TaskUI/actions";

import { getTimestamp } from "../../modules/TaskUI/reducers/timestamp";
import { WIDGETS } from "../../guiFactory";
import { getAnswers } from "../../modules/TaskUI/reducers/answers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { createViewWithInstructions } from "../TaskUI/ViewWithInstructions";
import SpotifyAudioPlayer from "../../components/TaskUI/SpotifyAudioPlayer/SpotifyAudioPlayer";
import AudioPlayerRecordTaskOutput from "../../lib/ApiClient/models/AudioPlayerRecordTaskOutput";

/**
 * @augments {Component<Props, State>}
 */
class AudioPlayerUIView extends Component {
    componentDidMount() {
        this.props.captureTimestamp(Date.now());
    }

    getCurrentTaskInput() {
        if (this.isReady()) {
            return this.props.task.task_inputs.records[
                this.props.currentQuestionIdx
            ];
        }
        return null;
    }

    getProgressBarIndex() {
        if (this.props.taskUIContext.hasImagesToPreload()) {
            return this.props.allImagesLoaded
                ? this.props.currentQuestionIdx + 1
                : 0;
        } else {
            return this.props.currentQuestionIdx + 1;
        }
    }

    addAnswer(answer) {
        const currentTaskInput = this.getCurrentTaskInput();
        const nowMillis = Date.now();
        const answerObject = new AudioPlayerRecordTaskOutput();
        const cachedRecordData = this.getCachedRecordData();
        answerObject
            .setId(currentTaskInput.id)
            .setSpotifyPreviewUrl(currentTaskInput.spotify_url)
            .setAnswer(answer)
            .setDuration(
                nowMillis - this.props.currentAnswerStartTimestampMillis
            )
            .setFullyPlayedCount(cachedRecordData.fully_played_cnt)
            .setPlaybackStartedCount(cachedRecordData.playback_started_cnt);

        this.props.addAnswerToStore(answerObject);
        console.log("added answer", answerObject);
        return answerObject;
    }

    onBackClicked() {
        if (this.props.answers.length > 0) {
            const removedAnswer = this.props.answers[
                this.props.answers.length - 1
            ];
            console.log("removed answer", removedAnswer);
            this.props.popAnswerFromStore();
            this.props.questionPrevious();
        }
    }

    onAnswerButtonClicked(answer) {
        if (this.props.currentQuestionIdx < this.props.questionsAmount) {
            const latestAnswer = this.addAnswer(answer);

            if (
                this.props.currentQuestionIdx + 1 ===
                this.props.questionsAmount
            ) {
                const resultsSubmitObject = this.props.taskUIContext.createResultsSubmitObject(
                    {
                        globalState: this.props.globalState,
                        answers: [...this.props.answers, latestAnswer]
                    }
                );
                this.props.setSubmitResultsObject(resultsSubmitObject);
                this.props.setNextGuiType(WIDGETS.SUBMIT);
            } else {
                this.props.captureTimestamp(Date.now());
                this.props.questionNext();
            }
        }
    }

    getCachedRecordData() {
        const ti = this.getCurrentTaskInput();
        if (!this.props.taskUIContext.hasSpecificVar(ti.id)) {
            this.initCachedRecordData(ti.id, {
                playback_started_cnt: 0,
                fully_played_cnt: 0
            });
        }
        return this.props.taskUIContext.getSpecificVar(ti.id);
    }

    /**
     * @param {String} id id of the current task_inputs track
     * @param {Object} data
     * @param {int} data.fully_played_cnt
     * @param {int} data.playback_started_cnt
     */
    initCachedRecordData(id, data) {
        if (!this.props.taskUIContext.hasSpecificVar(id)) {
            this.props.taskUIContext.setSpecificVar(id, data);
        }
    }

    incrementCurrentRecordPlaybackStartedCnt() {
        const cachedRecordData = this.getCachedRecordData();
        if (cachedRecordData !== null && cachedRecordData !== undefined) {
            cachedRecordData.playback_started_cnt++;
        } else {
            this.initCachedRecordData(this.getCurrentTaskInput().id, {
                playback_started_cnt: 1,
                fully_played_cnt: 0
            });
        }
    }

    incrementCurrentRecordFullyPlayedCnt() {
        const cachedRecordData = this.getCachedRecordData();
        if (cachedRecordData !== null && cachedRecordData !== undefined) {
            cachedRecordData.fully_played_cnt++;
        } else {
            this.initCachedRecordData(this.getCurrentTaskInput().id, {
                playback_started_cnt: 0,
                fully_played_cnt: 1
            });
        }
    }

    renderText() {
        const emotional_label = `<span class="audioplayer_view_question_emotional_label">${
            this.getCurrentTaskInput().label
        }</span>`;
        const question = this.props.guiSettings.question.replace(
            "{emotional_label}",
            emotional_label
        );
        return (
            <h1>
                <span dangerouslySetInnerHTML={{ __html: question }}></span>{" "}
                {/* this prop will be injected by the higher order component ViewWithInstructions */}
                {this.props.showInstructionsButton}
            </h1>
        );
    }

    renderActions() {
        const buttonTooltip =
            "You need to have listened to the track before you can give an answer";

        const backButton = (
            <button
                className="audioplayer_view_back_button"
                onClick={this.onBackClicked.bind(this)}
            >
                <FontAwesomeIcon icon={faUndo} />
            </button>
        );

        const noButton = (
            <button
                className="audioplayer_view_no_button"
                onClick={() => this.onAnswerButtonClicked("no")}
                title={buttonTooltip}
            >
                No
            </button>
        );

        const yesButton = (
            <button
                className="audioplayer_view_yes_button"
                onClick={() => this.onAnswerButtonClicked("yes")}
                title={buttonTooltip}
            >
                Yes
            </button>
        );

        const unsureButton = (
            <button
                className="audioplayer_view_unsure_button"
                onClick={() => this.onAnswerButtonClicked("unsure")}
                title={buttonTooltip}
            >
                Unsure
            </button>
        );

        return (
            <>
                {backButton}
                {noButton}
                {yesButton}
                {unsureButton}
            </>
        );
    }

    isReady() {
        return this.props.task !== null && this.props.guiSettings !== null;
    }

    render() {
        if (!this.isReady()) {
            return <></>;
        }

        return (
            <div className="audioplayer_view_container_">
                <div className="audioplayer_view_question">
                    {this.renderText()}
                </div>
                <div className="audioplayer_view_content_container">
                    <div className="audioplayer_view_content">
                        <SpotifyAudioPlayer
                            record={this.getCurrentTaskInput()}
                            onPlaybackStarted={this.incrementCurrentRecordPlaybackStartedCnt.bind(
                                this
                            )}
                            onRecordFullyPlayed={this.incrementCurrentRecordFullyPlayedCnt.bind(
                                this
                            )}
                        />
                    </div>
                    {/* add a progress bar if you want */}
                    <div className="audioplayer_view_progressbar">
                        <ProgressBar
                            index={this.getProgressBarIndex()}
                            amount={this.props.questionsAmount || 0}
                        />
                    </div>
                    <div className="audioplayer_view_actions">
                        {this.renderActions()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    task: getTask(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    questionsAmount: getQuestionsAmount(state),
    guiSettings: getGuiSettings(state),
    currentAnswerStartTimestampMillis: getTimestamp(state),
    answers: getAnswers(state),
    globalState: state.global
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            setNextGuiType: setNextGuiType,
            addAnswerToStore: addAnswerForQuestion,
            popAnswerFromStore: popAnswerForQuestion,
            questionNext: questionNext,
            questionPrevious: questionPrevious,
            setSubmitResultsObject: setTaskResult
        },
        dispatch
    );

AudioPlayerUIView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext).isRequired
};

AudioPlayerUIView = connect(
    mapStateToProps,
    mapDispatchToProps
)(AudioPlayerUIView);

AudioPlayerUIView = createViewWithInstructions(AudioPlayerUIView);

export default AudioPlayerUIView;
