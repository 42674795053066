import React, { useEffect, useRef } from "react";

import {
    tryCatchFinally,
    drawAndLoadImageOnCanvas,
    drawPreloadedImageOnCanvas
} from "../../lib/qm_cs_lib.js";
import "./CanvasImage.css";

/**
 * Draws an image onto a canvas element.
 * You can either provide an image url (img_url), which will then be loaded
 * or a preloaded Image instance (preloaded_img).
 * preloaded_img will take precedence over img_url.
 * If neither is provided, the callback_canvas_draw_init is invoked
 * which allows the receiver to handle canvas drawing without
 * having CanvasImage draw an image unnecessarily.
 */
const CanvasImage = props => {
    const canvasRef = useRef();
    // enforce max width and max height
    const getWidthAndHeight = () => {
        let width = props.canvas_width;
        let height = props.canvas_height;
        return [width, height];
    };
    const [width, height] = getWidthAndHeight();
    const onClick = props.onClick;
    const onMouseMove = props.onMouseMove;
    const onMouseDown = props.onMouseDown;
    const onMouseUp = props.onMouseUp;
    const onMouseOut = props.onMouseOut;
    const onWheel = props.onWheel;
    const style = props.style;

    // this is called after the first (react-) render of CanvasImage
    // --> will draw the image (preloaded object or from src) and then call a callback to
    //     allow further modification of the canvas after the image was drawn
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const callbackAfterImageDrawFinish = img => {
            tryCatchFinally(() =>
                props.callback_after_draw_finish(canvas, ctx, img)
            );
        };

        if (props.img_url !== "" && props.preloaded_img === null) {
            drawAndLoadImageOnCanvas(
                canvas,
                ctx,
                props.img_url,
                props.resize_img_to_canvas_dimensions,
                callbackAfterImageDrawFinish
            );
        } else if (props.preloaded_img !== null) {
            drawPreloadedImageOnCanvas(
                canvas,
                ctx,
                props.preloaded_img,
                props.resize_img_to_canvas_dimensions,
                callbackAfterImageDrawFinish
            );
        } else {
            tryCatchFinally(() => {
                props.callback_canvas_draw(canvas, ctx, props.name);
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
            onClick={onClick}
            onMouseMove={onMouseMove}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseOut={onMouseOut}
            onWheel={onWheel}
            className={props.className !== "" ? props.className : "img_canvas"}
            style={style}
        ></canvas>
    );
};

CanvasImage.defaultProps = {
    name: "",
    className: "",
    canvas_width: 200,
    canvas_height: 200,
    max_width: 640,
    max_height: 400,
    resize_img_to_canvas_dimensions: false,
    img_url: "",
    preloaded_img: null,
    onClick: null,
    onMouseMove: null,
    onMouseDown: null,
    onMouseUp: null,
    onMouseOut: null,
    onWheel: null,
    style: {},
    // callback for additional actions after drawing the Image
    // also returns the Image object used to drawn on canvas (contains original image width and height)
    callback_after_draw_finish: (canvas, ctx, img) => {
        ctx.strokeRect(0, 0, canvas.width, canvas.height);
    },
    callback_canvas_draw: (canvas, ctx, name) => {}
};

export default CanvasImage;
