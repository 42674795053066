import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialNextGuiType = null;

/**
 * The gui type that will be loaded next by TaskUI
 */
export const nextGuiTypeReducer = generateReducer(initialNextGuiType, {
    [ActionTypes.SET_NEXT_GUI_TYPE]: (nextGuiType, action) => {
        return action.nextGuiType;
    }
});

export const getNextGuiType = state => state.global.nextGuiType;
