import { combineReducers } from "redux";

/**
 * Root reducer combiner. Runs every time a module is loaded.
 * Enables merging the state of a module into a different module's state
 *
 * @param {Map<String, function(state, action): Object} reducerMap
 */
export const rootReducerCombiner = reducerMap => {
    let mergedReducerMap = { ...reducerMap };
    for (const key of Object.keys(reducerMap)) {
        mergeReducerMapsIfNecessary(reducerMap, mergedReducerMap, key);
    }
    return combineReducers(mergedReducerMap);
};

/**
 * @param {Object<String, function(state, action): Object} originalReducerMap
 * @param {Object<String, function(state, action): Object} targetReducerMap
 * @param {String} key
 */
const mergeReducerMapsIfNecessary = (
    originalReducerMap,
    targetReducerMap,
    key
) => {
    const reducerConfig = originalReducerMap[key];
    if (reducerConfig.mergeIntoOtherReducerState !== undefined) {
        if (
            !originalReducerMap.hasOwnProperty(
                reducerConfig.mergeIntoOtherReducerState
            ) ||
            reducerConfig.reducer === undefined
        ) {
            console.error(
                "cannot merge state for " + key + ". Its state will be ignored."
            );
            return;
        }

        const targetReducer =
            originalReducerMap[reducerConfig.mergeIntoOtherReducerState];
        targetReducerMap[reducerConfig.mergeIntoOtherReducerState] = (
            state = {},
            action
        ) => {
            return {
                ...targetReducer(state, action),
                [key]: reducerConfig.reducer(state, action)
            };
        };
        delete targetReducerMap[key];
    }
};
