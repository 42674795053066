import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialImgCache = {
    finishedLoading: false
};

export const imgCacheReducer = generateReducer(initialImgCache, {
    [ActionTypes.ALL_IMAGES_LOADED]: (imgCache, action) => {
        return { ...action.imgCache, finishedLoading: true };
    }
});

export const getImgCache = state => {
    return state.global.imgCache;
};
export const allImagesLoaded = state => state.global.imgCache.finishedLoading;
