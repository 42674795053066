import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import {
    fetchTasksWithTaskUIContext,
    loadImagesAction
} from "../../modules/TaskUI/actions/asyncActions";
import guiFactory from "../../guiFactory";
import { getNextGuiType } from "../../modules/TaskUI/reducers/nextGuiType";
import { setGuiType } from "../../modules/TaskUI/actions";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import MTurkPreviewBanner from "../../components/MTurkPreviewBanner/MTurkPreviewBanner";

/**
 * @augments {React.Component<Props, State>}
 * @class
 */
class TaskUI extends React.Component {
    constructor(props) {
        super(props);
        // get the initial guiType
        this.gui = guiFactory(this.props.taskUIContext);
        this.props.setGuiType(this.gui.name);
        this.state = {
            guiContainer: this.gui.container
        };
    }

    componentDidMount() {
        this.props.fetchTasks(this.props.taskUIContext);
    }

    componentDidUpdate() {
        if (
            this.props.nextGuiType !== null &&
            this.gui.name !== this.props.nextGuiType
        ) {
            const newGui = guiFactory(
                this.props.taskUIContext,
                this.props.nextGuiType,
                this.props.globalState
            );

            if (newGui !== null) {
                this.gui = newGui;
                this.setState({
                    guiContainer: this.gui.container
                });
            }
        }
    }

    render() {
        return (
            <>
                <MTurkPreviewBanner
                    mturkHelper={this.props.taskUIContext.getSpecificVar(
                        "mturkHelper"
                    )}
                />
                {this.state.guiContainer}
            </>
        );
    }
}

const mapStateToProps = state => ({
    nextGuiType: getNextGuiType(state),
    globalState: state.global
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchTasks: fetchTasksWithTaskUIContext,
            loadImages: loadImagesAction,
            setGuiType: setGuiType
        },
        dispatch
    );

TaskUI = connect(mapStateToProps, mapDispatchToProps)(TaskUI);
TaskUI.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};
export default TaskUI;
