import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";
import { getTimestamp } from "../../modules/TaskUI/reducers/timestamp";
import { getAnswers } from "../../modules/TaskUI/reducers/answers";
import { getResourceCache } from "../../modules/TaskUI/reducers/resourceCache";
import {
    captureTimestamp,
    setNextGuiType,
    addAnswerForQuestion,
    questionNext,
    setTaskResult,
    popAnswerForQuestion,
    questionPrevious,
    setShouldShowInstructions,
    addResourceToCache
} from "../../modules/TaskUI/actions";
import { getTaskResult } from "../../modules/TaskUI/reducers/taskResult";
import { getMTurkInfos } from "../../modules/TaskUI/reducers/mturkInfos";
import { getGuiType } from "../../modules/TaskUI/reducers/guiType";
import { submitTasksWithTaskUIContext } from "../../modules/TaskUI/actions/asyncActions";
import { getTaskSubmitRequest } from "../../modules/TaskUI/reducers/taskSubmitRequest";

const TaskUIViewWrapper = props => {
    const { render: InnerComponent, innerProps, ...outerProps } = props;
    return <InnerComponent {...outerProps} {...innerProps} />;
};

const mapStateToProps = state => ({
    task: getTask(state),
    taskResult: getTaskResult(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    questionsAmount: getQuestionsAmount(state),
    guiSettings: getGuiSettings(state),
    currentAnswerStartTimestampMillis: getTimestamp(state),
    answers: getAnswers(state),
    globalState: state.global,
    resourceCache: getResourceCache(state),
    mturkInfos: getMTurkInfos(state),
    guiType: getGuiType(state),
    taskSubmitRequest: getTaskSubmitRequest(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            addAnswerToStore: addAnswerForQuestion,
            popAnswerFromStore: popAnswerForQuestion,
            questionNext: questionNext,
            questionPrevious: questionPrevious,
            setSubmitResultsObject: setTaskResult,
            setNextGuiType: setNextGuiType,
            submitTasks: submitTasksWithTaskUIContext,
            setShouldShowInstructions: setShouldShowInstructions,
            addResourceToCache: addResourceToCache
        },
        dispatch
    );

const ConnectedTaskUIViewWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskUIViewWrapper);

/**
 * @typedef ConnectedTaskUIViewProps
 * @property {import("../../lib/TaskUIStrategy/taskUIContext").default} taskUIContext
 * @property {Object} task
 * @property {Object} taskResult
 * @property {number} currentQuestionIdx
 * @property {number} questionsAmount
 * @property {Object} guiSettings
 * @property {number} currentAnswerStartTimestampMillis
 * @property {Array} answers
 * @property {Object} globalState
 * @property {Object} resourceCache
 * @property {Object} mturkInfos
 * @property {string} guiType
 * @property {Object} taskSubmitRequest
 * @property {Function} captureTimestamp
 * @property {Function} addAnswerToStore
 * @property {Function} popAnswerFromStore
 * @property {Function} questionNext
 * @property {Function} questionPrevious
 * @property {Function} setSubmitResultsObject
 * @property {Function} setNextGuiType
 * @property {Function} submitTasks
 * @property {Function} setShouldShowInstructions
 * @property {Function} addResourceToCache
 */

const addTaskUIProps = WrappedComponent => {
    return props => (
        <ConnectedTaskUIViewWrapper
            render={WrappedComponent}
            innerProps={{ ...props }}
        />
    );
};

export default addTaskUIProps;
