import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import Image from "./Image";
import Actions from "./Actions";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { captureTimestamp } from "../../modules/TaskUI/actions";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { allImagesLoaded } from "../../modules/TaskUI/reducers/imgCache";

import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";
import { createViewWithInstructions } from "../TaskUI/ViewWithInstructions";
import ActionButton from "../../components/TaskUI/ActionButtons/ActionButton";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/YesNoMaybeView.css";

/**
 * @augments {React.Component<Props, State>}
 */
class YesNoMaybeView extends React.Component {
    constructor(props) {
        super(props);
        this.actionsRef = React.createRef();
    }

    componentDidMount() {
        this.props.captureTimestamp(Date.now());
    }

    getTitleFromGuiSettings() {
        if (this.props.guiSettings !== null) {
            return this.props.guiSettings.question;
        }
    }

    getInstructionsButton() {
        // the callback openInstructions and the bool hasInstructions will be injected by the higher order component ViewWithInstructions
        if (this.props.hasInstructions) {
            return (
                <button
                    className="button is-sm"
                    onClick={this.props.openInstructions}
                >
                    Instructions
                </button>
            );
        } else {
            return <></>;
        }
    }

    render() {
        return (
            <div className={"container-qm container-ynm"}>
                <div className="image_container">
                    {/* TODO: the actual displaying of the task_input (currently only Image component) depends on the guiType
                        and should be served by the guiFactory as a prop instead */}
                    <Image taskUIContext={this.props.taskUIContext} />
                </div>

                <div className="progressBar_ynm">
                    {/* TODO: only when there are images to be loaded will the allImagesLoaded prop make sense... */}
                    <ProgressBar
                        index={
                            this.props.allImagesLoaded
                                ? this.props.currentQuestionIdx + 1
                                : 0
                        }
                        amount={this.props.questionsAmount || 0}
                    />
                </div>

                <div className="header">
                    <p>{this.getTitleFromGuiSettings()}</p>
                </div>

                <div className="actions">
                    <Actions
                        ref={this.actionsRef}
                        taskUIContext={this.props.taskUIContext}
                    />
                </div>

                <div className="meta-actions">
                    <div>
                        <ActionButton
                            className="button is-sm"
                            actionKey="q"
                            onClick={() => this.actionsRef.current.handleBack()}
                        >
                            Undo
                        </ActionButton>
                        {this.getInstructionsButton()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentQuestionIdx: getCurrentQuestionIdx(state),
    questionsAmount: getQuestionsAmount(state),
    guiSettings: getGuiSettings(state),
    allImagesLoaded: allImagesLoaded(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp
        },
        dispatch
    );

YesNoMaybeView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

YesNoMaybeView = connect(mapStateToProps, mapDispatchToProps)(YesNoMaybeView);
YesNoMaybeView = createViewWithInstructions(YesNoMaybeView);

export default YesNoMaybeView;
