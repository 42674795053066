import React from "react";
import PropTypes from "prop-types";

import { MTurkHelper } from "../../lib/mmturkey";
import "./MTurkPreviewBanner.css";

const MTurkPreviewBanner = props => {
    if (
        props.mturkHelper !== undefined &&
        props.mturkHelper !== null &&
        props.mturkHelper.shouldShowPreviewWarning()
    ) {
        return (
            <div className="previewInfoContainer">
                <div className="previewInfoText">
                    This is the preview mode. You won't be able to submit the
                    HIT without accepting it.
                </div>
            </div>
        );
    }
    return <></>;
};

MTurkPreviewBanner.propTypes = {
    mturkHelper: PropTypes.instanceOf(MTurkHelper)
};

export default MTurkPreviewBanner;
