import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import KeypointSegmentCanvas from "../../components/KeypointSegmentCanvas/KeypointSegmentCanvas";
import { createViewWithInstructions } from "../TaskUI/ViewWithInstructions";
import { WIDGETS } from "../../guiFactory";

import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";
import { getTimestamp } from "../../modules/TaskUI/reducers/timestamp";
import { getAnswers } from "../../modules/TaskUI/reducers/answers";
import { getImgCache } from "../../modules/TaskUI/reducers/imgCache";
import {
    captureTimestamp,
    setNextGuiType,
    addAnswerForQuestion,
    questionNext,
    setTaskResult,
    popAnswerForQuestion,
    questionPrevious,
    allImagesLoaded
} from "../../modules/TaskUI/actions";

import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./KeypointSelectionView.css";

/**
 * @augments {React.Component<Props, State>}
 */
class KeypointSelectionView extends React.Component {
    componentDidMount() {
        this.props.captureTimestamp(Date.now());
    }

    getCurrentTaskInput() {
        if (this.isReady()) {
            return this.props.task.task_inputs[this.props.currentQuestionIdx];
        }
        return null;
    }

    getTitleFromGuiSettings() {
        if (this.props.guiSettings !== null) {
            return this.props.guiSettings.question;
        }
    }

    getInstructionsButton() {
        // the callback openInstructions and the bool hasInstructions will be injected by the higher order component ViewWithInstructions
        if (this.props.hasInstructions) {
            return (
                <button
                    className="button is-sm"
                    onClick={this.props.openInstructions}
                >
                    Instructions
                </button>
            );
        } else {
            return <></>;
        }
    }

    isReady() {
        return this.props.task !== null && this.props.guiSettings !== null;
    }

    getCurrentImageObject() {
        return this.props.imgCache[
            this.props.taskUIContext.getCurrentImageUrl(this.props.globalState)
        ];
    }

    getCanvasWithCurrentImage() {
        if (this.props.allImagesLoaded) {
            const currentImage = this.getCurrentImageObject();
            return (
                <KeypointSegmentCanvas
                    img_url={currentImage.src}
                    canvas_width={275}
                    canvas_height={400}
                    // keypoint={{x: 1, y: 2}}  // takes an optional keypoint
                />
            );
        } else {
            return <Spinner animation={"border"} variant={"secondary"} />;
        }
    }

    // TODO
    addKeypoint(pos) {
        // - create an answer object
        // - add the answer object to the store using the this.props.addAnswerToStore function
        // - if all task_inputs have answers now:
        //      - create results submit object with taskUIContext, because that depends on the backend that's used
        //      - add the result submit object to the store
        //      - load the SubmitView
        // - else there are more task_inputs:
        //      - capture a timestamp
        //      - load the next task_input

        if (this.props.currentQuestionIdx < this.props.questionsAmount) {
            const currentTaskInput = this.getCurrentTaskInput();
            const nowMillis = Date.now();
            const latestAnswer = {
                pos_2d: pos,
                image_url: currentTaskInput.image_url,
                duration:
                    nowMillis - this.props.currentAnswerStartTimestampMillis
            };
            this.props.addAnswerToStore(latestAnswer);
            console.log("added answer", latestAnswer);

            if (
                this.props.currentQuestionIdx + 1 ===
                this.props.questionsAmount
            ) {
                const resultsSubmitObject = this.props.taskUIContext.createResultsSubmitObject(
                    {
                        globalState: this.props.globalState,
                        answers: [...this.props.answers]
                    }
                );
                this.props.setSubmitResultsObject(resultsSubmitObject);
                this.props.setNextGuiType(WIDGETS.SUBMIT);
            } else {
                this.props.captureTimestamp(Date.now());
                this.props.questionNext();
            }
        }
    }

    // TODO
    onBackClicked() {
        console.log("back clicked");
        // - pop the previous task_input's answer from the answer stack
        // - load the previous task_input

        if (this.props.answers.length > 0) {
            const removedAnswer = this.props.answers[
                this.props.answers.length - 1
            ];
            console.log("removed answer", removedAnswer);
            this.props.popAnswerFromStore();
            this.props.questionPrevious();
            this.props.captureTimestamp(Date.now());
        }
    }

    render() {
        if (!this.isReady()) {
            return <></>;
        }

        return (
            <div className={"container-qm container_ks"}>
                <div className="image_container_ks">
                    {this.getCanvasWithCurrentImage()}
                    <p style={{ color: "red" }}>
                        TODO: Display the image in a canvas to capture the
                        actual click position (not for the codelab)
                    </p>
                </div>
                <div className="title_ks">
                    <h1>{this.getTitleFromGuiSettings()}</h1>
                </div>
                <div className="progress_container_ks">
                    <p>
                        <span style={{ float: "left" }}>
                            Current image:{" "}
                            <span style={{ color: "green" }}>
                                {this.props.currentQuestionIdx + 1}
                            </span>
                        </span>
                        <span style={{ float: "right" }}>
                            Total images:{" "}
                            <span style={{ color: "green" }}>
                                {this.props.questionsAmount}
                            </span>
                        </span>
                    </p>
                </div>
                <div className="actions_container_ks">
                    <button
                        className="button is-sm"
                        onClick={this.onBackClicked.bind(this)}
                    >
                        Undo
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    task: getTask(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    questionsAmount: getQuestionsAmount(state),
    guiSettings: getGuiSettings(state),
    allImagesLoaded: allImagesLoaded(state),
    currentAnswerStartTimestampMillis: getTimestamp(state),
    answers: getAnswers(state),
    imgCache: getImgCache(state),
    globalState: state.global
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            addAnswerToStore: addAnswerForQuestion,
            popAnswerFromStore: popAnswerForQuestion,
            questionNext: questionNext,
            questionPrevious: questionPrevious,
            setSubmitResultsObject: setTaskResult,
            setNextGuiType: setNextGuiType
        },
        dispatch
    );

KeypointSelectionView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

KeypointSelectionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(KeypointSelectionView);
KeypointSelectionView = createViewWithInstructions(KeypointSelectionView);

export default KeypointSelectionView;
