// eslint-disable-next-line
import { JSONSerializable } from "./JSONSerializable";
import ApiClient from "./ApiClient";

/**
 * @typedef {import('./ApiClient').ApiClientOptions} ApiClientOptions
 *
 * @typedef {Object} GoliatApiClientOptions
 * @property {string} vendor_id
 * @property {string} vendor_user_id
 * @property {string=} project_id optional
 * @property {string=} project_node_id optional
 */

/**
 * Class for accessing the JSON Api of TaskApi
 * @class
 * @augments {ApiClient}
 */
export class GoliatApiClient extends ApiClient {
    static VERSION = "v1";

    /**
     * @type {ApiClientOptions & GoliatApiClientOptions}
     */
    options;

    /**
     * @param {ApiClientOptions & GoliatApiClientOptions} options
     */
    constructor(options) {
        super(options);
        this.options.endpoint += "/" + GoliatApiClient.VERSION;
    }

    /**
     * TODO
     */
    authenticate() {}

    /**
     * In the future this api call will transmit user information
     *
     * @param {apiCallCallback} callback
     */
    getTaskGroup(callback) {
        const requestObject = {
            vendor_id: this.options.vendor_id,
            vendor_user_id: this.options.vendor_user_id
        };

        // use either project_id or project_node_id: never both
        if (this.options.project_id !== null) {
            requestObject.project_id = this.options.project_id;
        } else if (this.options.project_node_id !== null) {
            requestObject.project_node_id = this.options.project_node_id;
        }

        const url = this.getUrlWithParams(
            "/task/get_task_group",
            requestObject
        );
        console.log(url);
        this.makeApiCall(url, callback);
    }

    /**
     * @param {SubmitTaskGroupRequest} submitTaskGroupRequest
     * @param {apiCallCallback} callback
     */
    submitTaskGroupResults(submitTaskGroupRequest, callback) {
        const url = this.getUrl("/task/submit_task_group_results");
        this.makeApiCall(url, callback, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(submitTaskGroupRequest)
        });
    }
}
