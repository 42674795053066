import { JSONSerializable } from "../JSONSerializable";

/**
 * Represents an mturk submitted assignment for the task api
 * @class
 * @augments {JSONSerializable}
 */
export class MTurkSubmittedAssignment extends JSONSerializable {
    /**
     * @param {String} taskId
     * @returns {MTurkSubmittedAssignment}
     */
    setTaskId(taskId) {
        this.task_id = taskId;
        return this;
    }

    /**
     * @param {String} assignmentId
     * @returns {MTurkSubmittedAssignment}
     */
    setAssignmentId(assignmentId) {
        this.assignmentId = assignmentId;
        return this;
    }

    /**
     * @param {String} HITId
     * @returns {MTurkSubmittedAssignment}
     */
    setHITId(HITId) {
        this.hitId = HITId;
        return this;
    }

    /**
     * @param {String} workerId
     * @returns {MTurkSubmittedAssignment}
     */
    setWorkerId(workerId) {
        this.workerId = workerId;
        return this;
    }
}
