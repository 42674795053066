import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import InstructionsView from "./InstructionsView";
import {
    captureTimestamp,
    setShouldShowInstructions
} from "../../modules/TaskUI/actions";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getShouldShowInstructions } from "../../modules/TaskUI/reducers/shouldShowInstructions";

/**
 * This component will receive the view component that should be displayed along with its props.
 * It will inject a showInstructionsButton and the corresponding callback openInstructions (to allow for more freedom)
 * into that view as a prop along with its original props.
 *
 * @augments {React.Component<Props, State>}
 */
class ViewWithInstructions extends React.Component {
    componentDidMount() {
        this.props.captureTimestamp(Date.now());
    }

    onCloseInstructions() {
        // start measuring time every time the instructions are closed
        this.props.captureTimestamp(Date.now());
        this.props.setShouldShowInstructions(false);
    }

    onOpenInstructions() {
        this.props.setShouldShowInstructions(true);
    }

    hasInstructions() {
        return (
            this.props.guiSettings !== null &&
            this.props.guiSettings.instructions !== undefined &&
            this.props.guiSettings.instructions !== null &&
            !this.shouldFullyDisableInstructions()
        );
    }

    // this is an override for fully disabling the instructions
    shouldFullyDisableInstructions() {
        if (this.props.guiSettings !== null) {
            const hide = this.props.guiSettings.disable_instructions;
            if (hide !== undefined && hide !== null) {
                return hide;
            }
        }
        return false;
    }

    shouldShowInstructions() {
        return this.hasInstructions() && this.props.showInstructions;
    }

    getShowInstructionsButton() {
        if (this.hasInstructions()) {
            return (
                <Button
                    variant="outline-primary"
                    onClick={this.onOpenInstructions.bind(this)}
                >
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                </Button>
            );
        }
        return <></>;
    }

    render() {
        // calling the prop "render" is not strictly necessary, but it's good style as this is an indication that
        // the prop is a react component that should be rendered
        const { render: InnerComponent, innerProps } = this.props;
        if (
            !this.shouldFullyDisableInstructions() &&
            this.shouldShowInstructions()
        ) {
            return (
                <InstructionsView
                    onCloseInstructions={this.onCloseInstructions.bind(this)}
                />
            );
        } else {
            const outerProps = {};
            if (this.shouldFullyDisableInstructions()) {
                const printErr = () => {
                    console.error(
                        "instructions are disabled as defined in the gui_settings"
                    );
                    return null;
                };
                outerProps.showInstructionsButton = printErr;
                outerProps.openInstructions = printErr;
            } else {
                outerProps.showInstructionsButton = this.getShowInstructionsButton();
                outerProps.openInstructions = this.onOpenInstructions.bind(
                    this
                );
            }

            return (
                <InnerComponent
                    {...innerProps}
                    {...outerProps}
                    hasInstructions={this.hasInstructions()}
                />
            );
        }
    }
}

const mapStateToProps = state => ({
    guiSettings: getGuiSettings(state),
    showInstructions: getShouldShowInstructions(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            setShouldShowInstructions: setShouldShowInstructions
        },
        dispatch
    );

ViewWithInstructions.propTypes = {
    render: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.node,
        PropTypes.func,
        PropTypes.elementType
    ]).isRequired,
    innerProps: PropTypes.object
};

ViewWithInstructions = connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewWithInstructions);

/**
 * @typedef ViewWithInstructionsProps
 * @property {boolean} hasInstructions
 * @property {Function} showInstructionsButton
 * @property {Function} openInstructions
 */

export const createViewWithInstructions = WrappedComponent => {
    return props => (
        <ViewWithInstructions
            render={WrappedComponent}
            innerProps={{ ...props }}
        />
    );
};

export default ViewWithInstructions;
