import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import PropTypes from "prop-types";

import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import {
    allImagesLoaded,
    getImgCache
} from "../../modules/TaskUI/reducers/imgCache";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import DomNode from "../../components/DomNode/DomNode";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";

/**
 * @augments {Component<Props, State>}
 */
class Image extends Component {
    getImage() {
        if (this.props.allImagesLoaded) {
            const currentImage = this.props.imgCache[
                this.props.taskUIContext.getCurrentImageUrl(
                    this.props.globalState
                )
            ];
            this.adjustImage(currentImage);

            // add image as img tag by adding the Image Object directly to dom
            return <DomNode node={currentImage} />;
        }
        return <Spinner animation={"border"} variant={"secondary"} />;
    }

    /**
     * @param {HTMLImageElement} image
     */
    adjustImage(image) {
        if (this.props.guiSettings.width !== undefined) {
            image.width = this.props.guiSettings.width;
        }
        if (this.props.guiSettings.height !== undefined) {
            image.height = this.props.guiSettings.height;
        }
    }

    render() {
        return <>{this.getImage()}</>;
    }
}

const mapStateToProps = state => ({
    task: getTask(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    allImagesLoaded: allImagesLoaded(state),
    imgCache: getImgCache(state),
    guiSettings: getGuiSettings(state),
    globalState: state.global
});

Image.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

Image = connect(mapStateToProps)(Image);
export default Image;
