import React from "react";
import PropTypes from "prop-types";

import { tryCatchFinally } from "../../../lib/qm_cs_lib.js";

/**
 * @class
 * @augments {React.Component<Props, State>}
 */
class ActionButton extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.boundHandleKeyup = this.handleKeyup.bind(this);
        this.boundOnClick = this.onClick.bind(this);
        this.state = { isReady: false };
    }

    componentDidMount() {
        if (
            this.props.actionKey !== null &&
            this.props.actionKey !== undefined
        ) {
            window.addEventListener("keyup", this.boundHandleKeyup);
        }
        this.setState({
            isReady: true
        });
    }

    componentWillUnmount() {
        // removing event listeners that weren't added previously isn't a problem
        window.removeEventListener("keyup", this.boundHandleKeyup);
    }

    onClick(event) {
        tryCatchFinally(this.props.onClick);
    }

    handleKeyup(event) {
        if (this.props.actionKey === event.key) {
            this.onClick(event);
        }
    }

    render() {
        if (!this.state.isReady) {
            return <></>;
        }

        return (
            <button
                ref={this.ref}
                onClick={this.boundOnClick}
                className={this.props.className}
                style={this.props.style}
                disabled={this.props.disabled}
            >
                {this.props.children}
            </button>
        );
    }
}

ActionButton.defaultProps = {
    children: "Button",
    onClick: () => {},
    disabled: false,
    actionKey: null,
    style: {},
    className: "actionButton button"
};

ActionButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    actionKey: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string
};

export default ActionButton;
