export const tryCatchFinally = (
    tryCallback,
    catchCallback = err => console.error(err),
    finallyCallback = () => {}
) => {
    try {
        tryCallback();
    } catch (err) {
        catchCallback(err);
    } finally {
        finallyCallback();
    }
};

export const drawAndLoadImageOnCanvas = (
    canvas,
    ctx,
    img_url,
    resize,
    imageWasDrawnCallback = img => {}
) => {
    const img = new Image();
    img.onload = () => {
        drawPreloadedImageOnCanvas(
            canvas,
            ctx,
            img,
            resize,
            imageWasDrawnCallback
        );
    };
    img.crossOrigin = "anonymous";
    img.src = img_url;
};

export const drawPreloadedImageOnCanvas = (
    canvas,
    ctx,
    img,
    resize,
    imageWasDrawnCallback = img => {}
) => {
    if (resize) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    } else {
        ctx.drawImage(img, 0, 0);
    }
    imageWasDrawnCallback(img);
};

/**
 * @returns URLSearchParams
 */
export const getQueryParams = () => {
    return new URLSearchParams(window.location.search);
};

export const checkRequiredQueryParams = (params, requiredParamNames) => {
    for (const requiredParamName of requiredParamNames) {
        if (!params.has(requiredParamName)) {
            return false;
        }
    }
    return true;
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const stringTruthiness = str => {
    return str !== undefined && str !== null && str.toLowerCase() === "true";
};

export const memoize = (f, keyAccessor = o => o.key) => {
    const cache = {};
    return (...args) => {
        const key = keyAccessor(args[0]);
        if (!(key in cache)) {
            cache[key] = f(args[0]);
        }
        return cache[key];
    };
};

/**
 * Returns the current date in YYYY-MM-DD format
 *
 * @returns {String}
 */
export const getFormattedTodayDate = () => {
    const d = new Date();
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
        month = "0" + month;
    }
    if (day.length < 2) {
        day = "0" + day;
    }

    return [year, month, day].join("-");
};

/**
 * Converts a json object to a string and triggers the download.
 * Pretty print with indentation is optional.
 *
 * @param {Object} jsonData json data object
 * @param {String} filename only the filename without the .json extension
 * @param {Number=} indent with how many spaces to pretty print the file. Default is false (no pretty print)
 */
export const triggerJsonObjectDownload = (
    jsonData,
    filename,
    indent = false
) => {
    let jsonString = "";
    if (indent === false) {
        jsonString = JSON.stringify(jsonData);
    } else {
        console.log(indent);
        jsonString = JSON.stringify(jsonData, null, indent);
    }
    const data = "text/json;charset=utf-8," + encodeURIComponent(jsonString);
    const a = document.createElement("a");
    a.href = "data:" + data;
    a.download = filename + ".json";
    a.innerHTML = "download JSON";
    a.click();
};

/**
 * Copy text to clipboard when navigator clipboard api is supported by the browser
 *
 * @param {String} text
 */
export const copyTextToClipboard = text => {
    if (navigator !== undefined && navigator.clipboard !== undefined) {
        navigator.clipboard
            .writeText(text)
            .then(() => console.log("copied text to clipboard:", text))
            .catch(err =>
                console.err("error trying to copy text to clipboard", text)
            );
    } else {
        console.warn("clipboard api not implemented in this browser", text);
    }
};

/**
 * @param {MouseEvent} originalEvent
 * @param {HTMLElement} target
 */
export const propagateMouseEventToTarget = (originalEvent, target) => {
    const event = new MouseEvent(originalEvent.type, originalEvent);
    target.dispatchEvent(event);
};

/**
 * @param {Number} colorVal single color value between 0 and 255
 * @param {Number} percent
 */
export const adjustColorValToHexString = (colorVal, percent) => {
    if (colorVal > 0) {
        return (0 | ((1 << 8) + colorVal + ((256 - colorVal) * percent) / 100))
            .toString(16)
            .substr(1);
    }
    return "00";
};

/**
 * adjusted and corrected from src: https://stackoverflow.com/a/6444043
 *
 * @param {String} hexString
 * @param {Number} percent
 */
export const adjustHexColorBrightness = (hexString, percent) => {
    // strip the leading # if it's there
    let hex = hexString.replace(/^\s*#|\s*$/g, "");
    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length === 3) {
        hex = hex.replace(/(.)/g, "$1$1");
    }

    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    const rHex = adjustColorValToHexString(r, percent);
    const gHex = adjustColorValToHexString(g, percent);
    const bHex = adjustColorValToHexString(b, percent);
    return "#" + rHex + gHex + bHex;
};

/**
 * Create a random alphanumeric string (36 different characters) with specified length.
 *
 * @param {Number} len
 */
export const generateRandomString = len => {
    return [...new Array(len)]
        .map(i => (~~(Math.random() * 36)).toString(36))
        .join("");
};

/**
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 */
export const clamp = (value, min, max) => {
    return Math.max(min, Math.min(max, value));
};
