import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialTaskSubmitRequest = {
    pending: false,
    result: null,
    error: null
};

export const taskSubmitRequestReducer = generateReducer(
    initialTaskSubmitRequest,
    {
        [ActionTypes.TASK_SUBMIT]: (taskSubmitRequest, action) => {
            return { ...taskSubmitRequest, pending: true };
        },
        [ActionTypes.TASK_SUBMIT_SUCCESS]: (taskSubmitRequest, action) => {
            return {
                ...taskSubmitRequest,
                pending: false,
                result: action.result
            };
        },
        [ActionTypes.TASK_SUBMIT_ERROR]: (taskSubmitRequest, action) => {
            return {
                ...taskSubmitRequest,
                pending: false,
                error: action.error
            };
        }
    }
);

export const getTaskSubmitRequest = state => state.global.taskSubmitRequest;
export const getTaskSubmitResult = state => {
    return state.global.taskSubmitRequest.result;
};
