import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialGuiSettings = null;

export const guiSettingsReducer = generateReducer(initialGuiSettings, {
    [ActionTypes.SET_GUI_SETTINGS]: (guiSettings, action) => {
        return { ...action.guiSettings };
    }
});

export const getGuiSettings = state => state.global.guiSettings;
