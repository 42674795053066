import { JSONSerializable } from "../JSONSerializable";

/**
 * Represents the TaskResult built from the YesNoMaybe gui for the task api
 * @class
 * @augments {JSONSerializable}
 */
export class TaskResult extends JSONSerializable {
    constructor() {
        super();

        this.task_id = "";
        this.result = {
            answers: []
        };
    }

    /**
     * @param {String} taskId
     * @returns {TaskResult}
     */
    setTaskId(taskId) {
        this.task_id = taskId;
        return this;
    }

    /**
     * @param {Answer[]} answers
     * @returns {TaskResult}
     */
    setAnswers(answers) {
        this.result.answers = answers;
        return this;
    }

    /**
     * @param {Number} totalTimeMillis
     * @returns {TaskResult}
     */
    setTotalTimeMillis(totalTimeMillis) {
        this.result.total_time_ms = totalTimeMillis;
        return this;
    }
}
