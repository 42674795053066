import generateActionCreator from "../../generateActionCreator";

export const ActionTypes = {
    TASK_FETCH: "TASK_FETCH",
    TASK_RECEIVED: "TASK_RECEIVED",
    TASK_FETCH_ERROR: "TASK_FETCH_ERROR",
    SET_QUESTIONS_AMOUNT: "SET_QUESTIONS_AMOUNT",
    QUESTION_NEXT: "QUESTION_NEXT",
    QUESTION_PREVIOUS: "QUESTION_PREVIOUS",
    ALL_IMAGES_LOADED: "ALL_IMAGES_LOADED",
    SET_ALL_ANSWERS_FOR_QUESTION: "SET_ALL_ANSWERS_FOR_QUESTION",
    ADD_ANSWER_FOR_QUESTION: "ADD_ANSWER_FOR_QUESTION",
    POP_ANSWER_FOR_QUESTION: "POP_ANSWER_FOR_QUESTION",
    CAPTURE_TIMESTAMP: "CAPTURE_TIMESTAMP",
    SET_GUI_SETTINGS: "SET_GUI_SETTINGS",
    SET_GUI_TYPE: "SET_GUI_TYPE",
    SET_NEXT_GUI_TYPE: "SET_NEXT_GUI_TYPE",
    SET_TASK_RESULT: "SET_TASK_RESULT",
    TASK_SUBMIT: "TASK_SUBMIT",
    TASK_SUBMIT_SUCCESS: "TASK_SUBMIT_SUCCESS",
    TASK_SUBMIT_ERROR: "TASK_SUBMIT_ERROR",
    MTURK_SUBMITTED_ASSIGNMENT: "MTURK_SUBMITTED_ASSIGNMENT",
    MTURK_SUBMITTED_ASSIGNMENT_SUCCESS: "MTURK_SUBMITTED_ASSIGNMENT_SUCCESS",
    MTURK_SUBMITTED_ASSIGNMENT_ERROR: "MTURK_SUBMITTED_ASSIGNMENT_ERROR",
    ADD_MTURK_INFOS: "ADD_MTURK_INFOS",
    THANK_YOU_REACHED: "THANK_YOU_REACHED",
    SET_SHOULD_SHOW_INSTRUCTIONS: "SET_SHOULD_SHOW_INSTRUCTIONS",
    ADD_RESOURCE_TO_CACHE: "ADD_RESOURCE_TO_CACHE"
};

export const taskFetch = generateActionCreator(ActionTypes.TASK_FETCH);
export const taskReceived = generateActionCreator(
    ActionTypes.TASK_RECEIVED,
    "task"
);
export const taskFetchError = generateActionCreator(
    ActionTypes.TASK_FETCH_ERROR,
    "error"
);

export const setQuestionsAmount = generateActionCreator(
    ActionTypes.SET_QUESTIONS_AMOUNT,
    "questionsAmount"
);

export const questionNext = generateActionCreator(ActionTypes.QUESTION_NEXT);
export const questionPrevious = generateActionCreator(
    ActionTypes.QUESTION_PREVIOUS
);

export const allImagesLoaded = generateActionCreator(
    ActionTypes.ALL_IMAGES_LOADED,
    "imgCache"
);

export const setAllAnswersForQuestion = generateActionCreator(
    ActionTypes.SET_ALL_ANSWERS_FOR_QUESTION,
    "answers"
);
export const addAnswerForQuestion = generateActionCreator(
    ActionTypes.ADD_ANSWER_FOR_QUESTION,
    "answer"
);
export const popAnswerForQuestion = generateActionCreator(
    ActionTypes.POP_ANSWER_FOR_QUESTION
);

export const captureTimestamp = generateActionCreator(
    ActionTypes.CAPTURE_TIMESTAMP,
    "timestamp"
);

export const setNextGuiType = generateActionCreator(
    ActionTypes.SET_NEXT_GUI_TYPE,
    "nextGuiType"
);

export const setGuiSettings = generateActionCreator(
    ActionTypes.SET_GUI_SETTINGS,
    "guiSettings"
);

export const setGuiType = generateActionCreator(
    ActionTypes.SET_GUI_TYPE,
    "guiType"
);

export const setTaskResult = generateActionCreator(
    ActionTypes.SET_TASK_RESULT,
    "taskResult"
);

export const taskSubmit = generateActionCreator(ActionTypes.TASK_SUBMIT);
export const taskSubmitSuccess = generateActionCreator(
    ActionTypes.TASK_SUBMIT_SUCCESS,
    "result"
);
export const taskSubmitError = generateActionCreator(
    ActionTypes.TASK_SUBMIT_ERROR,
    "error"
);

export const mturkSubmittedAssignment = generateActionCreator(
    ActionTypes.MTURK_SUBMITTED_ASSIGNMENT
);
export const mturkSubmittedAssignmentSuccess = generateActionCreator(
    ActionTypes.MTURK_SUBMITTED_ASSIGNMENT_SUCCESS,
    "result"
);
export const mturkSubmittedAssignmentError = generateActionCreator(
    ActionTypes.MTURK_SUBMITTED_ASSIGNMENT_ERROR,
    "error"
);
export const addMTurkInfos = generateActionCreator(
    ActionTypes.ADD_MTURK_INFOS,
    "mturkInfos"
);

export const thankYouReached = generateActionCreator(
    ActionTypes.THANK_YOU_REACHED
);

export const setShouldShowInstructions = generateActionCreator(
    ActionTypes.SET_SHOULD_SHOW_INSTRUCTIONS,
    "shouldShowInstructions"
);

export const addResourceToCache = generateActionCreator(
    ActionTypes.ADD_RESOURCE_TO_CACHE,
    "url", "resource"
);
