import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialAnswers = [];

export const answersReducer = generateReducer(initialAnswers, {
    [ActionTypes.SET_ALL_ANSWERS_FOR_QUESTION]: (answers, action) => {
        return action.answers;
    },
    [ActionTypes.ADD_ANSWER_FOR_QUESTION]: (answers, action) => {
        return [...answers, action.answer];
    },
    [ActionTypes.POP_ANSWER_FOR_QUESTION]: (answers, action) => {
        const copy = [...answers];
        copy.pop();
        return copy;
    }
});

// return copied array instead of actual array
export const getAnswers = state => {
    return [...state.global.answers];
};
