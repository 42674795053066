import React from "react";
import PropTypes from "prop-types";
import { ProgressBar as BootstrapProgressBar } from "react-bootstrap";

import "./ProgressBar.css";

let ProgressBar = props => {
    const getSimpleProgressBar = () => {
        let progress = 0;
        if (props.amount !== 0) {
            progress = Math.floor(100 * (props.index / props.amount));
        }
        const label = (
            <span className="progress_bar_label">
                {props.index}/{props.amount}
            </span>
        );
        const style = {
            width: props.width
        };

        return (
            <div className="progress_bar_container" style={style}>
                <BootstrapProgressBar now={progress} label={label} />
            </div>
        );
    };

    return <>{getSimpleProgressBar()}</>;
};

ProgressBar.defaultProps = {
    width: "auto"
};

ProgressBar.propTypes = {
    index: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default ProgressBar;
