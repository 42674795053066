import { globalReducer } from "./reducers/global";

export const getTaskUIModule = () => {
    return {
        id: "task_ui",
        reducerMap: {
            global: globalReducer
        }
    };
};
