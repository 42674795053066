import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialTimestamp = 0;

export const timestampReducer = generateReducer(initialTimestamp, {
    [ActionTypes.CAPTURE_TIMESTAMP]: (timestamp, action) => {
        return action.timestamp;
    }
});

export const getTimestamp = state => state.global.timestamp;
