import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setNextGuiType, captureTimestamp } from "../../modules/TaskUI/actions";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getGuiType } from "../../modules/TaskUI/reducers/guiType";
import RawHTML from "../../components/RawHTML/RawHTML";
import { Carousel } from "react-bootstrap";
import ActionButton from "../../components/TaskUI/ActionButtons/ActionButton";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/InstructionsView.css";

let InstructionsView = props => {
    const onCloseInstructions = () => {
        props.setNextGuiType(props.guiType);
        props.onCloseInstructions();
    };

    useEffect(() => {
        const eventName = "closeInstructions";
        document.addEventListener(eventName, onCloseInstructions);
        return () =>
            document.removeEventListener(eventName, onCloseInstructions);
        // eslint-disable-next-line
    }, []);

    const createRawHTMLNode = (htmlString, className) => {
        return <RawHTML htmlString={htmlString} className={className} />;
    };

    const createCarousel = htmlStrings => {
        const slideNodes = htmlStrings.map(htmlString => (
            <RawHTML
                htmlString={htmlString}
                className={"instructionsCarouselSlide image"}
            />
        ));
        return (
            <div className="container-qm instructionsCarouselContainer">
                <Carousel interval={null}>
                    {slideNodes.map((slide, idx) => (
                        <Carousel.Item key={idx}>{slide}</Carousel.Item>
                    ))}
                </Carousel>

                <ActionButton
                    className="button is-primary closeInstructionsButton"
                    onClick={e =>
                        document.dispatchEvent(new Event("closeInstructions"))
                    }
                >
                    Understood –>
                </ActionButton>
            </div>
        );
    };

    const createInstructions = () => {
        if (props.guiSettings !== null) {
            if (props.guiSettings.instructions.length === 1) {
                return createRawHTMLNode(
                    props.guiSettings.instructions[0],
                    "instructionsContainer"
                );
            } else {
                return createCarousel(props.guiSettings.instructions);
            }
        } else {
            return "";
        }
    };

    return <>{createInstructions()}</>;
};

const mapStateToProps = state => ({
    guiSettings: getGuiSettings(state),
    guiType: getGuiType(state)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            setNextGuiType: setNextGuiType
        },
        dispatch
    );

InstructionsView.defaultProps = {
    onCloseInstructions: () => {}
};

InstructionsView.propTypes = {
    onCloseInstructions: PropTypes.func
};

InstructionsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(InstructionsView);

export default InstructionsView;
