import {
    addMTurkInfos,
    taskFetch,
    taskReceived,
    taskFetchError,
    setGuiSettings,
    taskSubmit,
    taskSubmitSuccess,
    taskSubmitError,
    setQuestionsAmount
} from "../../modules/TaskUI/actions";
import { TaskApiClient } from "../ApiClient/TaskApiClient";
import { checkRequiredQueryParams as doRequiredQueryParamCheck } from "../qm_cs_lib";
import { MTurkHelper } from "../mmturkey";
import { MTurkSubmittedAssignment } from "../ApiClient/models/MTurkSubmittedAssignment";
import {
    submitMTurkSubmittedAssignmentAction,
    loadImagesAction
} from "../../modules/TaskUI/actions/asyncActions";
import { Answer } from "../ApiClient/models/Answer";
import { TaskResult } from "../ApiClient/models/TaskResult";
// eslint-disable-next-line
import TaskUIContext from "./taskUIContext";
import { WIDGETS } from "../../guiFactory";
import { templateUICreateTaskOutput } from "./sharedStrategies";

// all functions in this file expect to be run in a TaskUIContext object

/**
 * @this TaskUIContext
 */
export function checkRequiredQueryParams() {
    // the mturk specific query params are handled directly by the mturkHelper
    const requiredQueryParamNames = ["task_endpoint", "task_id", "gui_type"];
    return doRequiredQueryParamCheck(this._params, requiredQueryParamNames);
}

/**
 * @this TaskUIContext
 */
export function init(apiClient) {
    const allParamsOk = this.checkRequiredQueryParams();
    if (allParamsOk && apiClient === null) {
        this._apiClient = new TaskApiClient({
            endpoint: this._params.get("task_endpoint")
        });
    } else if (!allParamsOk) {
        console.error("Missing parameters in", this._params);
    } else {
        this._apiClient = apiClient;
    }

    if (allParamsOk) {
        this.setSpecificVar("task_id", this._params.get("task_id"));
        this.reconfigureStrategiesBasedOnGuiType(this._params.get("gui_type"));
    }

    this.setSpecificVar("mturkHelper", new MTurkHelper());
}

/**
 * @this TaskUIContext
 */
export function getInitialGuiType() {
    return this._params.get("gui_type");
}

/**
 * @this TaskUIContext
 */
export function createTaskOutput(data) {
    const answerObject = new Answer();
    answerObject
        .setAnswerKey(data.answer)
        .setTimeMillis(data.timeMillis)
        .setImageUrl(
            data.globalState.taskRequest.task.task_inputs.images_urls[
                data.globalState.currentQuestionIdx
            ]
        );
    return answerObject;
}

/**
 * @this TaskUIContext
 */
export function createResultsSubmitObject(data) {
    const taskResult = new TaskResult();
    taskResult
        .setTaskId(data.globalState.taskRequest.task.id)
        .setAnswers(data.answers)
        .setTotalTimeMillis(
            data.answers.reduce((sum, answer) => sum + answer.time_ms, 0)
        );
    return taskResult;
}

/**
 * @this TaskUIContext
 */
export function hasImagesToPreload(task_inputs) {
    return (
        task_inputs.images_urls !== undefined &&
        task_inputs.images_urls.length > 0
    );
}

/**
 *
 * @this {TaskUIContext}
 */
export function getQuestionsAmount(task_inputs) {
    return task_inputs.images_urls.length;
}

/**
 * @this TaskUIContext
 */
export function fetchTasks(resolve, reject, dispatch) {
    dispatch(taskFetch());

    this._apiClient.getTask(
        this.getSpecificVar("task_id"),
        (err, data) => {
            if (err) {
                dispatch(taskFetchError(err));
                console.error(err);
                reject(err);
            } else if (data.ok) {
                dispatch(taskReceived(data.task));
                dispatch(setGuiSettings(data.gui_settings));

                // TODO: consider putting this in its own strategy
                if (this.hasImagesToPreload(data.task.task_inputs)) {
                    const images_urls = data.task.task_inputs.images_urls;
                    dispatch(loadImagesAction(images_urls));
                }
                const questionsAmount = this.getQuestionsAmount(
                    data.task.task_inputs
                );
                dispatch(setQuestionsAmount(questionsAmount));

                resolve();
            }

            const mturkHelper = this.getSpecificVar("mturkHelper");
            dispatch(
                addMTurkInfos({
                    isPreview: mturkHelper.shouldShowPreviewWarning(),
                    runsOnMTurk: true,
                    assignmentId: mturkHelper.assignmentId,
                    hitId: mturkHelper.hitId,
                    workerId: mturkHelper.workerId
                })
            );
        },
        true
    );
}

/**
 * @this TaskUIContext
 */
export function submitTaskResults(
    resolve,
    reject,
    dispatch,
    taskResults,
    mturkInfos
) {
    dispatch(taskSubmit());

    taskResults.result.mturk_info = {
        worker_id: mturkInfos.workerId,
        assignment_id: mturkInfos.assignmentId,
        hit_id: mturkInfos.hitId
    };

    new Promise((resolveInner, rejectInner) =>
        this._apiClient.submitTask(taskResults, (err, data) => {
            if (err) {
                dispatch(taskSubmitError(err));
                console.error(err);
                reject(err);
                rejectInner(err);
            } else if (data.ok) {
                dispatch(taskSubmitSuccess(data));
                // resolves outer promise from asyncAction for usage in SubmitView
                resolve(data);
                // resolves inner promise for further processing
                resolveInner(data);
            }
        })
    )
        .then(submitTaskResult => {
            console.log("Task submitted successfully", submitTaskResult);

            if (!mturkInfos.isPreview) {
                const mturkSubmittedAssignment = new MTurkSubmittedAssignment();
                mturkSubmittedAssignment
                    .setAssignmentId(mturkInfos.assignmentId)
                    .setHITId(mturkInfos.hitId)
                    .setWorkerId(mturkInfos.workerId)
                    .setTaskId(this.getSpecificVar("task_id"));
                return dispatch(
                    submitMTurkSubmittedAssignmentAction(
                        this._apiClient,
                        mturkSubmittedAssignment
                    )
                );
            }
        })
        .then(submitMTurkAssignmentResult => {
            if (submitMTurkAssignmentResult !== undefined) {
                console.log(
                    "Submitted MTurkSubmittedAssignment successfully",
                    submitMTurkAssignmentResult
                );
            }

            if (!mturkInfos.isPreview) {
                this.getSpecificVar("mturkHelper").submit(taskResults);
                console.log("Submitted task result to mturk");
            }
        });
}

/**
 * @this TaskUIContext
 */
export function reconfigureStrategiesBasedOnGuiType(guiType) {
    switch (guiType) {
        case WIDGETS.YES_NO_MAYBE:
            this.getCurrentImageUrl = getCurrentImageUrl;
            break;
        case WIDGETS.TEMPLATE_UI:
            this.hasImagesToPreload = () => false;
            this.getQuestionsAmount = task_inputs => task_inputs.length;
            this.createTaskOutput = templateUICreateTaskOutput;
            break;
        case WIDGETS.SPOTIFY_AUDIO_PLAYER:
            this.hasImagesToPreload = () => false;
            this.getQuestionsAmount = task_inputs => task_inputs.records.length;
            this.createResultsSubmitObject = createResultsSubmitObjectWithoutTotalTimeMillis;
            break;
        default:
            break;
    }
}

// specifics for the yes no maybe gui with images

/**
 * @this TaskUIContext
 */
function getCurrentImageUrl(data) {
    return data.taskRequest.task.task_inputs.images_urls[
        data.currentQuestionIdx
    ];
}

/**
 * @this TaskUIContext
 */
function createResultsSubmitObjectWithoutTotalTimeMillis(data) {
    const taskResult = new TaskResult();
    taskResult
        .setTaskId(data.globalState.taskRequest.task.id)
        .setAnswers(data.answers);
    return taskResult;
}
