import { JSONSerializable } from "../JSONSerializable";

/**
 * @class
 * @augments {JSONSerializable}
 */
export default class DiscreteAnswersGuiTaskOutput extends JSONSerializable {
    /**
     *
     * @param {String} answer answerKey from answer button
     * @param {int} duration duration in milliseconds
     */
    constructor(answer, duration) {
        super();
        this.setAnswer(answer).setDuration(duration);
    }

    /**
     *
     * @param {String} answer
     * @returns {DiscreteAnswersGuiTaskOutput}
     */
    setAnswer(answer) {
        this.answer = answer;
        return this;
    }

    /**
     *
     * @param {int} duration
     * @returns {DiscreteAnswersGuiTaskOutput}
     */
    setDuration(duration) {
        this.duration = duration;
        return this;
    }
}
