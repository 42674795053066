import React from "react";
import PropTypes from "prop-types";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks
} from "body-scroll-lock";
import { quantileSeq } from "mathjs";

import CanvasImage from "../../components/CanvasImage/CanvasImage";
import {
    Box3D,
    Box2D,
    Boxplot2D,
    Arrow,
    Cross,
    getCenterPointOfLine,
    magnitude,
    dotProduct
} from "../../containers/PointcloudProjection/ObjectCreation";
import {
    createObjectSVG,
    svgCircle,
    createObjectSVGDynamic,
    styleObjectEdges,
    defaultEdgeStyleSettings
} from "../../containers/PointcloudProjection/ObjectVisualization";
import { getColorMap, COLORMAPS, ColorMap } from "../../lib/NRL/ColorMap";
import {
    propagateMouseEventToTarget,
    adjustHexColorBrightness
} from "../../lib/qm_cs_lib";

export const BOX_COLORS = [
    "#FF0000",
    "#008000",
    "#FFF",
    "#808000",
    "#008080",
    "#800080",
    "#004000",
    "#400000",
    "#000040",
    "#404000",
    "#004040",
    "#400040"
];

/**
 * @augments {React.Component<Props, State>}
 */
class PointcloudProjection extends React.Component {
    constructor(props) {
        super(props);
        this.scrollLockTargetRef = React.createRef();

        this.drawingCanvases = {};
        this.colormap = new ColorMap("z", getColorMap(COLORMAPS.JET));
        this.relevantYQuantile = null;
        this.bevCanvasSize = 1024; //fixed size for rendering
        this.kHighlightDistanceRgb = 7.0;
        this.kHighlightDistanceVerticalBev = 20.0;
        this.kHighlightDistanceHorizontalBev = 50.0;
        this.bevZoomForCOG = this.props.bevZoomSettings.initial;
        this.bevPointRadius = 3;
        // -1 for pixel rendering only. otherwise the radius will be scaled
        // with rgbScale accordingly
        this.rgbPointRadius = 1.5;
        this.pointMinY = null;
        this.pointMaxY = null;
        this.mouseLastInRGB = false;
        this.bevVisCenter = { u: 0, v: 0 };
        this.bevZoom = 10;
        this.bevZoomingIncrementFactor = 20;
        this.dataProcessed = false;
        this.pointsProjected = false;
        this.boxCenter = null;
        this.canvasAmount = 0;
        this.imgRgbX = 0;
        this.imgRgbY = 0;
        this.imgBevX = 0;
        this.imgBevY = 0;

        // - the states to which the drag vector
        //   or orientation is added upon mouseDown
        // - the order is important!
        this.bevActionsStates = {
            boxWidthLeft: 0,
            boxLengthBot: 0,
            boxWidthRight: 0,
            boxLengthTop: 0,
            boxOrientation: 0
        };
        this.rgbActionsStates = {
            boxHeightBot: 0,
            boxHeightTop: 0
        };

        this.state = {
            bevBoxCenterU: 0,
            bevBoxCenterV: 0,
            showBox: this.props.showBox,
            pressed: false,
            showBev: this.props.showBev
        };
    }

    componentDidMount() {
        const get3DBoxParametersWrapper = function () {
            return this.get3DBoxParameters(true);
        }.bind(this);
        // hacky but ALTERNATIVLOS
        this.props.setBoxDataAccessor(get3DBoxParametersWrapper);
    }

    componentDidUpdate() {
        if (this.props.useBevActionsCanvas && this.boxGroundChanged === true) {
            this.renderRgbActions();
            this.boxGroundChanged = false;
        }
    }

    componentWillUnmount() {
        // make sure to cleanup the scroll lock!
        clearAllBodyScrollLocks();
    }

    // callback
    renderImageBBox(canvas, ctx, img) {
        const meta = this.props.pcData.metaData;
        const bw = meta.relativeBoxRight - meta.relativeBoxLeft;
        const bh = meta.relativeBoxBottom - meta.relativeBoxTop;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = "magenta";
        ctx.rect(meta.relativeBoxLeft, meta.relativeBoxTop, bw, bh);
        ctx.stroke();
        ctx.restore();
    }

    // callback
    initDrawingCanvas(canvas, ctx, name) {
        this.drawingCanvases[name] = {
            canvas: canvas,
            ctx: ctx
        };

        if (name === "bevBackground") {
            ctx.fillStyle = "black";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        if (name === "rgbPoints") {
            const rgbScale = this.getRgbScale();
            if (this.rgbPointRadius !== -1) {
                this.rgbPointRadius /= rgbScale;
                // use pixel rendering if our layout upscales the image because
                // that method looks more crisp in an upscaled image
                if (this.rgbPointRadius < 1) {
                    this.rgbPointRadius = -1;
                }
            }
            // scale rgb highlighting
            this.kHighlightDistanceRgb /= rgbScale;
        }

        if (
            this.drawingCanvasesReady() &&
            !(this.dataProcessed && this.pointsProjected)
        ) {
            // TODO: consider moving to its own function...
            // these are basically initialization steps
            this.preprocessInput();
            this.projectPoints();
            this.renderBEVProjection();
            if (this.props.useBevActionsCanvas) {
                this.renderBEVActions();
                this.renderRgbActions();
            }
            this.setState({
                bevBoxCenterU: this.props.centerW / this.getBevScale(),
                bevBoxCenterV: this.props.centerL / this.getBevScale()
            });

            let newBoxGround = 0;
            if (this.boxCenter) {
                // sets new boxGround to center of canvas
                console.log("Centering Box Height");
                newBoxGround = this.getRgbCanvasHeightAsSphericalHeight(
                    this.drawingCanvases["rgbPoints"].canvas.height / 2
                );
            }
            // updates parents boxGround state
            this.props.setSliderValue({ boxGround: newBoxGround });
            this.boxGroundChanged = true;
        }
    }

    // callback
    onRGBMouseMoved(e) {
        this.imgRgbX = e.nativeEvent.offsetX;
        this.imgRgbY = e.nativeEvent.offsetY;
        this.mouseLastInRGB = true;
        if (this.drawingCanvasesReady()) {
            this.renderImageProjection();
            this.renderBEVProjection();
        }
    }

    // callback
    onRgbActionsMouseDown(e) {
        // pass through to underlying rgb canvas
        propagateMouseEventToTarget(
            e,
            this.drawingCanvases["rgbPoints"].canvas
        );

        this.rgbActionsMouseDown = true;
        this.rgbActionsMouseDownMousePos = this.getCanvasPointInUnscaledImage(
            {
                x: e.nativeEvent.offsetX,
                y: e.nativeEvent.offsetY
            },
            true
        );

        this.selectedRgbActionIdx = this.getActionIdxByMousePos(
            this.drawingCanvases["rgbActions"],
            this.rgbActionsOpacities,
            this.rgbActionsMouseDownMousePos
        );

        if (this.selectedRgbActionIdx > -1) {
            const stateToUpdate = Object.keys(this.rgbActionsStates)[
                this.selectedRgbActionIdx
            ];
            this.rgbActionsStates[stateToUpdate] = this.props[stateToUpdate];
        }
    }

    // callback
    onRgbActionsMouseUp(e) {
        // pass through to underlying rgb canvas
        propagateMouseEventToTarget(
            e,
            this.drawingCanvases["rgbPoints"].canvas
        );
        this.rgbActionsMouseDown = false;
        this.renderRgbActions();
    }

    // callback
    onRgbActionsMouseOut(e) {
        // pass through to underlying rgb canvas
        propagateMouseEventToTarget(
            e,
            this.drawingCanvases["rgbPoints"].canvas
        );
        this.rgbActionsMouseDown = false;
        this.renderRgbActions();
    }

    // callback
    onRgbActionsMouseMoved(e) {
        // pass through to underlying rgb canvas
        propagateMouseEventToTarget(
            e,
            this.drawingCanvases["rgbPoints"].canvas
        );

        if (
            this.rgbActionsMouseDown === true &&
            this.selectedRgbActionIdx > -1
        ) {
            const mouse = this.getCanvasPointInUnscaledImage(
                {
                    x: e.nativeEvent.offsetX,
                    y: e.nativeEvent.offsetY
                },
                true
            );
            const stateToUpdate = Object.keys(this.rgbActionsStates)[
                this.selectedRgbActionIdx
            ];
            const direction = stateToUpdate === "boxHeightTop" ? -1 : 1;
            const startDragHeight = this.getRgbCanvasHeightAsSphericalHeight(
                this.rgbActionsMouseDownMousePos.y
            );
            const dragToHeight = this.getRgbCanvasHeightAsSphericalHeight(
                mouse.y
            );
            const heightDiff = dragToHeight - startDragHeight;
            const update = direction * heightDiff;

            this.props.setSliderValue({
                [stateToUpdate]: this.rgbActionsStates[stateToUpdate] + update
            });
        }
    }

    // callback
    onBEVZoom(e) {
        if (!this.props.bevZoomEnabled) {
            return;
        }

        const w = this.bevCanvasSize;
        // const s = this.bevZoom;
        const cs = this.getBevScale();
        //projection pixel position relative to top left
        const mu = e.nativeEvent.offsetX / cs;
        const mv = e.nativeEvent.offsetY / cs;
        const mBefore = this.bevPointTo3D(mu, mv);
        //3d point at projection center
        const cBefore = this.bevPointTo3D(w / 2, w / 2);
        const boxCenterBefore = this.bevPointTo3D(
            this.state.bevBoxCenterU,
            this.state.bevBoxCenterV
        );

        //compute shift of the same 2D points in 3D
        this.bevZoom -= e.deltaY / this.bevZoomingIncrementFactor;
        if (this.bevZoom < this.props.bevZoomSettings.min) {
            this.bevZoom = this.props.bevZoomSettings.min;
        }
        if (this.bevZoom > this.props.bevZoomSettings.max) {
            this.bevZoom = this.props.bevZoomSettings.max;
        }

        const mAfter = this.bevPointTo3D(mu, mv);
        const cAfter = this.bevPointTo3D(w / 2, w / 2);
        const mDiff = [
            mAfter[0] - mBefore[0],
            mAfter[1] - mBefore[1],
            mAfter[2] - mBefore[2]
        ];
        const cDiff = [
            cAfter[0] - cBefore[0],
            cAfter[1] - cBefore[1],
            cAfter[2] - cBefore[2]
        ];

        //compute new vectors so that the center moves, but not the mouse location
        this.bevVisCenter.u =
            -(cBefore[0] + cDiff[0] - mDiff[0]) * this.bevZoom;
        this.bevVisCenter.v =
            -(cBefore[2] + cDiff[2] - mDiff[2]) * this.bevZoom;

        const camBev = this.getBevProjectionMatrix();
        const p = [
            boxCenterBefore[0],
            boxCenterBefore[1],
            boxCenterBefore[2],
            1
        ];
        const pBev = this.multNxNbyNx1norm(camBev, p);
        const newU = Math.round(pBev[0]);
        const newV = Math.round(pBev[1]);

        // reproject and rerender bev
        this.projectPoints(true);
        this.renderPointsBev(
            this.props.pcData.pointsTableRowList,
            this.drawingCanvases["bevHighlighted"]
        );
        this.renderBEVProjection();
        if (Object.keys(this.drawingCanvases).includes("bevActions")) {
            this.renderBEVActions();
        }

        this.setState({
            bevBoxCenterU: newU,
            bevBoxCenterV: newV
        });
    }

    onBEVClicked(e) {
        if (this.props.objectMovement) {
            if (this.props.bevClickEnabled) {
                this.setState({
                    bevBoxCenterU: e.nativeEvent.offsetX / this.getBevScale(),
                    bevBoxCenterV: e.nativeEvent.offsetY / this.getBevScale(),
                    showBox: { rgbView: true, bevView: true }
                });
            }
        } else {
            if (this.props.bevClickEnabled) {
                this.setState({
                    showBox: { rgbView: true, bevView: true }
                });
            }
        }
    }

    // callback
    onBEVMouseMoved(e) {
        this.imgBevX = e.nativeEvent.offsetX;
        this.imgBevY = e.nativeEvent.offsetY;

        if (this.props.objectMovement) {
            if (this.state.pressed) {
                this.setState({
                    bevBoxCenterU: e.nativeEvent.offsetX / this.getBevScale(),
                    bevBoxCenterV: e.nativeEvent.offsetY / this.getBevScale(),
                    showBox: { rgbView: true, bevView: true }
                });
            }
        }
        this.mouseLastInRGB = false;
        if (this.drawingCanvasesReady()) {
            this.renderBEVProjection();
            this.renderImageProjection();
        }
    }

    // callback
    onBEVActionsClicked(e) {
        // pass through to underlying bev canvas
        propagateMouseEventToTarget(e, this.drawingCanvases["bev"].canvas);
    }

    getActionIdxByMousePos(drawingCanvas, opacities, mousePos) {
        // figure out which action was clicked by its corresponding alpha channel
        const ctx = drawingCanvas.ctx;
        const imgData = ctx.getImageData(mousePos.x, mousePos.y, 1, 1);
        const alphaOfMouseDownPixel = imgData.data[3] / 255;

        const diffs = [];
        for (let i = 0; i < opacities.length; i++) {
            diffs.push(Math.abs(alphaOfMouseDownPixel - opacities[i]));
        }

        let actionIdx = -1;
        if (alphaOfMouseDownPixel > 0) {
            actionIdx = diffs.reduce(
                (min, current, idx) =>
                    current < min.val ? { val: current, idx: idx } : min,
                { val: 1, idx: -1 }
            ).idx;
        }
        return actionIdx;
    }

    // callback
    onBEVActionsMouseDown(e) {
        // pass through to underlying bev canvas
        propagateMouseEventToTarget(e, this.drawingCanvases["bev"].canvas);

        this.bevActionsMouseDown = true;
        this.bevActionsMouseDownMousePos = this.getCanvasPointInUnscaledImage({
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY
        });

        this.selectedBevActionIdx = this.getActionIdxByMousePos(
            this.drawingCanvases["bevActions"],
            this.bevActionsOpacities,
            this.bevActionsMouseDownMousePos
        );

        if (this.selectedBevActionIdx > -1) {
            const stateToUpdate = Object.keys(this.bevActionsStates)[
                this.selectedBevActionIdx
            ];
            this.bevActionsStates[stateToUpdate] = this.props[stateToUpdate];
        }
    }

    // callback
    onBEVActionsMouseUp(e) {
        // pass through to underlying bev canvas
        propagateMouseEventToTarget(e, this.drawingCanvases["bev"].canvas);
        this.bevActionsMouseDown = false;
        this.renderBEVActions();
    }

    // callback
    onBEVActionsMouseOut(e) {
        // pass through to underlying bev canvas
        propagateMouseEventToTarget(e, this.drawingCanvases["bev"].canvas);
        this.bevActionsMouseDown = false;
        this.renderBEVActions();
    }

    // callback
    onBEVActionsMouseMoved(e) {
        // pass through to underlying bev canvas
        propagateMouseEventToTarget(e, this.drawingCanvases["bev"].canvas);

        if (
            this.bevActionsMouseDown === true &&
            this.selectedBevActionIdx > -1
        ) {
            const mouse = this.getCanvasPointInUnscaledImage({
                x: e.nativeEvent.offsetX,
                y: e.nativeEvent.offsetY
            });
            const diff = {
                x: mouse.x - this.bevActionsMouseDownMousePos.x,
                y: mouse.y - this.bevActionsMouseDownMousePos.y
            };
            const diffLength = magnitude(diff);
            const selectedGeometry = this.bevActionsGeometries[
                this.selectedBevActionIdx
            ];

            // TODO: clean this mess up...
            // ######### START: get the actual box center #########
            const boxParams = this.get3DBoxParameters(false);
            const geometry = this.getBoxWithArrowFromParameters(boxParams);
            const box = geometry.box;
            const cam = this.getPointcloudProjectionMatrix();
            const boxLines = this.projectObject(box, cam);
            const projectedBoxCenter = {
                x:
                    boxLines[0][0][0] +
                    (boxLines[1][1][0] - boxLines[0][0][0]) / 2,
                y:
                    boxLines[0][0][1] +
                    (boxLines[1][1][1] - boxLines[0][0][1]) / 2
            };
            // ######### END #########

            const boxCenterToSelectedGeometry = {
                x: selectedGeometry.x - projectedBoxCenter.x,
                y: selectedGeometry.y - projectedBoxCenter.y
            };
            const boxCenterToGeometryLength = magnitude(
                boxCenterToSelectedGeometry
            );
            const dragVector =
                dotProduct(diff, boxCenterToSelectedGeometry) /
                    (diffLength * boxCenterToGeometryLength) || 0;

            const stateToUpdate = Object.keys(this.bevActionsStates)[
                this.selectedBevActionIdx
            ];

            if (stateToUpdate === "boxOrientation") {
                const boxCenterToMouse = {
                    x: mouse.x - projectedBoxCenter.x,
                    y: mouse.y - projectedBoxCenter.y
                };
                const boxCenterToMouseDownPos = {
                    x:
                        this.bevActionsMouseDownMousePos.x -
                        projectedBoxCenter.x,
                    y: this.bevActionsMouseDownMousePos.y - projectedBoxCenter.y
                };
                const dot = dotProduct(
                    boxCenterToMouseDownPos,
                    boxCenterToMouse
                );
                const cross =
                    boxCenterToMouseDownPos.x * boxCenterToMouse.y -
                    boxCenterToMouseDownPos.y * boxCenterToMouse.x;
                const atan2AngleRad = -Math.atan2(cross, dot);
                const atan2AngleDeg = (atan2AngleRad * 180) / Math.PI;

                this.props.setSliderValue({
                    boxOrientation:
                        this.bevActionsStates.boxOrientation + atan2AngleDeg
                });
            } else {
                const diffProjectedOntoLineNormal = diffLength * dragVector;
                this.props.setSliderValue({
                    [stateToUpdate]:
                        this.bevActionsStates[stateToUpdate] +
                        diffProjectedOntoLineNormal / this.bevZoom
                });
            }
        }
    }

    // callback
    handleButtonPress(e) {
        if (this.props.bevClickEnabled) {
            this.setState({
                pressed: true
            });
        }
    }

    // callback
    handleButtonRelease() {
        if (this.props.bevClickEnabled) {
            this.setState({
                pressed: false
            });
        }
    }

    // high level
    getRgbCanvases() {
        const img = this.props.currentImageObject;
        // only three of the four rgb canvases are used for drawing
        this.canvasAmount += 3;
        return [
            <CanvasImage
                name="rgb"
                canvas_width={img.width}
                canvas_height={img.height}
                preloaded_img={img}
                resize_img_to_canvas_dimensions={true}
                callback_after_draw_finish={this.renderImageBBox.bind(this)}
            />,
            <CanvasImage
                name="rgbPoints"
                className="canvas_on_canvas"
                canvas_width={img.width}
                canvas_height={img.height}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
                onMouseMove={this.onRGBMouseMoved.bind(this)}
            />,
            <CanvasImage
                name="rgbPointsHighlighted"
                canvas_width={img.width}
                canvas_height={img.height}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
            />,
            <CanvasImage
                name="rgbAlpha"
                canvas_width={img.width}
                canvas_height={img.height}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
            />
        ];
    }

    // high level
    // create all SVG objects for the RGB overlay
    getRgbSvg() {
        const img = this.props.currentImageObject;
        const style = {
            clipPath: "inset(0, 0, " + img.width + ", " + img.height + ")"
        };
        const viewBox = "0 0 " + img.width + " " + img.height;
        return this.getBoxSvg(
            this.props.boxes,
            style,
            viewBox,
            true,
            this.state.showBox.rgbView
        );
    }

    // high level
    getBevCanvases() {
        this.canvasAmount += 4;
        return [
            <CanvasImage
                name="bevBackground"
                canvas_width={this.bevCanvasSize}
                canvas_height={this.bevCanvasSize}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
            />,
            <CanvasImage
                name="bev"
                className="canvas_on_canvas"
                canvas_width={this.bevCanvasSize}
                canvas_height={this.bevCanvasSize}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
                onClick={this.onBEVClicked.bind(this)}
                onMouseMove={this.onBEVMouseMoved.bind(this)}
                onWheel={this.onBEVZoom.bind(this)}
            />,
            <CanvasImage
                name="bevHighlighted"
                canvas_width={this.bevCanvasSize}
                canvas_height={this.bevCanvasSize}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
            />,
            <CanvasImage
                name="bevAlpha"
                canvas_width={this.bevCanvasSize}
                canvas_height={this.bevCanvasSize}
                callback_canvas_draw={this.initDrawingCanvas.bind(this)}
            />
        ];
    }

    getBevActionsCanvas() {
        if (this.props.useBevActionsCanvas) {
            this.canvasAmount++;
            return (
                <CanvasImage
                    name="bevActions"
                    className="canvas_on_canvas"
                    canvas_width={this.bevCanvasSize}
                    canvas_height={this.bevCanvasSize}
                    callback_canvas_draw={this.initDrawingCanvas.bind(this)}
                    onClick={this.onBEVActionsClicked.bind(this)}
                    onMouseMove={this.onBEVActionsMouseMoved.bind(this)}
                    onMouseDown={this.onBEVActionsMouseDown.bind(this)}
                    onMouseUp={this.onBEVActionsMouseUp.bind(this)}
                    onMouseOut={this.onBEVActionsMouseOut.bind(this)}
                    // make sure the bev action canvas doesn't swallow the onWheel event
                    onWheel={this.onBEVZoom.bind(this)}
                />
            );
        }
        return null;
    }

    getRgbActionsCanvas() {
        if (this.props.useBevActionsCanvas) {
            const img = this.props.currentImageObject;
            this.canvasAmount++;
            return (
                <CanvasImage
                    name="rgbActions"
                    className="canvas_on_canvas"
                    canvas_width={img.width}
                    canvas_height={img.height}
                    callback_canvas_draw={this.initDrawingCanvas.bind(this)}
                    onMouseMove={this.onRgbActionsMouseMoved.bind(this)}
                    onMouseDown={this.onRgbActionsMouseDown.bind(this)}
                    onMouseUp={this.onRgbActionsMouseUp.bind(this)}
                    onMouseOut={this.onRgbActionsMouseOut.bind(this)}
                />
            );
        }
        return null;
    }

    // high level projection
    renderRgbActions() {
        const boxParams = this.get3DBoxParameters(false);
        const points = [];
        const boxCenterPoint3DBottom = [
            boxParams.center_w,
            boxParams.top,
            boxParams.center_l
        ];
        const boxCenterPoint3DTop = [
            boxParams.center_w,
            boxParams.bottom,
            boxParams.center_l
        ];
        const pointCanvasBottom = this.project3DPointToRgb(
            boxCenterPoint3DBottom
        );
        const boxCenterPointCanvasTop = this.project3DPointToRgb(
            boxCenterPoint3DTop
        );
        points.push({
            x: pointCanvasBottom[0],
            y: pointCanvasBottom[1]
        });
        points.push({
            x: boxCenterPointCanvasTop[0],
            y: boxCenterPointCanvasTop[1]
        });

        /**
         * @type {CanvasRenderingContext2D}
         */
        const ctx = this.drawingCanvases["rgbActions"].ctx;
        const canvas = this.drawingCanvases["rgbActions"].canvas;

        // render circles for updating width of svg box
        const renderCircle = circle => {
            ctx.save();
            ctx.beginPath();
            ctx.arc(circle.x, circle.y, circle.radius, 0, 2 * Math.PI);
            ctx.fillStyle = `rgba(${circle.r * 255}, ${circle.g * 255}, ${
                circle.b * 255
            }, ${circle.a})`;
            ctx.fill();
            ctx.restore();
        };

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const radiusScaledToCanvasWidth = canvas.width * 0.03;

        // 0: bottom
        // 1: top
        this.rgbActionsOpacities = [];
        this.rgbActionsGeometries = [];
        for (let i = 0; i < points.length; i++) {
            this.rgbActionsOpacities.push(0.8 + i / 100);
            const circle = {
                ...points[i],
                radius: radiusScaledToCanvasWidth,
                // pink
                r: 1,
                g: 0,
                b: 0.8,
                a: this.rgbActionsOpacities[i]
            };
            this.rgbActionsGeometries.push(circle);
            renderCircle(circle);
        }
    }

    // high level projection
    renderBEVActions() {
        const boxParams = this.get3DBoxParameters(false);
        const geometry = this.getBoxWithArrowFromParameters(boxParams);
        const box = geometry.box;
        const cam = this.getPointcloudProjectionMatrix();
        const boxLines = this.projectObject(box, cam);

        /**
         * @type {CanvasRenderingContext2D}
         */
        const ctx = this.drawingCanvases["bevActions"].ctx;

        // render circles for updating width of svg box
        const renderCircle = circle => {
            ctx.save();
            ctx.beginPath();
            ctx.arc(circle.x, circle.y, circle.radius, 0, 2 * Math.PI);
            ctx.fillStyle = `rgba(${circle.r * 255}, ${circle.g * 255}, ${
                circle.b * 255
            }, ${circle.a})`;
            ctx.fill();
            ctx.restore();
        };

        ctx.clearRect(0, 0, this.bevCanvasSize, this.bevCanvasSize);
        const lines = [boxLines[0], boxLines[1], boxLines[2], boxLines[3]];
        // 0: left
        // 1: back
        // 2: right
        // 3: front
        // 4: rotate --> located at front-right corner
        this.bevActionsOpacities = [];
        this.bevActionsGeometries = [];
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            this.bevActionsOpacities.push(0.8 + i / 100);
            const centerPointOfLine = getCenterPointOfLine(line);
            const circle = {
                ...centerPointOfLine,
                radius: 30,
                // pink
                r: 1,
                g: 0,
                b: 0.8,
                a: this.bevActionsOpacities[i]
            };
            this.bevActionsGeometries.push(circle);
            renderCircle(circle);
        }
        // circle for rotation
        this.bevActionsOpacities.push(0.804);
        this.bevActionsGeometries.push({
            x: lines[3][0][0],
            y: lines[3][0][1],
            radius: 30,
            r: 0,
            g: 1,
            b: 1,
            a: this.bevActionsOpacities[4]
        });
        renderCircle(this.bevActionsGeometries[4]);
    }

    checkPointConstraint(point) {
        // only in the slider view !!!
        if (this.props.pointVisibility === "Box") {
            const width = this.props.boxWidthLeft + this.props.boxWidthRight;
            const length = this.props.boxLengthTop + this.props.boxLengthBot;
            let margin_x = 0;
            let margin_y = 0;
            let margin = 2;
            //check if point is in box
            if (
                this.props.boxOrientation <= -135 ||
                this.props.boxOrientation > 135 ||
                (this.props.boxOrientation < 45 &&
                    this.props.boxOrientation > -45)
            ) {
                margin_x = width / 2 + margin;
                margin_y = length / 2 + margin;
            } else {
                margin_x = length / 2 + margin;
                margin_y = width / 2 + margin;
            }
            if (
                point.x < this.boxCenter.center_w + margin_x &&
                point.x > this.boxCenter.center_w - margin_x &&
                point.z < this.boxCenter.center_l + margin_y &&
                point.z > this.boxCenter.center_l - margin_y
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            ///no constraint
            return true;
        }
    }

    // projection
    get3DBoxParameters(result) {
        const worldPoint = this.mouseCoordToWorld();
        if (result) {
            //return results for backend
            const width = this.props.boxWidthLeft + this.props.boxWidthRight;
            const length = this.props.boxLengthTop + this.props.boxLengthBot;

            if (this.props.guiType === "slider_gui") {
                return {
                    width: width,
                    length: length,
                    height_0: this.props.boxGround + this.props.boxHeightBot,
                    height_1: this.props.boxGround - this.props.boxHeightTop,
                    center_w: this.boxCenter.center_w,
                    center_l: this.boxCenter.center_l,
                    orientation_rad: (this.props.boxOrientation / 180) * Math.PI
                };
            } else if (this.props.guiType === "button_gui") {
                return {
                    width: width,
                    length: length,
                    height_0: this.props.boxGround + this.props.boxHeightBot,
                    height_1: this.props.boxGround - this.props.boxHeightTop,
                    center_w: worldPoint[0],
                    center_l: worldPoint[2],
                    orientation_rad: (this.props.boxOrientation / 180) * Math.PI
                };
            } else {
                const width =
                    this.props.boxWidthLeft + this.props.boxWidthRight;
                const length =
                    this.props.boxLengthTop + this.props.boxLengthBot;

                const hackyCenter = this.bevPointTo3D(
                    this.bevCanvasSize / 2,
                    this.bevCanvasSize / 2
                );
                return {
                    width: width,
                    length: length,
                    height_0: this.props.boxGround + this.props.boxHeightBot,
                    height_1: this.props.boxGround - this.props.boxHeightTop,
                    center_w: worldPoint[0] || hackyCenter[0],
                    center_l: worldPoint[2] || hackyCenter[2],
                    orientation_rad: (this.props.boxOrientation / 180) * Math.PI
                };
            }
        } else {
            return {
                left: this.props.boxWidthLeft,
                right: this.props.boxWidthRight,
                front: this.props.boxLengthTop,
                back: this.props.boxLengthBot,
                top: this.props.boxGround + this.props.boxHeightBot,
                bottom: this.props.boxGround - this.props.boxHeightTop,
                center_w: this.props.centerW,
                center_l: this.props.centerL,
                orientation_rad: (this.props.boxOrientation / 180) * Math.PI
            };
        }
    }

    // projection
    rotateObjectOnGroundPlane(object, angleRad, cx, cz) {
        let rotatedObject = [];
        for (const line of object) {
            let start = this.rotatePointOnGroundPlane(
                line[0],
                cx,
                cz,
                angleRad
            );
            let end = this.rotatePointOnGroundPlane(line[1], cx, cz, angleRad);
            rotatedObject.push([start, end]);
        }
        return rotatedObject;
    }

    // projection
    rotatePointOnGroundPlane(point, cx, cz, angleRad) {
        const px = point[0] - cx;
        const pz = point[2] - cz;

        const x = px * Math.cos(angleRad) - pz * -Math.sin(angleRad) + cx;
        const z = px * Math.sin(angleRad) - pz * Math.cos(angleRad) + cz;

        return [x, point[1], z, point[3]];
    }

    // projection
    mouseCoordToWorld() {
        return this.bevPointTo3D(
            this.state.bevBoxCenterU,
            this.state.bevBoxCenterV
        );
    }

    // projection
    bevPointTo3D(u, v) {
        //assumption: this is an orthographic projection
        //with only 4 relevant non-0 values
        const bevProj = this.getPointcloudProjectionMatrix();
        const cx = bevProj[0][3];
        const cy = bevProj[1][3];
        const sx = bevProj[0][0];
        const sz = bevProj[1][2];
        const groundplaneY = this.pointMaxY; //assumption: the lowest 3d point is ground
        // assumption: u, v are in image coordinates (not scaled image coords)
        // get u, v in BEV coords -> get world coordinates
        return [(u - cx) / sx, groundplaneY, (v - cy) / sz];
    }

    Point3DToBev(u, v) {
        //assumption: this is an orthographic projection
        //with only 4 relevant non-0 values
        const bevProj = this.getBevInverseProjectionMatrix();
        const cx = bevProj[0][3];
        const cy = bevProj[1][3];
        const sx = bevProj[0][0];
        const sz = bevProj[1][2];
        const groundplaneY = this.pointMaxY; //assumption: the lowest 3d point is ground
        // assumption: u, v are in image coordinates (not scaled image coords)
        // get u, v in BEV coords -> get world coordinates
        return [(u - cx) / sx, groundplaneY, (v - cy) / sz];
    }

    getPointcloudProjectionMatrix() {
        if (this.props.pointcloudView === "Front") {
            return this.getFrontViewProjectionMatrix();
        } else if (this.props.pointcloudView === "Bev") {
            return this.getBevProjectionMatrix();
        }
    }

    // projection
    getBevProjectionMatrix() {
        const w = this.bevCanvasSize;
        const s = this.bevZoom;
        const u = this.bevVisCenter.u;
        const v = this.bevVisCenter.v;
        const proj = [
            [-s, 0, 0, w / 2 - u],
            [0, 0, s, w / 2 + v],
            [0, 0, 0, 0],
            [0, 0, 0, 1]
        ];
        return proj;
    }

    getFrontViewProjectionMatrix() {
        const w = this.bevCanvasSize;
        const s = this.bevZoom;
        const proj = [
            [-s, 0, 0, w / 2],
            [0, s, 0, w / 2],
            [0, 0, 0, 0],
            [0, 0, 0, 1]
        ];
        return proj;
    }

    getBevInverseProjectionMatrix() {
        const w = this.bevCanvasSize;
        const s = this.bevZoom;
        const u = this.bevVisCenter.u;
        const v = this.bevVisCenter.v;
        const proj = [
            [-1 / s, 0, 0, (w / 2 - u) / s],
            [0, 0, 1 / s, -(w / 2 + v) / s],
            [0, 0, 0, 0],
            [0, 0, 0, 1]
        ];
        return proj;
    }

    // projection
    projectObjectToRgb(object, color, strokeWidth, opacity) {
        const projection = [];

        const edgeStyleSettings = { ...defaultEdgeStyleSettings };
        edgeStyleSettings.edgeInForeground = {
            ...edgeStyleSettings.edgeInForeground,
            color,
            strokeWidth,
            opacity
        };

        edgeStyleSettings.edgeInBackground = {
            ...edgeStyleSettings.edgeInBackground,
            color: adjustHexColorBrightness(
                edgeStyleSettings.edgeInForeground.color,
                -20
            )
        };
        const edgeStyles = styleObjectEdges(object, edgeStyleSettings);

        object.forEach(line => {
            const lineStart = this.project3DPointToRgb(line[0]);
            const lineEnd = this.project3DPointToRgb(line[1]);
            projection.push([lineStart, lineEnd]);
        });

        return createObjectSVGDynamic(projection, edgeStyles);
    }

    // projection
    project3DPointToRgb(p) {
        const alphaBeta = this.getSphericalAnglesFrom3DPoint(p);
        return this.getRgbCoordFromSphericalAngles(alphaBeta[0], alphaBeta[1]);
    }

    // projection
    getSphericalAnglesFrom3DPoint(point) {
        // input: 3D point with x,y,z coordinates
        // setting alpha=0 to the center of the pano image:
        // alpha = atan(x / y) [rad], alpha \in [-pi, pi)
        const alpha = Math.atan2(-point[0], -point[2]);
        // Distance on ground plane: sqrt(x^2 + y^2) [m]
        const distanceProjectedOnGroundPlane = Math.sqrt(
            point[0] * point[0] + point[2] * point[2]
        );
        // beta = atan(z/dist) [rad]
        const beta = Math.atan2(-point[1], distanceProjectedOnGroundPlane);
        return [alpha, beta];
    }

    // projection
    getRgbCoordFromSphericalAngles(alpha, beta) {
        const data = this.props.pcData.rgbSphericalParams;
        const u = (data.alphaOffset + alpha) * data.pxPerRadAlpha;
        const v = (data.betaOffset - beta) * data.pxPerRadBeta;
        return [u, v];
    }

    // projection
    getRgbCanvasHeightAsSphericalHeight(v) {
        const centerPoint = {
            x: this.boxCenter.center_w,
            y: this.boxCenter.center_l
        };
        const dist = magnitude(centerPoint);
        const data = this.props.pcData.rgbSphericalParams;
        const beta = -(v / data.pxPerRadBeta - data.betaOffset);
        const height = -Math.tan(beta) * dist;
        return height;
    }

    // projection
    getPointsToHighlight(points, mouse) {
        let outPoints = [];
        if (mouse.rgb === true) {
            for (let i = 0; i < points.length; i++) {
                const dist =
                    Math.abs(mouse.x - points[i].uRgb) + //warning, this dist is no circle!
                    Math.abs(mouse.y - points[i].vRgb);
                if (dist < this.kHighlightDistanceRgb)
                    outPoints.push({
                        x: points[i].uBev, // return BEV point to highlight with color
                        y: points[i].vBev,
                        d: dist, //distance
                        colorIdx: points[i].colorIdx
                    });
            }
        } else {
            for (let i = 0; i < points.length; i++) {
                const dist = Math.abs(mouse.y - points[i].vBev);
                if (dist < this.kHighlightDistanceVerticalBev)
                    outPoints.push({
                        x: points[i].uRgb,
                        y: points[i].vRgb,
                        d: Math.abs(mouse.x - points[i].uBev), //distance
                        colorIdx: points[i].colorIdx
                    });
            }
        }
        return outPoints;
    }

    setCanvasAlpha(top, alpha, dst, removeBlack) {
        const cw = dst.canvas.width;
        const ch = dst.canvas.height;
        const topPixels = top.ctx.getImageData(0, 0, cw, ch).data;
        const alphaPixels = alpha.ctx.getImageData(0, 0, cw, ch).data;
        const dstData = dst.ctx.getImageData(0, 0, cw, ch);
        let dstPixels = dstData.data;

        for (let i = 0; i < dstPixels.length; i += 4) {
            const a = alphaPixels[i];
            dstPixels[i] = topPixels[i];
            dstPixels[i + 1] = topPixels[i + 1];
            dstPixels[i + 2] = topPixels[i + 2];
            dstPixels[i + 3] = a;
        }
        if (removeBlack === true) {
            this.makeAllBlackPixelsTransparent(dstPixels);
        }

        dst.ctx.putImageData(dstData, 0, 0);
    }

    // high level projection
    renderBEVProjection() {
        const points = this.props.pcData.pointsTableRowList;
        const mouse = this.getMouseInUnscaledImage();
        const bright = this.drawingCanvases["bevHighlighted"];
        const alpha = this.drawingCanvases["bevAlpha"];
        const dst = this.drawingCanvases["bev"];

        alpha.ctx.fillStyle = "rgb(150, 150, 150)";
        alpha.ctx.fillRect(0, 0, alpha.canvas.width, alpha.canvas.height);
        if (mouse.rgb !== true) {
            alpha.ctx.fillStyle = "rgb(200, 200, 200)";
            const height = this.kHighlightDistanceVerticalBev / 2;
            alpha.ctx.fillRect(
                0,
                mouse.y - height,
                alpha.canvas.width,
                2 * height
            );
            alpha.ctx.fillStyle = "rgb(255, 255, 255)";
            const width = this.kHighlightDistanceHorizontalBev / 2;
            alpha.ctx.fillRect(
                mouse.x - width,
                mouse.y - height,
                2 * width,
                2 * height
            );
        } else {
            const highlightPoints = this.getPointsToHighlight(points, mouse);
            const opaque = [1.0, 1.0, 1.0];
            this.drawPointList(
                highlightPoints,
                alpha,
                this.bevPointRadius,
                1.0,
                opaque
            );
        }
        this.setCanvasAlpha(bright, alpha, dst, false);
    }

    drawPointList(points, drawingCanvas, radius, opacity, color = null) {
        if (points.length > 0) {
            const cw = drawingCanvas.canvas.width;
            const ch = drawingCanvas.canvas.height;
            let data = null;
            let pixels = null;
            if (radius === -1) {
                data = drawingCanvas.ctx.getImageData(0, 0, cw, ch);
                pixels = data.data;
            }
            for (let i = 0; i < points.length; ++i) {
                this.drawPoint(
                    drawingCanvas.ctx,
                    points[i],
                    pixels,
                    cw,
                    ch,
                    radius,
                    opacity,
                    color
                );
            }
            if (data !== null) {
                drawingCanvas.ctx.putImageData(data, 0, 0);
            }
        }
    }

    // high level projection
    renderImageProjection() {
        const points = this.props.pcData.pointsTableRowList;
        const mouse = this.getMouseInUnscaledImage();
        const dst = this.drawingCanvases["rgbPoints"];
        const bright = this.drawingCanvases["rgbPointsHighlighted"];
        const alpha = this.drawingCanvases["rgbAlpha"];

        alpha.ctx.fillStyle = "black";
        alpha.ctx.fillRect(0, 0, alpha.canvas.width, alpha.canvas.height);
        if (mouse.rgb === true) {
            const radius = this.kHighlightDistanceRgb;
            alpha.ctx.beginPath();
            alpha.ctx.fillStyle = "white";
            alpha.ctx.arc(
                Math.round(mouse.x),
                Math.round(mouse.y),
                radius,
                0,
                Math.PI * 2
            );
            alpha.ctx.fill();
        } else {
            const highlightPoints = this.getPointsToHighlight(points, mouse);
            let pClose = [];
            let pFar = [];
            for (let i = 0; i < highlightPoints.length; ++i) {
                if (
                    highlightPoints[i].d <
                    this.kHighlightDistanceHorizontalBev / 2
                ) {
                    pClose.push(highlightPoints[i]);
                } else {
                    pFar.push(highlightPoints[i]);
                }
            }
            const opaque = [1.0, 1.0, 1.0];
            this.drawPointList(pClose, alpha, this.rgbPointRadius, 1.0, opaque);
            const semi = [0.4, 0.4, 0.4];
            this.drawPointList(pFar, alpha, this.rgbPointRadius, 1.0, semi);
        }
        this.setCanvasAlpha(bright, alpha, dst, true);
    }

    // projection
    renderPointsBev(points, drawingCanvas, opacity = 1.0) {
        const ctx = drawingCanvas.ctx;
        const canvas = drawingCanvas.canvas;
        ctx.save();
        ctx.fillStyle = "rgb(0, 0, 0)";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // sort points by Y:
        let pArr = points.map(item => [item, item.y]);
        pArr.sort((first, second) => second[1] - first[1]);
        const sortedPoints = pArr.map(item => ({
            x: item[0].uBev,
            y: item[0].vBev,
            colorIdx: item[0].colorIdx
        }));

        for (let i = 0; i < sortedPoints.length; i++) {
            this.drawPoint(
                ctx,
                sortedPoints[i],
                null,
                canvas.width,
                canvas.height,
                this.bevPointRadius,
                opacity
            );
        }

        ctx.restore();
    }

    // projection
    renderPointsRgb(points, drawingCanvas) {
        const ctx = drawingCanvas.ctx;
        const canvas = drawingCanvas.canvas;

        //sort points by Z:
        let pArr = points.map(item => [item, item.z]);
        pArr.sort((first, second) => first[1] - second[1]);
        const sortedPoints = pArr.map(item => ({
            x: item[0].uRgb,
            y: item[0].vRgb,
            colorIdx: item[0].colorIdx
        }));

        // draw pixels, not circles
        ctx.save();
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.restore();

        let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < sortedPoints.length; i++) {
            this.drawPoint(
                ctx,
                sortedPoints[i],
                imgData.data,
                canvas.width,
                canvas.height,
                this.rgbPointRadius,
                1.0,
                null
            );
        }

        if (this.rgbPointRadius === -1) {
            this.makeAllBlackPixelsTransparent(imgData.data);
            ctx.putImageData(imgData, 0, 0);
        }
    }

    // projection
    drawPoint(
        context,
        point,
        pixels,
        width,
        height,
        radius,
        opacity,
        color = null
    ) {
        const pos = point.x * 4 + point.y * 4 * width;
        if (
            point.x >= 0 &&
            point.x < width &&
            point.y >= 0 &&
            point.y < height
        ) {
            let dstColor = null;
            if (color === null) {
                dstColor = this.colormap.colors[point.colorIdx];
            } else {
                dstColor = color;
            }
            if (radius > 0) {
                context.beginPath();
                context.arc(point.x, point.y, radius, 0, 2 * Math.PI);
                const colorCss =
                    "rgba(" +
                    Math.round(dstColor[0] * 255) +
                    "," +
                    Math.round(dstColor[1] * 255) +
                    "," +
                    Math.round(dstColor[2] * 255) +
                    "," +
                    opacity +
                    ")";
                context.fillStyle = colorCss;
                context.fill();
            } else {
                for (let c = 0; c < 3; c++) {
                    pixels[pos + c] = dstColor[c] * 255;
                }
                pixels[pos + 3] = opacity * 255;
            }
        }
    }

    // projection
    getMouseInUnscaledImage() {
        let p = { x: 0, y: 0 };

        // use either BEV or RGB to get mouse position
        if (this.mouseLastInRGB === true) {
            p = this.getCanvasPointInUnscaledImage(
                {
                    x: this.imgRgbX,
                    y: this.imgRgbY
                },
                true
            );
        } else {
            p = this.getCanvasPointInUnscaledImage({
                x: this.imgBevX,
                y: this.imgBevY
            });
        }
        p.rgb = this.mouseLastInRGB;
        return p;
    }

    // projection
    getCanvasPointInUnscaledImage(canvasPoint, rgb = false) {
        let scale = this.getBevScale();
        if (rgb) {
            scale = this.getRgbScale();
        }
        return { x: canvasPoint.x / scale, y: canvasPoint.y / scale };
    }

    // projection
    getRgbScale() {
        if ("rgbPoints" in this.drawingCanvases) {
            //rgb always scales along width with fixed aspect ration
            const canvas = this.drawingCanvases["rgbPoints"].canvas;
            const scale = canvas.offsetWidth / canvas.width;
            return scale;
        }
        return 1;
    }

    // projection
    getBevScale() {
        if ("bev" in this.drawingCanvases) {
            //bev always scales along width with fixed aspect ration
            const canvas = this.drawingCanvases["bev"].canvas;
            const scale = canvas.offsetWidth / canvas.width;
            return scale;
        }
        return 1;
    }

    // projection
    makeAllBlackPixelsTransparent(pixels) {
        for (let i = 0; i < pixels.length; i += 4) {
            // if all channels are black, set alpha to zero
            if (pixels[i] < 1 && pixels[i + 1] < 1 && pixels[i + 2] < 1) {
                pixels[i + 3] = 0;
            }
        }
    }

    // turn parameterset into box with arrow 3D object which can be projected
    getBoxWithArrowFromParameters(box) {
        if (!Object.keys(box).includes("right") && box.width !== null) {
            //console.log("found box with missing params", box);
            //compute missing params on the fly
            box.right = box.width / 2;
            box.left = box.width / 2;
            box.front = box.length / 2;
            box.back = box.length / 2;
            box.top = box.height_1;
            box.bottom = box.height_0;
        }
        let box3D = Box3D(
            box.right,
            box.left,
            box.front,
            box.back,
            box.top,
            box.bottom,
            0,
            0
        );

        box3D = this.rotateObjectOnGroundPlane(
            box3D,
            box.orientation_rad,
            0,
            0
        );

        let res = [];
        let dx = box.center_w;
        let dy = box.center_l;
        box3D.forEach(pt => {
            res.push([
                [pt[0][0] + dx, pt[0][1], pt[0][2] + dy, 1],
                [pt[1][0] + dx, pt[1][1], pt[1][2] + dy, 1]
            ]);
        });
        box3D = res;

        const initialBevZoom = this.props.bevZoomSettings.initial;
        let arrow3D = Arrow(
            box.center_w,
            box.center_l,
            box.top,
            350 / initialBevZoom,
            150 / initialBevZoom
        );

        const crossPos = this.bevPointTo3D(
            this.state.bevBoxCenterU,
            this.state.bevBoxCenterV
        );
        let cross3D = Cross(crossPos[0], crossPos[2], box.top, 1);

        arrow3D = this.rotateObjectOnGroundPlane(
            arrow3D,
            box.orientation_rad,
            box.center_w,
            box.center_l
        );

        //TODO: having multiple boxes in a results view shows that this code below makes no sense
        const p1 = box3D[0][0];
        const p2 = box3D[1][1];
        this.boxCenter = {
            center_w: p1[0] + (p2[0] - p1[0]) / 2,
            center_l: p1[2] + (p2[2] - p1[2]) / 2
        };

        return { box: box3D, arrow: arrow3D, cross: cross3D };
    }

    createBoxPlotSVG(statistics, boxes) {
        let boxWidthX = 0;
        let boxWidthY = 0.5;
        let boxplotWidth = boxes[0].width / 4;

        let boxplotArr = [];
        let outlierArr = [];
        let meanLineArr = [];
        let box = null;
        let outliers = null;
        let meanLine = null;

        for (let [key, value] of Object.entries(this.props.showBoxplotPara)) {
            if (value && key !== "height_0" && key !== "height_1") {
                const boxParameter = statistics[0][key];

                const q25 = boxParameter.quantiles["q0.25"];
                const q75 = boxParameter.quantiles["q0.75"];
                const q90 = boxParameter.quantiles["q0.90"];
                const q10 = boxParameter.quantiles["q0.10"];
                const outlier_low = boxParameter.quantiles["q0.00"];
                const outlier_high = boxParameter.quantiles["q1.00"];
                const mean = boxParameter.mean;

                if (
                    key === "width_l" ||
                    key === "width_r" ||
                    key === "center_w"
                ) {
                    boxWidthX = 0;
                    boxWidthY = boxplotWidth;

                    box = Boxplot2D(
                        boxWidthX,
                        boxWidthY,
                        q25,
                        boxes[0].center_l,
                        1,
                        q75,
                        boxes[0].center_l,
                        q10,
                        q90,
                        true
                    );

                    outliers = [
                        [
                            [outlier_low, 1, boxes[0].center_l, 1],
                            [outlier_high, 1, boxes[0].center_l, 1]
                        ]
                    ];

                    meanLine = [
                        [
                            [mean, 1, boxes[0].center_l - boxWidthY, 1],
                            [mean, 1, boxes[0].center_l + boxWidthY, 1]
                        ]
                    ];
                } else if (
                    key === "length_f" ||
                    key === "length_b" ||
                    key === "center_l"
                ) {
                    boxWidthX = boxplotWidth;
                    boxWidthY = 0;

                    box = Boxplot2D(
                        boxWidthX,
                        boxWidthY,
                        boxes[0].center_w,
                        q75,
                        1,
                        boxes[0].center_w,
                        q25,
                        q10,
                        q90,
                        false
                    );

                    outliers = [
                        [
                            [boxes[0].center_w, 1, outlier_low, 1],
                            [boxes[0].center_w, 1, outlier_high, 1]
                        ]
                    ];

                    meanLine = [
                        [
                            [boxes[0].center_w - boxWidthX, 1, mean, 1],
                            [boxes[0].center_w + boxWidthX, 1, mean, 1]
                        ]
                    ];
                }

                box = this.rotateObjectOnGroundPlane(
                    box,
                    boxes[0].orientation_rad,
                    boxes[0].center_w,
                    boxes[0].center_l
                );

                outliers = this.rotateObjectOnGroundPlane(
                    outliers,
                    boxes[0].orientation_rad,
                    boxes[0].center_w,
                    boxes[0].center_l
                );

                meanLine = this.rotateObjectOnGroundPlane(
                    meanLine,
                    boxes[0].orientation_rad,
                    boxes[0].center_w,
                    boxes[0].center_l
                );

                boxplotArr.push(box);
                outlierArr.push(outliers);
                meanLineArr.push(meanLine);
            }
        }

        return {
            boxplot: boxplotArr,
            outliers: outlierArr,
            meanline: meanLineArr
        };
    }
    createBoxplotHeight(statistics, boxes) {
        let boxWidthX = 0;
        let boxWidthY = 0.5;
        let boxplotWidth = boxes[0].width / 4;

        let boxHeightArr = [];
        let outlierArr = [];
        let meanLineArr = [];
        let boxHeight = null;
        let whiskerBot = null;
        let whiskerTop = null;
        let outliers = null;
        let meanLine = null;

        for (let [key, value] of Object.entries(this.props.showBoxplotPara)) {
            if (value) {
                const boxParameter = statistics[0][key];

                const q25 = boxParameter.quantiles["q0.25"];
                const q75 = boxParameter.quantiles["q0.75"];
                const q90 = boxParameter.quantiles["q0.90"];
                const q10 = boxParameter.quantiles["q0.10"];
                const outlier_low = boxParameter.quantiles["q0.00"];
                const outlier_high = boxParameter.quantiles["q1.00"];
                const mean = boxParameter.mean;

                if (key === "height_0" || key === "height_1") {
                    boxWidthX = boxplotWidth;
                    boxWidthY = boxplotWidth;

                    boxHeight = Box3D(
                        boxWidthX,
                        boxWidthY,
                        boxWidthX,
                        boxWidthY,
                        q25,
                        q75,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    whiskerBot = Box2D(
                        boxWidthX,
                        boxWidthY,
                        boxWidthX,
                        boxWidthY,
                        q10,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    whiskerTop = Box2D(
                        boxWidthX,
                        boxWidthY,
                        boxWidthX,
                        boxWidthY,
                        q90,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    outliers = [
                        [
                            [
                                boxes[0].center_w,
                                outlier_low,
                                boxes[0].center_l,
                                1
                            ],
                            [
                                boxes[0].center_w,
                                outlier_high,
                                boxes[0].center_l,
                                1
                            ]
                        ]
                    ];

                    meanLine = Box2D(
                        boxWidthX,
                        boxWidthY,
                        boxWidthX,
                        boxWidthY,
                        mean,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    boxHeight = boxHeight.concat(whiskerBot, whiskerTop);

                    boxHeight = this.rotateObjectOnGroundPlane(
                        boxHeight,
                        boxes[0].orientation_rad,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    meanLine = this.rotateObjectOnGroundPlane(
                        meanLine,
                        boxes[0].orientation_rad,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    outliers = this.rotateObjectOnGroundPlane(
                        outliers,
                        boxes[0].orientation_rad,
                        boxes[0].center_w,
                        boxes[0].center_l
                    );

                    boxHeightArr.push(boxHeight);
                    outlierArr.push(outliers);
                    meanLineArr.push(meanLine);
                }
            }
        }

        return {
            boxHeight: boxHeightArr,
            meanline: meanLineArr,
            outliers: outlierArr
        };
    }

    getBoxSvg(boxes, style, viewBox, rgb, boxVis) {
        if ((boxes === null || boxes.length === 0) && boxVis) {
            boxes = [this.get3DBoxParameters(false)]; // list of one box with drawn box
        }

        if (boxes !== null && boxes.length > 0) {
            const svgBoxes = [];
            const svgArrows = [];
            const svgCrosses = [];
            let strokeWidth = 0.5;
            const projectionMethod = (() => {
                if (rgb === true) {
                    return this.projectObjectToRgb.bind(this);
                }
                return this.projectObjectToBev.bind(this);
            })();

            for (let idx = 0; idx < boxes.length; idx++) {
                const box = boxes[idx];
                const geometry = this.getBoxWithArrowFromParameters(box);
                const color = BOX_COLORS[idx % BOX_COLORS.length];
                // assumes the first box is the aggregated box and should be drawn thicker
                // than the rest
                if (idx === 0) {
                    strokeWidth = 2;
                }

                const svgBox = projectionMethod(
                    geometry.box,
                    color,
                    strokeWidth,
                    1
                );
                const svgBoxKey = "box_" + idx;
                svgBoxes.push(
                    <g key={svgBoxKey} id={svgBoxKey}>
                        {svgBox}
                    </g>
                );

                const svgArrow = projectionMethod(
                    geometry.arrow,
                    color,
                    strokeWidth,
                    1
                );
                const svgArrowKey = "arrow_" + idx;
                svgArrows.push(
                    <g key={svgArrowKey} id={svgArrowKey}>
                        {svgArrow}
                    </g>
                );
                const svgCross = projectionMethod(
                    geometry.cross,
                    color,
                    strokeWidth,
                    1
                );
                const svgCrossKey = "cross_" + idx;
                svgCrosses.push(
                    <g key={svgCrossKey} id={svgCrossKey}>
                        {svgCross}
                    </g>
                );
            }
            let bevboxplot = [];
            let rgbBoxplot = [];
            let outlier_array = [];
            if (
                this.props.statistics &&
                boxes.length === 1 &&
                rgb === false &&
                this.props.pointcloudView === "Bev"
            ) {
                const { boxplot, outliers, meanline } = this.createBoxPlotSVG(
                    this.props.statistics,
                    boxes
                );
                if (boxplot.length != null) {
                    for (let i = 0; i < boxplot.length; i++) {
                        bevboxplot.push(
                            this.projectObjectToBev(boxplot[i], "white", 4, 1)
                        );

                        bevboxplot.push(
                            this.projectObjectToBev(meanline[i], "orange", 4, 1)
                        );

                        const cam = this.getPointcloudProjectionMatrix();
                        let projection = this.projectObject(outliers[i], cam);
                        projection[0].forEach(outlier => {
                            outlier_array.push(svgCircle(outlier, "white"));
                        });
                    }
                }
            } else if (
                this.props.statistics &&
                boxes.length === 1 &&
                rgb === false &&
                this.props.pointcloudView === "Front"
            ) {
                const {
                    boxHeight,
                    meanline,
                    outliers
                } = this.createBoxplotHeight(this.props.statistics, boxes);
                if (boxHeight.length != null) {
                    for (let i = 0; i < boxHeight.length; i++) {
                        rgbBoxplot.push(
                            this.projectObjectToBev(boxHeight[i], "white", 4, 1)
                        );

                        rgbBoxplot.push(
                            this.projectObjectToBev(meanline[i], "orange", 4, 1)
                        );

                        const cam = this.getPointcloudProjectionMatrix();
                        let projection = this.projectObject(outliers[i], cam);
                        projection[0].forEach(outlier => {
                            outlier_array.push(svgCircle(outlier, "white"));
                        });
                    }
                }
            }

            if (this.props.objVis === "box") {
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={viewBox}
                        style={style}
                    >
                        {rgbBoxplot}
                        {bevboxplot}
                        {outlier_array}
                        {svgBoxes}
                        {svgArrows}
                    </svg>
                );
            } else if (this.props.objVis === "cross") {
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox={viewBox}
                        style={style}
                    >
                        {svgCrosses}
                    </svg>
                );
            }
        }

        return null;
    }

    // create all SVG objects for the BEV overlay
    getBevSvg() {
        const style = {
            clipPath:
                "inset(0, 0, " +
                this.bevCanvasSize +
                ", " +
                this.bevCanvasSize +
                ")"
        };
        const viewBox = "0 0 " + this.bevCanvasSize + " " + this.bevCanvasSize;
        return this.getBoxSvg(
            this.props.boxes,
            style,
            viewBox,
            false,
            this.state.showBox.bevView
        );
    }

    // projection
    projectObjectToBev(object, color, strokeWidth, opacity) {
        const cam = this.getPointcloudProjectionMatrix();
        let projection = this.projectObject(object, cam);
        return createObjectSVG(projection, color, strokeWidth, opacity);
    }

    // projection
    projectObject(object, matrix) {
        let toProject = object;
        if (matrix.length === 3) {
            toProject = this.makeNonHomogenous(object);
        }
        let projection = [];

        toProject.forEach(line => {
            const lineStart = this.multNxNbyNx1norm(matrix, line[0]);
            const lineEnd = this.multNxNbyNx1norm(matrix, line[1]);
            projection.push([lineStart, lineEnd]);
        });
        return projection;
    }

    // calc
    makeNonHomogenous(object) {
        let res = [];
        object.forEach(line => {
            const lineStart = [line[0][0], line[0][1], line[0][2]];
            const lineEnd = [line[1][0], line[1][1], line[1][2]];
            res.push([lineStart, lineEnd]);
        });
        return res;
    }

    // calc
    multNxNbyNx1norm(A, x) {
        let res = [];
        const N = A.length; //assumes A is NxN and x is Nx1
        for (let r = 0; r < N; r++) {
            res.push(0);
            for (let c = 0; c < N; c++) {
                res[r] += A[r][c] * x[c];
            }
        }
        const norm = res[N - 1];
        if (norm === 0) {
            console.log("Zero norm found for A*x", A, x, res);
        }
        for (let r = 0; r < N - 1; r++) {
            res[r] /= norm;
        }
        res.pop();
        return res;
    }

    // on mount
    preprocessInput() {
        if (!this.drawingCanvasesReady()) {
            return;
        }

        const data = this.props.pcData;
        let points = data.pointsTableRowList;
        let minY = 1000;
        let maxY = -1000;
        let minX = 1000;
        let maxX = -1000;
        let minZ = 1000;
        let maxZ = -1000;
        // we want the BEV to be in the x-z plane and
        // y as up-vector. Current data is stored differently on server
        let step = 1;
        const maxPointCount = 25000;
        if (points.length > maxPointCount) {
            //take every nth point so that we have fewer points
            step = Math.round(points.length / maxPointCount);
        }
        let res = [];
        for (let i = 0; i < points.length; i += step) {
            const p = points[i];
            if (this.props.pcData.dataProcessed !== true) {
                p.x = -p.x;
                const z = p.z;
                p.z = -p.y;
                p.y = -z;
            }

            if (p.y < minY) minY = p.y;
            if (p.y > maxY) maxY = p.y;
            if (p.x < minX) minX = p.x;
            if (p.x > maxX) maxX = p.x;
            if (p.z < minZ) minZ = p.z;
            if (p.z > maxZ) maxZ = p.z;
            res.push(p);
        }

        const r = { x: maxX - minX, y: maxY - minY, z: maxZ - minZ };
        const c = { x: r.x / 2 + minX, y: r.y / 2 + minY, z: r.z / 2 + minZ };
        const w = this.bevCanvasSize;
        let maxR = Math.max(r.x, r.z);
        let newZoom = w / maxR;

        // when there's already a box, zoom to that box instead of the pointcloud
        if (this.props.showBox.bevView) {
            c.x = this.props.centerW;
            c.z = this.props.centerL;
            newZoom = this.bevZoomForCOG;
        }

        let cBevX = -c.x * newZoom;
        let cBevY = -c.z * newZoom;

        // in case we are using the resultsview, we will have a number of boxes
        // the first box will be the aggregated box, which we use for zoom
        if (
            this.props.boxes !== null &&
            this.props.boxes !== undefined &&
            this.props.boxes.length > 0
        ) {
            const agg = this.props.boxes[0];
            newZoom = this.bevZoomForCOG / 1.5; //zoom out a bit for seeing all boxes
            cBevX = -agg.center_w * newZoom;
            cBevY = -agg.center_l * newZoom;
        }
        this.bevVisCenter = { u: cBevX, v: cBevY };
        this.dataProcessed = true;
        this.bevZoom = newZoom;
        console.log("Data preprocessing done");

        // preprocessing is done in place (points are objects) so avoid multiple preprocessing
        // runs on the same pcData! --> e.g. going back and forth between instructions and the task
        this.props.pcData.dataProcessed = true;
    }

    // on mount
    projectPoints(bevOnly = false) {
        if (!this.drawingCanvasesReady()) {
            return;
        }

        const data = this.props.pcData;
        let points = data.pointsTableRowList;
        let minY = 1000;
        let maxY = -1000;
        let minZ = 1000;
        let maxZ = -1000;

        // calc only once after switching dimensions
        if (this.relevantYQuantile === null) {
            this.relevantYQuantile = quantileSeq(
                // remember this is before the switching of point dimensions
                points.map(p => p.y),
                0.95
            );
        }

        for (const p of points) {
            if (p.y < minY) minY = p.y;
            if (p.y > maxY) maxY = p.y;
            if (p.z < minZ) minZ = p.z;
            if (p.z > maxZ) maxZ = p.z;

            if (!bevOnly && this.checkPointConstraint(p)) {
                const pRgb = this.project3DPointToRgb([p.x, p.y, p.z]);
                p.uRgb = Math.round(pRgb[0]);
                p.vRgb = Math.round(pRgb[1]);
            }
        }

        const camBev = this.getPointcloudProjectionMatrix();
        for (const p of points) {
            if (this.checkPointConstraint(p)) {
                const pBev = this.multNxNbyNx1norm(camBev, [p.x, p.y, p.z, 1]);
                p.uBev = Math.round(pBev[0]);
                p.vBev = Math.round(pBev[1]);
            }
            p.colorIdx = this.colormap.getColorIdxFor(
                p.y,
                minY,
                this.relevantYQuantile
            );
        }
        this.pointMinY = minY;
        this.pointMaxY = maxY;

        if (!bevOnly) {
            this.renderPointsRgb(
                points,
                this.drawingCanvases["rgbPointsHighlighted"]
            );
        }
        this.renderPointsBev(points, this.drawingCanvases["bevHighlighted"]);
        this.pointsProjected = true;
    }

    // ready check
    drawingCanvasesReady() {
        return Object.keys(this.drawingCanvases).length === this.canvasAmount;
    }

    showBev(img_transform) {
        if (this.state.showBev) {
            const bevCanvases = this.getBevCanvases();
            return (
                <>
                    <div className="growable">
                        <div
                            className="img-overlay-wrap"
                            ref={this.scrollLockTargetRef}
                            style={{ transform: img_transform }}
                            onMouseEnter={() => {
                                if (this.props.bevZoomEnabled) {
                                    disableBodyScroll(
                                        this.scrollLockTargetRef.current,
                                        {
                                            reserveScrollBarGap: true
                                        }
                                    );
                                }
                            }}
                            onMouseLeave={e => {
                                if (this.props.bevZoomEnabled) {
                                    enableBodyScroll(
                                        this.scrollLockTargetRef.current
                                    );
                                }
                                this.handleButtonRelease(e);
                            }}
                            onTouchStart={this.handleButtonPress.bind(this)}
                            onTouchEnd={this.handleButtonRelease.bind(this)}
                            onMouseDown={this.handleButtonPress.bind(this)}
                            onMouseUp={this.handleButtonRelease.bind(this)}
                        >
                            {/* TOOD: create a configurable multi-layered canvas component */}
                            {bevCanvases[0]}
                            {bevCanvases[1]}
                            {this.getBevSvg()}
                            {this.getBevActionsCanvas()}
                        </div>
                    </div>
                    <div style={{ display: "none" }}>
                        {bevCanvases[2]}
                        {bevCanvases[3]}
                    </div>
                </>
            );
        } else {
            return null;
        }
    }

    /**
     * Bundles canvas renders that need to be done after a state update
     */
    canvasRenderOnStateUpdate() {
        if (this.props.useBevActionsCanvas && this.drawingCanvasesReady()) {
            this.renderBEVActions();
            this.renderRgbActions();
        }
        return null;
    }

    render() {
        this.canvasAmount = 0;
        const img_transform = "translate(0px, 0px)";
        const rgbCanvases = this.getRgbCanvases();
        return (
            <>
                <div className="growable">
                    <div
                        className="img-overlay-wrap"
                        style={{ transform: img_transform }}
                    >
                        {rgbCanvases[0]}
                        {this.getRgbSvg()}
                        {rgbCanvases[1]}
                        {this.getRgbActionsCanvas()}
                    </div>
                </div>
                <div style={{ display: "none" }}>
                    {rgbCanvases[2]}
                    {rgbCanvases[3]}
                </div>
                {this.showBev(img_transform)}
                {this.canvasRenderOnStateUpdate()}
            </>
        );
    }
}

PointcloudProjection.defaultProps = {
    setBoxDataAccessor: () => {},
    setSliderValue: () => {},
    bevClickEnabled: true,
    bevZoomEnabled: false,
    bevZoomSettings: { initial: 180, min: 10, max: 500 },
    boxes: null
};

PointcloudProjection.propTypes = {
    currentImageObject: PropTypes.instanceOf(HTMLImageElement),
    pcData: PropTypes.object,
    boxWidthLeft: PropTypes.number,
    boxWidthRight: PropTypes.number,
    boxHeightTop: PropTypes.number,
    boxHeightBot: PropTypes.number,
    boxLengthTop: PropTypes.number,
    boxLengthBot: PropTypes.number,
    boxOrientation: PropTypes.number,
    boxGround: PropTypes.number,
    setBoxDataAccessor: PropTypes.func,
    bevClickEnabled: PropTypes.bool,
    boxes: PropTypes.array,
    objVis: PropTypes.string,
    centerW: PropTypes.number,
    centerL: PropTypes.number,
    objectMovement: PropTypes.bool,
    guiType: PropTypes.string,
    setSliderValue: PropTypes.func,
    showBox: PropTypes.object,
    showBev: PropTypes.bool,
    useBevActionsCanvas: PropTypes.bool,
    pointcloudView: PropTypes.string,
    pointVisibility: PropTypes.string,
    cloudOrientation: PropTypes.number,
    bevZoomEnabled: PropTypes.bool,
    bevZoomSettings: PropTypes.object,
    statistics: PropTypes.array,
    showBoxplotPara: PropTypes.object
};

export default PointcloudProjection;
