import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialResourceCache = {};

export const resourceCacheReducer = generateReducer(initialResourceCache, {
    [ActionTypes.ADD_RESOURCE_TO_CACHE]: (resourceCache, action) => {
        return {...resourceCache, ...{[action.url]: action.resource}};
    }
});

export const getResourceCache = state => {
    return state.global.resourceCache;
};
