import React from "react";
import { Range } from "rc-slider";

import CanvasImage from "../CanvasImage/CanvasImage";
import { getGrayscaleColor, getNeighbors } from "../../lib/imageDataUtils";

import "bootstrap/dist/css/bootstrap.min.css";
import "rc-slider/assets/index.css";

const KEYPOINT_COLOR = "rgba(214, 0, 0, 0.3)";
const SELECTION_COLOR = "rgba(191, 63, 191, 0.4)";

/**
 * Gets the location of a click event inside a canvas. Necessary helper method
 * to handle the canvas' global location inside the rest of the website.
 */
const getClickLocation = (event, canvas) => {
    const rect = canvas.getBoundingClientRect();
    const elementRelativeX = event.clientX - rect.left;
    const elementRelativeY = event.clientY - rect.top;
    const x = Math.round((elementRelativeX * canvas.width) / rect.width);
    const y = Math.round((elementRelativeY * canvas.height) / rect.height);
    return [x, y];
};

/**
 * Highlights passed pixels (coordinates as [x, y] tuples) in a given canvas context.
 */
const highlightPixels = (ctx, pixels) => {
    ctx.fillStyle = SELECTION_COLOR;
    pixels.forEach(([x, y]) => ctx.fillRect(x, y, 1, 1));
};

/**
 * Traverse the neighborhood of a single passed pixel within a given context
 * by considering the neighboring pixels' color in contrast to the neighborhoods
 * mean color. The given threshold basically adjusts the size of the extractd
 * segment.
 *
 * Returns [x, y] coordinates of all pixels within the segment.
 */
const traverseNeighbors = (ctx, x, y, thresh) => {
    const imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    const visit_neighbors = getNeighbors(imgData, x, y).map((n) => [
        n[0],
        n[1],
    ]);
    const visited = new Set([]);
    const segment_pixels = [];

    // Track segment's mean color in order to compare new neighbors to.
    let sum = getGrayscaleColor(imgData, x, y);
    let count = 1;

    const handleNeighbor = ([x, y, v]) => {
        if (!visited.has(`${x},${y}`)) {
            visited.add(`${x},${y}`);
            if (Math.sqrt(Math.pow(sum / count - v, 2)) < thresh) {
                sum += v;
                count += 1;
                segment_pixels.push([x, y]);
                visit_neighbors.push([x, y]);
            }
        }
    };

    while (visit_neighbors.length > 0) {
        [x, y] = visit_neighbors.shift();
        getNeighbors(imgData, x, y).forEach(handleNeighbor);
    }

    return segment_pixels;
};

/**
 * @augments {React.Component<Props, State>}
 */
class KeypointSegmentCanvas extends React.Component {
    constructor(props) {
        super(props);
        this.state = { threshold: 0 };
    }

    _callback(canvas, ctx, img) {
        // Handle keypoint selection if no keypoint was passed in props.
        canvas.onmousedown = (e) => {
            if (!this.props.keypoint) {
                this.setState({ keypoint: getClickLocation(e, canvas) });
            }
        };

        const keypoint = this.props.keypoint || this.state.keypoint;

        // Handle tresholding using previously selected keypoint. Has to happen
        // before drawing the selected keypoint as both currently modify
        // imageData, overwriting the keypoints original color in the imageData.
        if (keypoint) {
            const [x, y] = keypoint;
            if (this.state.threshold) {
                // `segment` contains a list of [x, y] tuples. This list is also the
                // result to be passed to th backend as answer. This could also maybe
                // be further processed before drawing using some fill-in algorithm
                // to get a smoother, less "holey" segment.
                const segment = traverseNeighbors(
                    ctx,
                    x,
                    y,
                    this.state.threshold
                );
                if (segment.length) {
                    highlightPixels(ctx, segment);
                }
            }

            // Draw keypoint, if any.
            ctx.fillStyle = KEYPOINT_COLOR;
            ctx.fillRect(x - 2, y - 2, 4, 4);
        }
    }

    render() {
        return (
            <>
                <CanvasImage
                    img_url={this.props.img_url}
                    canvas_width={this.props.canvas_width}
                    canvas_height={this.props.canvas_height}
                    callback_after_draw_finish={(canvas, ctx, img) =>
                        this._callback(canvas, ctx, img)
                    }
                />
                <Range
                    min={0}
                    max={1}
                    step={0.01}
                    defaultValue={[0]}
                    onChange={([threshold]) => this.setState({ threshold })}
                />
            </>
        );
    }
}

export default KeypointSegmentCanvas;
