import { taskRequestReducer } from "./taskRequest";
import { currentQuestionIdxReducer } from "./currentQuestionIdx";
import { imgCacheReducer } from "./imgCache";
import { answersReducer } from "./answers";
import { timestampReducer } from "./timestamp";
import { nextGuiTypeReducer } from "./nextGuiType";
import { guiSettingsReducer } from "./guiSettings";
import { guiTypeReducer } from "./guiType";
import { taskSubmitRequestReducer } from "./taskSubmitRequest";
import { thankYouReachedReducer } from "./thankYouReached";
import { mTurkInfosReducer } from "./mturkInfos";
import { taskResultReducer } from "./taskResult";
import { shouldShowInstructionsReducer } from "./shouldShowInstructions";
import { questionsAmountReducer } from "./questionsAmount";
import { resourceCacheReducer } from "./resourceCache";

export const globalReducer = (state = {}, action) => {
    return {
        taskRequest: taskRequestReducer(state.taskRequest, action),
        imgCache: imgCacheReducer(state.imgCache, action),
        questionsAmount: questionsAmountReducer(state.questionsAmount, action),
        currentQuestionIdx: currentQuestionIdxReducer(state, action),
        answers: answersReducer(state.answers, action),
        timestamp: timestampReducer(state.timestamp, action),
        taskResult: taskResultReducer(state.taskResult, action),
        guiType: guiTypeReducer(state.guiType, action),
        guiSettings: guiSettingsReducer(state.guiSettings, action),
        nextGuiType: nextGuiTypeReducer(state.nextGuiType, action),
        taskSubmitRequest: taskSubmitRequestReducer(
            state.taskSubmitRequest,
            action
        ),
        thankYouReached: thankYouReachedReducer(state.thankYouReached, action),
        mturkInfos: mTurkInfosReducer(state.mturkInfos, action),
        shouldShowInstructions: shouldShowInstructionsReducer(
            state.shouldShowInstructions,
            action
        ),
        resourceCache: resourceCacheReducer(state.resourceCache, action)
    };
};
