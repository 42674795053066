// eslint-disable-next-line
import { JSONSerializable } from "./JSONSerializable";
// eslint-disable-next-line
import { MTurkSubmittedAssignment } from "./models/MTurkSubmittedAssignment";
import ApiClient from "./ApiClient";

/**
 * Class for accessing the JSON Api of TaskApi
 * @class
 * @augments {ApiClient}
 */
export class TaskApiClient extends ApiClient {
    /**
     * TODO
     */
    authenticate() {}

    /**
     *
     * @param {String} taskId
     * @param {apiCallCallback} callback
     * @param {boolean=} withGuiSettings
     */
    getTask(taskId, callback, withGuiSettings = false) {
        const requestObject = {
            task_id: taskId
        };
        if (withGuiSettings) {
            requestObject.with_gui_settings = true;
        }
        const url = this.getUrlWithParams("/task", requestObject);
        this.makeApiCall(url, callback);
    }

    /**
     * @param {JSONSerializable} taskResult - the exact taskResult and answer schemas depends on the guiType and guiSettings
     * @param {apiCallCallback} callback
     */
    submitTask(taskResult, callback) {
        const url = this.getUrlWithParams("/task/submit");
        this.makeApiCall(url, callback, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(taskResult)
        });
    }

    /**
     * @param {MTurkSubmittedAssignment} mturkAssignment
     * @param {apiCallCallback} callback
     */
    submitMTurkAssignment(mturkAssignment, callback) {
        const url = this.getUrlWithParams("/task/submit/mturkAssignment");
        this.makeApiCall(url, callback, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(mturkAssignment)
        });
    }
}
