import React from "react";

import YesNoMaybeView from "./containers/YesNoMaybe/YesNoMaybeView";
import SubmitView from "./containers/Submit/SubmitView";
import ThankYouView from "./containers/ThankYou/ThankYouView";
// eslint-disable-next-line
import TaskUIContext from "./lib/TaskUIStrategy/taskUIContext";
import TemplateUI from "./containers/TemplateUI/TemplateUIView";
import AudioPlayerUIView from "./containers/AudioPlayerUI/AudioPlayerUIView";
import KeypointSelectionView from "./containers/KeypointSelection/KeypointSelectionView";
import ZoomKeypointSelectionView from "./containers/ZoomKeypointSelection/ZoomKeypointSelection";
import PointcloudProjectionView from "./containers/PointcloudProjection/PointcloudProjectionView";
import PointcloudProjectionResultsView from "./containers/PointcloudProjection/PointcloudProjectionResultsView";

/**
 * @typedef {String} WIDGET
 */

/**
 * @enum {WIDGET}
 */
export const WIDGETS = {
    // the different task gui types
    YES_NO_MAYBE: "yes_no_maybe",
    DISCRETE_ANSWERS_GUI: "discrete_answers_gui",
    SPOTIFY_AUDIO_PLAYER: "spotify_audio_player",
    SUBMIT: "submit",
    THANK_YOU: "thank_you",
    LOADING: "loading",
    GENERIC_ERROR: "generic_error",
    NO_TASKS_AVAILABLE: "no_tasks_available",
    MALFORMED_USER_IDS: "malformed_user_ids",
    TEMPLATE_UI: "template_ui",
    KEYPOINT_SELECTION: "keypoint_selection",
    ZOOM_KEYPOINT_SELECTION: "zoom_keypoint_selection",
    POINTCLOUD_PROJECTION: "pointcloud_projection",
    POINTCLOUD_PROJECTION_RESULTS: "pointcloud_projection_results"
};

/**
 * Creates a modular user interface. Returns null if gui switching is not permitted.
 * @param {TaskUIContext} taskUIContext
 * @param {=String} nextGuiType if this is set, then this will be used as guiType instead of taskUIContext.getGuiType
 * @param {=Object} globalState is used so that taskUIContext can decide whether switching gui can be allowed
 *
 * @returns {Gui}
 */
const guiFactory = (taskUIContext, nextGuiType = "", globalState) => {
    /**
     * @typedef {Object} Gui
     * @property {String} name - the name of the gui. One of WIDGETS
     * @property {React.ReactElement} container - react element that is the gui
     */
    let gui = {
        name: "",
        container: null
    };
    const guiType =
        nextGuiType === "" ? taskUIContext.getInitialGuiType() : nextGuiType;

    switch (guiType) {
        case WIDGETS.DISCRETE_ANSWERS_GUI:
        case WIDGETS.YES_NO_MAYBE:
            gui.container = <YesNoMaybeView taskUIContext={taskUIContext} />;
            break;
        case WIDGETS.SPOTIFY_AUDIO_PLAYER:
            gui.container = <AudioPlayerUIView taskUIContext={taskUIContext} />;
            break;
        case WIDGETS.SUBMIT:
            if (
                taskUIContext.canSwitchToGui(guiType, {
                    globalState: globalState
                })
            ) {
                gui.container = <SubmitView taskUIContext={taskUIContext} />;
            } else {
                gui = null;
            }
            break;
        case WIDGETS.THANK_YOU:
            gui.container = <ThankYouView taskUIContext={taskUIContext} />;
            break;
        case WIDGETS.LOADING:
            // can be replaced with proper loading view if necessary
            gui.container = <></>;
            break;
        case WIDGETS.TEMPLATE_UI:
            gui.container = <TemplateUI taskUIContext={taskUIContext} />;
            break;
        case WIDGETS.KEYPOINT_SELECTION:
            gui.container = (
                <KeypointSelectionView taskUIContext={taskUIContext} />
            );
            break;
        case WIDGETS.ZOOM_KEYPOINT_SELECTION:
            gui.container = (
                <ZoomKeypointSelectionView taskUIContext={taskUIContext} />
            );
            break;
        case WIDGETS.POINTCLOUD_PROJECTION:
            gui.container = (
                <PointcloudProjectionView taskUIContext={taskUIContext} />
            );
            break;
        case WIDGETS.POINTCLOUD_PROJECTION_RESULTS:
            gui.container = (
                <PointcloudProjectionResultsView
                    taskUIContext={taskUIContext}
                />
            );
            break;
        case WIDGETS.GENERIC_ERROR:
            gui.container = (
                <>
                    <h1>Sorry! Something went wrong on our side.</h1>
                </>
            );
            break;
        case WIDGETS.NO_TASKS_AVAILABLE:
            gui.container = (
                <>
                    <h1>There are currently no more tasks available.</h1>
                </>
            );
            break;
        case WIDGETS.MALFORMED_USER_IDS:
            gui.container = (
                <>
                    <h1>
                        ERROR: bad values for vendor_id and or vendor_user_id.
                    </h1>
                </>
            );
            break;
        default:
            gui.container = <h1>ERROR: gui type {guiType} unknown</h1>;
    }

    if (gui !== null) {
        gui.name = guiType;
    }

    return gui;
};

export default guiFactory;
