import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialMTurkInfos = {
    runsOnMTurk: false
};

export const mTurkInfosReducer = generateReducer(initialMTurkInfos, {
    [ActionTypes.ADD_MTURK_INFOS]: (mturkInfos, action) => {
        return { ...mturkInfos, ...action.mturkInfos };
    }
});

export const getMTurkInfos = state => state.global.mturkInfos;
