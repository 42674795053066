export const Cross = (centerW, centerL, height, offset) => {
    const cross = [];
    cross.push([
        [centerW + offset, height, centerL + offset, 1],
        [centerW - offset, height, centerL - offset, 1]
    ]);

    cross.push([
        [centerW + offset, height, centerL - offset, 1],
        [centerW - offset, height, centerL + offset, 1]
    ]);

    return cross;
};

export const Arrow = (centerW, centerL, height, midLength, rlSpan) => {
    const arrow = [];
    midLength /= 3;
    rlSpan /= 2;
    arrow.push([
        [centerW, height, centerL - midLength, 1],
        [centerW, height, centerL + midLength, 1]
    ]);

    arrow.push([
        [centerW - rlSpan, height, centerL + midLength / 4, 1],
        [centerW, height, centerL - midLength, 1]
    ]);

    arrow.push([
        [centerW + rlSpan, height, centerL + midLength / 4, 1],
        [centerW, height, centerL - midLength, 1]
    ]);

    return arrow;
};

export const MiddlePoint = (
    widthLeft,
    widthRight,
    lengthTop,
    lengthBot,
    centerW,
    centerL
) => {
    let middlepoint = [];
    middlepoint.push(
        [
            centerW + widthRight - 2 - widthLeft + 2,
            0,
            centerL - lengthTop + lengthBot,
            1
        ],
        [0, 0, 0, 0]
    );

    return [middlepoint];
};

export const getCenterPointOfLine = line => {
    return {
        x: line[1][0] + (line[0][0] - line[1][0]) / 2,
        y: line[0][1] + (line[1][1] - line[0][1]) / 2
    };
};

export const Boxplot3D = (
    width,
    len,
    x1,
    y1,
    height,
    height2,
    x2,
    y2,
    whiskerBot,
    whiskerTop,
    isWidth
) => {
    const boxplotBot = Boxplot2D(
        width,
        len,
        x1,
        y1,
        height,
        x2,
        y2,
        whiskerBot,
        whiskerTop,
        isWidth
    );

    const boxplotTop = Boxplot2D(
        width,
        len,
        x1,
        y1,
        height2,
        x2,
        y2,
        whiskerBot,
        whiskerTop,
        isWidth
    );

    const connections = connectBoxes(boxplotBot, boxplotTop);

    return boxplotBot.concat(boxplotTop, connections);
};

export const Boxplot2D = (
    width,
    len,
    x1,
    y1,
    height,
    x2,
    y2,
    whiskerBot,
    whiskerTop,
    isWidth
) => {
    let box = [];
    box.push([
        [x1 - width, height, y1 - len, 1],
        [x2 - width, height, y2 - len, 1]
    ]);

    box.push([
        [x2 - width, height, y2 - len, 1],
        [x2 + width, height, y2 + len, 1]
    ]);

    box.push([
        [x2 + width, height, y2 + len, 1],
        [x1 + width, height, y1 + len, 1]
    ]);

    box.push([
        [x1 + width, height, y1 + len, 1],
        [x1 - width, height, y1 - len, 1]
    ]);

    if (isWidth) {
        //Whisker Connection

        box.push([
            [x1 + width, height, y1, 1],
            [whiskerBot - width, height, y1, 1]
        ]);

        //Whisker
        box.push([
            [whiskerBot + width, height, y1 + len, 1],
            [whiskerBot - width, height, y1 - len, 1]
        ]);

        //Whisker Connection
        box.push([
            [whiskerTop + width, height, y2 + len, 1],
            [whiskerTop - width, height, y2 - len, 1]
        ]);

        //Whisker
        box.push([
            [x2 + width, height, y2, 1],
            [whiskerTop - width, height, y2, 1]
        ]);
    } else {
        box.push([
            [x1, height, y1 + len, 1],
            [x1, height, whiskerBot - len, 1]
        ]);

        //Whisker
        box.push([
            [x1 + width, height, whiskerBot + len, 1],
            [x1 - width, height, whiskerBot - len, 1]
        ]);

        //Whisker Connection
        box.push([
            [x2 + width, height, whiskerTop + len, 1],
            [x2 - width, height, whiskerTop - len, 1]
        ]);

        //Whisker
        box.push([
            [x2, height, y2 + len, 1],
            [x2, height, whiskerTop - len, 1]
        ]);
    }

    return box;
};

export const Box2D = (
    widthLeft,
    widthRight,
    lengthTop,
    lengthBot,
    height,
    centerW,
    centerL
) => {
    let box = [];

    box.push([
        [centerW - widthRight, height, centerL - lengthTop, 1],
        [centerW - widthRight, height, centerL + lengthBot, 1]
    ]);

    box.push([
        [centerW - widthRight, height, centerL + lengthBot, 1],
        [centerW + widthLeft, height, centerL + lengthBot, 1]
    ]);

    box.push([
        [centerW + widthLeft, height, centerL + lengthBot, 1],
        [centerW + widthLeft, height, centerL - lengthTop, 1]
    ]);

    box.push([
        [centerW + widthLeft, height, centerL - lengthTop, 1],
        [centerW - widthRight, height, centerL - lengthTop, 1]
    ]);

    return box;
};

// const connectOppositeEdgePoints = (connection, h0, h1) => {
//     //cross in box plane
//     connection.push([h0[0][0], h1[0][1]]);
//     connection.push([h0[0][1], h1[0][0]]);

//     connection.push([h0[1][0], h1[1][1]]);
//     connection.push([h0[1][1], h1[1][0]]);

//     connection.push([h0[3][0], h1[3][1]]);
//     connection.push([h0[3][1], h1[3][0]]);

//     connection.push([h0[2][0], h1[2][1]]);
//     connection.push([h0[2][1], h1[2][0]]);

//     return connection;
// };

export const connectBoxes = (h0, h1) => {
    let connection = [];
    for (let i = 0; i < h0.length; ++i) {
        connection.push([h0[i][0], h1[i][0]]);
        connection.push([h0[i][1], h1[i][1]]);
    }

    // connection = connectOppositeEdgePoints(connection, h0, h1);

    return connection;
};

export const Box3D = (
    widthLeft,
    widthRight,
    lengthTop,
    lengthBot,
    height0,
    height1,
    centerW,
    centerL
) => {
    let boxBottom = Box2D(
        widthLeft,
        widthRight,
        lengthTop,
        lengthBot,
        height0,
        centerW,
        centerL
    );
    let boxTop = Box2D(
        widthLeft,
        widthRight,
        lengthTop,
        lengthBot,
        height1,
        centerW,
        centerL
    );
    const connections = connectBoxes(boxBottom, boxTop);
    return boxBottom.concat(boxTop, connections);
};

export const Grid = (width, length, height0, stepSize) => {
    let grid = [];
    //start at x = h = 1 because 0 is the camera center
    for (var x = 1; x <= width; x += stepSize) {
        grid.push([
            [x, height0, 1, 1],
            [x, height0, length, 1]
        ]);
    }
    for (var h = 1; h <= length; h += stepSize) {
        grid.push([
            [1, height0, h, 1],
            [width, height0, h, 1]
        ]);
    }
    return grid;
};

export const dotProduct = (a, b) => {
    let res = 0;
    for (const key in a) {
        res += a[key] * b[key];
    }
    return res;
};

export const magnitude = a => {
    let res = 0;
    for (const key in a) {
        res += a[key] * a[key];
    }
    return Math.sqrt(res);
};
