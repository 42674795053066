import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialQuestionsAmount = 0;

export const questionsAmountReducer = generateReducer(initialQuestionsAmount, {
    [ActionTypes.SET_QUESTIONS_AMOUNT]: (questionsAmount, action) => {
        return action.questionsAmount;
    }
});

export const getQuestionsAmount = state => state.global.questionsAmount;
