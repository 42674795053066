import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import {
    questionNext,
    questionPrevious,
    setAllAnswersForQuestion,
    setNextGuiType,
    captureTimestamp,
    setTaskResult
} from "../../modules/TaskUI/actions";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getTimestamp } from "../../modules/TaskUI/reducers/timestamp";
import { getAnswers } from "../../modules/TaskUI/reducers/answers";
import { WIDGETS } from "../../guiFactory";
import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import { allImagesLoaded } from "../../modules/TaskUI/reducers/imgCache";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import actionButtonFactory from "../TaskUI/actionButtonFactory";

import { yesNoMaybeButtonConfigs } from "../../mock_data";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";

/**
 * @augments {React.Component<Props, State>}
 */
class Actions extends React.Component {
    constructor(props) {
        super(props);
        this.answers = [];
    }

    componentDidMount() {
        this.answers = [...this.props.answers];
    }

    componentDidUpdate() {
        this.answers = [...this.props.answers];
    }

    startStopwatchLap() {
        this.props.captureTimestamp(Date.now());
    }

    getStopwatchLapTimeMillis() {
        return Date.now() - this.props.currentAnswerStartTimestampMillis;
    }

    addAnswer(answer) {
        const timeMillis = this.getStopwatchLapTimeMillis();
        const answerObject = this.props.taskUIContext.createTaskOutput({
            globalState: this.props.globalState,
            timeMillis: timeMillis,
            answer: answer
        });

        this.answers.push(answerObject);
        console.log("added answer", answerObject);
        this.props.setAnswers(this.answers);
    }

    handleAnswer(answer) {
        if (this.answers.length < this.props.questionsAmount) {
            this.addAnswer(answer);
        }

        if (this.answers.length >= this.props.questionsAmount) {
            const taskResult = this.props.taskUIContext.createResultsSubmitObject(
                {
                    globalState: this.props.globalState,
                    answers: this.answers
                }
            );

            this.props.setTaskResult(taskResult);
            this.props.setNextGuiType(WIDGETS.SUBMIT);
        } else {
            this.startStopwatchLap();
            this.props.questionNext();
        }
    }

    handleBack() {
        const removedAnswer = this.answers.pop();
        console.log("removed answer", removedAnswer);
        this.props.setAnswers(this.answers);
        this.props.questionPrevious();
        this.startStopwatchLap();
    }

    isReady() {
        if (this.props.task === null) {
            return false;
        }

        if (
            this.props.taskUIContext.hasImagesToPreload(
                this.props.task.task_inputs
            )
        ) {
            return this.props.allImagesLoaded;
        } else {
            return this.props.guiSettings !== null;
        }
    }

    shouldUseYesNoMaybeDefaultButtonConfigs() {
        return (
            this.props.guiSettings.button_configs === undefined ||
            this.props.guiSettings.button_configs === null
        );
    }

    createActionButtons() {
        const buttonConfigs = this.shouldUseYesNoMaybeDefaultButtonConfigs()
            ? yesNoMaybeButtonConfigs
            : this.props.guiSettings.button_configs;
        const buttons = [];
        const answerTypeCallbackMap = {
            answer: answerKey => this.handleAnswer(answerKey),
            back: () => this.handleBack()
        };
        buttonConfigs.forEach((buttonConf, idx) => {
            buttons.push(
                actionButtonFactory(buttonConf, answerTypeCallbackMap, idx)
            );
        });
        return <div className="actionButtonContainer">{buttons}</div>;
    }

    render() {
        if (this.isReady()) {
            return <>{this.createActionButtons()}</>;
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = state => ({
    questionsAmount: getQuestionsAmount(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    currentAnswerStartTimestampMillis: getTimestamp(state),
    answers: getAnswers(state),
    allImagesLoaded: allImagesLoaded(state),
    task: getTask(state),
    guiSettings: getGuiSettings(state),
    globalState: state.global
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            questionNext: questionNext,
            questionPrevious: questionPrevious,
            setAnswers: setAllAnswersForQuestion,
            captureTimestamp: captureTimestamp,
            setTaskResult: setTaskResult,
            setNextGuiType: setNextGuiType
        },
        dispatch
    );

Actions.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

Actions = connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
})(Actions);
export default Actions;
