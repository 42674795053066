import React from "react";
import ReactDOM from "react-dom";

/**
 * upgraded to modern React from source at: https://stackoverflow.com/a/24815891
 *
 * @augments {React.Component<Props, State>}
 */
class DomNode extends React.Component {
    componentDidMount() {
        this.updateNode(this.props.node);
    }

    componentDidUpdate() {
        this.updateNode(this.props.node);
    }

    componentWillUnmount() {
        this.updateNode(null);
    }

    updateNode(node) {
        const myNode = ReactDOM.findDOMNode(this);

        for (const child of myNode.children) {
            myNode.removeChild(child);
        }

        if (node) {
            myNode.appendChild(node);
        }
    }

    render() {
        return <div />;
    }
}

export default DomNode;
