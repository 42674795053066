import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialTaskResult = null;

export const taskResultReducer = generateReducer(initialTaskResult, {
    [ActionTypes.SET_TASK_RESULT]: (taskResult, action) => {
        return action.taskResult;
    }
});

export const getTaskResult = state => state.global.taskResult;
