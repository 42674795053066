import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import addTaskUIProps from "../TaskUI/addTaskUIProps";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import {
    Loader,
    fetchLoad,
    loadImageObject
} from "../../lib/DataLoader/loaders";
import {
    decodeJson,
    decodeAny,
    decodeProtobufFromResponseCreator
} from "../../lib/DataLoader/decoders";
import PointcloudProjection, {
    BOX_COLORS
} from "../../components/PointcloudProjection/PointcloudProjection";

import "./PointcloudProjectionResultsView.css";
import {
    getFormattedTodayDate,
    triggerJsonObjectDownload
} from "../../lib/qm_cs_lib";

const PointsTable = require("../../lib/proto/points_table_pb");

/**
 * @param {import("../TaskUI/addTaskUIProps").ConnectedTaskUIViewProps} props
 */
let PointcloudProjectionResultsView = props => {
    const [results, setResults] = useState(null);
    const [selectedReportKey, setSelectedReportKey] = useState("");
    const [pageBegin, setPageBegin] = useState(0);
    const [currentResultPage, setCurrentResultPage] = useState([]);
    const [ready, setReady] = useState(false);
    const [selectedSamples, setSelectedSamples] = useState({});
    const [selectedAggregation, setSelectedAggregation] = useState({});
    const [sideView, setSideView] = useState(false);

    const [showBoxplotPara, setShowBoxplotPara] = useState({
        center_l: false,
        center_w: false,
        width_l: false,
        width_r: false,
        length_f: false,
        length_b: false,
        height_0: false,
        height_1: false
    });

    const checkboxNaming = {
        center_l: "Centerpoint Left",
        center_w: "Centerpoint Right",
        width_l: "Width Left",
        width_r: "Width Right",
        length_f: "Length Front",
        length_b: "Length Back",
        height_0: "Height Bottom",
        height_1: "height Top"
    };

    const pageLength = 5;

    const createBoxesFromAggregatedResults = sample => {
        const boxes = [];
        const stats = [];
        const statsDev = [];
        const paramNames = [
            "center_l",
            "center_w",
            "height_0",
            "height_1",
            "length",
            "width",
            "orientation_rad"
        ];

        // first box is the aggregated result
        const outputs = [sample.aggregated_task_output];
        const statOutput = sample.statistics.statistics;
        const statDeviation = sample.statistics.deviation_statistics;
        for (let i = 0; i < sample.task_outputs.length; i++) {
            //other boxes are raw input from annotators
            outputs.push(sample.task_outputs[i].task_output);
        }

        for (let i = 0; i < outputs.length; i++) {
            if (boxes[i] === undefined) {
                boxes[i] = {};
            }
            if (stats[i] === undefined) {
                stats[i] = {};
                statsDev[i] = {};
            }

            for (const paramName of paramNames) {
                boxes[i][paramName] = outputs[i][paramName];
            }

            Object.keys(showBoxplotPara).forEach((key, value) => {
                stats[i][key] = {};

                if (key !== "orientation_rad") {
                    stats[i][key]["quantiles"] = statOutput[key]["quantiles"];

                    stats[i][key]["mean"] = statOutput[key]["mean"];
                    stats[i][key]["std"] = statOutput[key]["std"];
                    stats[i][key]["quantiles-range"] =
                        statOutput[key]["quantiles-range"];
                }
            });

            Object.keys(showBoxplotPara).forEach(key => {
                statsDev[i][key] = {};

                if (key !== "orientation_rad") {
                    statsDev[i][key]["quantiles"] =
                        statDeviation[key]["quantiles"];

                    statsDev[i][key]["mean"] = statDeviation[key]["mean"];
                    statsDev[i][key]["std"] = statDeviation[key]["std"];
                }
            });
        }

        return { boxes: boxes, stats: stats, statsDev: statsDev };
    };

    const createProjection = (randomKey, sample, index) => {
        const pcData = props.resourceCache[sample.task_input.pb_data_url];
        const img = props.resourceCache[sample.task_input.image_url];
        let { boxes, stats, statsDev } = createBoxesFromAggregatedResults(
            sample
        );
        const users = [];
        for (let i = 0; i < sample.task_outputs.length; ++i) {
            const style = {
                color: BOX_COLORS[(i + 1) % BOX_COLORS.length]
            };
            const userKkey = "user_" + (pageBegin + index) + "_" + i;
            users.push(
                <p style={style} key={userKkey}>
                    {sample.task_outputs[i].user.vendor_user_id}
                </p>
            );
        }
        const stat = sample.sorting.sort_key_value;

        if (isAggSelected(index)) {
            boxes = [boxes[0]];
        }

        return (
            <>
                <div className="pc_wrapper" key={randomKey}>
                    <div className="side_view">
                        <p>Index: {pageBegin + index}</p>
                        <p>Annotations: {boxes.length - 1}</p>
                        {users}
                        <p>Stats: {stat}</p>

                        <p>
                            <label htmlFor={img.src + "agg"}>Aggregation</label>
                            <input
                                id={img.src + "agg"}
                                className="pc_results_select_sample_checkbox"
                                type="checkbox"
                                checked={isAggSelected(index)}
                                onChange={event =>
                                    handleAggChange(event, index)
                                }
                            />
                        </p>
                        <p>
                            <label htmlFor={img.src}>Select as GT?</label>
                            <input
                                id={img.src}
                                className="pc_results_select_sample_checkbox"
                                type="checkbox"
                                checked={isSampleSelected(img.src)}
                                onChange={event =>
                                    handleCheckboxChange(event, img.src)
                                }
                            />
                        </p>
                    </div>
                    <PointcloudProjection
                        pcData={pcData}
                        currentImageObject={img}
                        boxes={boxes}
                        bevClickEnabled={false}
                        objVis="box"
                        showBox={{ rgbView: true, bevView: true }}
                        showBev={true}
                        bevZoomSettings={{ initial: 180, min: 10, max: 800 }}
                        bevZoomEnabled
                        statistics={stats}
                        showBoxplotPara={showBoxplotPara}
                        pointcloudView={sideView ? "Front" : "Bev"}
                    />
                </div>
                <div className="column_container">
                    {Object.keys(showBoxplotPara).map(name => {
                        return (
                            <>
                                <div className="checkbox_divider">
                                    <label htmlFor={name + index}>
                                        {checkboxNaming[name]}{" "}
                                    </label>
                                    <input
                                        id={name + index}
                                        type="checkbox"
                                        className="pc_results_select_sample_checkbox"
                                        checked={showBoxplotPara[name]}
                                        onChange={() =>
                                            setShowBoxplotPara({
                                                ...showBoxplotPara,
                                                [name]: !showBoxplotPara[name]
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    {Object.keys(
                                        statsDev[0][name].quantiles
                                    ).map(q => {
                                        return (
                                            <p>
                                                <strong>{q}</strong>:{" "}
                                                {statsDev[0][name].quantiles[
                                                    q
                                                ].toFixed(2)}
                                            </p>
                                        );
                                    })}

                                    <p>
                                        <strong>IQR 0.75 - 0.25</strong>:{" "}
                                        {Number(
                                            stats[0][name]["quantiles-range"][
                                                "|q0.75-q0.25|"
                                            ]
                                        ).toFixed(2)}
                                    </p>
                                    <p>
                                        <strong>IQR 0.10 - 0.90</strong>:{" "}
                                        {Number(
                                            stats[0][name]["quantiles-range"][
                                                "|q0.90-q0.10|"
                                            ]
                                        ).toFixed(2)}
                                    </p>
                                    <p>
                                        <strong>Mean</strong>:{" "}
                                        {Number(statsDev[0][name].mean).toFixed(
                                            2
                                        )}
                                    </p>
                                    <p>
                                        <strong>Std</strong>:{" "}
                                        {Number(stats[0][name].std).toFixed(2)}
                                    </p>
                                </div>
                            </>
                        );
                    })}
                </div>{" "}
            </>
        );
    };

    // create projections
    const getPCProjectionList = () => {
        const projections = [];
        for (let i = 0; i < currentResultPage.length; i++) {
            const sample = currentResultPage[i];
            // create random integer key so that react will not re-use the old projections
            const tmp = createProjection(
                Math.floor(Math.random() * 1000000),
                sample,
                i
            );
            projections.push(tmp);
        }
        return projections;
    };

    // add or remove a sample by its image_url from the selection
    const handleCheckboxChange = (event, image_url) => {
        const newSelectedSamples = { ...selectedSamples };
        if (event.target.checked) {
            // remember as selected
            const sample = getAggregatedTaskOutputsByImageUrl(image_url);
            newSelectedSamples[image_url] = sample;
            setSelectedSamples(newSelectedSamples);
        } else {
            // forget as selected
            delete newSelectedSamples[image_url];
            setSelectedSamples(newSelectedSamples);
        }
    };

    // add or remove a sample by its image_url from the selection
    const handleAggChange = (event, index) => {
        const newSelectedAggregation = { ...selectedAggregation };
        if (event.target.checked) {
            // remember as selected
            newSelectedAggregation[index] = true;
            setSelectedAggregation(newSelectedAggregation);
        } else {
            // forget as selected
            delete newSelectedAggregation[index];
            setSelectedAggregation(newSelectedAggregation);
        }
    };

    // returns the first sample that has that exact image_url
    const getAggregatedTaskOutputsByImageUrl = image_url => {
        return results[selectedReportKey].find(
            sample => sample.task_input.image_url === image_url
        );
    };

    const pageNext = () => {
        setPageBegin(pageBegin + pageLength);
        setReady(false);
    };

    const pagePrevious = () => {
        setPageBegin(pageBegin - pageLength);
        setReady(false);
    };

    const onReportKeyChanged = event => {
        setSelectedReportKey(event.target.value);
        setPageBegin(0);
        setReady(false);
    };

    const reportKeySelect = () => {
        const reportKeys = Object.keys(results);
        const reportOptions = [];
        reportKeys.forEach((reportKey, idx) => {
            reportOptions.push(
                <option key={idx} value={reportKey}>
                    {reportKey}
                </option>
            );
        });
        return (
            <select
                className="pc_results_report_key_select"
                onChange={onReportKeyChanged}
                defaultValue={selectedReportKey}
            >
                {reportOptions}
            </select>
        );
    };

    const isSampleSelected = image_url => {
        return image_url in selectedSamples;
    };

    const isAggSelected = index => {
        return index in selectedAggregation;
    };

    const downloadSelectedSamplesJsonFile = () => {
        const today = getFormattedTodayDate();
        const filename = "selectedSamples_" + today;
        triggerJsonObjectDownload(selectedSamples, filename, 4);
    };

    // load results file
    useEffect(() => {
        const results_url = props.taskUIContext.getSpecificVar("results_url");
        const loader = new Loader(results_url, fetchLoad, decodeJson);
        loader.loadAndDecode().then(json => {
            setResults(json);
            const reportKeys = Object.keys(json);
            if (reportKeys.length > 0) {
                // show first report by default
                setSelectedReportKey(reportKeys[0]);
            }
            console.log("results json loaded", results_url);
        });
        // eslint-disable-next-line
    }, []);

    // select loadable resources and load them
    useEffect(() => {
        if (results === null || selectedReportKey === "") {
            return;
        }
        console.log("loading current page of results", pageBegin);
        console.log("using report:", selectedReportKey);
        const protobufDecoder = decodeProtobufFromResponseCreator(
            PointsTable.Table
        );
        let imgLoaded = 0;
        let pbLoaded = 0;
        const report = results[selectedReportKey];
        const page = [];
        for (
            let i = pageBegin;
            i < pageBegin + pageLength && i < report.length;
            i++
        ) {
            const sample = report[i];
            const img_url = sample.task_input.image_url;
            const pb_url = sample.task_input.pb_data_url;
            page.push(sample);
            const imgLoader = new Loader(img_url, loadImageObject, decodeAny);
            const protobufLoader = new Loader(
                pb_url,
                fetchLoad,
                protobufDecoder
            );
            let realPageSize = pageLength;
            if (pageBegin + pageLength > report.length) {
                realPageSize = report.length - pageBegin;
            }
            // eslint-disable-next-line
            imgLoader.loadAndDecode().then(img => {
                props.addResourceToCache(img_url, img);
                imgLoaded++;
                if (imgLoaded === realPageSize && pbLoaded === realPageSize) {
                    setReady(true);
                    setCurrentResultPage([...page]);
                }
            });
            // eslint-disable-next-line
            protobufLoader.loadAndDecode().then(pcData => {
                props.addResourceToCache(pb_url, pcData);
                pbLoaded++;
                if (imgLoaded === realPageSize && pbLoaded === realPageSize) {
                    setReady(true);
                    setCurrentResultPage([...page]);
                }
            });
        }
        // eslint-disable-next-line
    }, [results, pageBegin, selectedReportKey]);

    if (!ready) {
        return <h1>loading...</h1>;
    }
    return (
        <div className="pc_results_container">
            <h1 className="pc_results_title">
                {selectedReportKey}: {pageBegin}-
                {pageBegin + currentResultPage.length}/
                {results[selectedReportKey].length}
            </h1>
            <div className="pc_results_pagination">
                <button disabled={pageBegin === 0} onClick={pagePrevious}>
                    previous
                </button>
                <button
                    disabled={
                        pageBegin + pageLength >=
                        results[selectedReportKey].length
                    }
                    onClick={pageNext}
                >
                    next
                </button>
                {reportKeySelect()}
                <button
                    className="pc_results_download_selected_samples_button"
                    onClick={() => downloadSelectedSamplesJsonFile()}
                    disabled={Object.keys(selectedSamples).length === 0}
                >
                    Download selected samples (
                    {Object.keys(selectedSamples).length})
                </button>
            </div>
            <p>
                <label htmlFor={"sideView"}>SideView</label>
                <input
                    id={"sideView"}
                    className="pc_results_select_sample_checkbox"
                    type="checkbox"
                    checked={sideView}
                    onChange={() => setSideView(!sideView)}
                />
            </p>
            {getPCProjectionList()}
        </div>
    );
};

PointcloudProjectionResultsView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

PointcloudProjectionResultsView = addTaskUIProps(
    PointcloudProjectionResultsView
);

export default PointcloudProjectionResultsView;
