import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { submitTasksWithTaskUIContext } from "../../modules/TaskUI/actions/asyncActions";
import { getTaskResult } from "../../modules/TaskUI/reducers/taskResult";
import { getMTurkInfos } from "../../modules/TaskUI/reducers/mturkInfos";
import { setNextGuiType } from "../../modules/TaskUI/actions";

/**
 * @typedef SubmitFunctionalityProps
 * @property {Function} finalSubmit
 */

const SubmitWrapper = props => {
    const { render: InnerComponent, innerProps, ...outerProps } = props;
    /**
     * @type {import("../../lib/TaskUIStrategy/taskUIContext").default}
     */
    const taskUIContext = innerProps.taskUIContext;
    if (taskUIContext === null || taskUIContext === undefined) {
        console.error("missing prop taskUIContext");
    }

    /**
     * @returns {Promise}
     */
    const finalSubmit = () => {
        return new Promise((resolve, reject) => {
            if (props.mturkInfos.runsOnMTurk && props.mturkInfos.isPreview) {
                console.log("Can't submit in MTurk Preview mode");
                reject();
                return;
            }
            // let the caller handle the submission result
            resolve(
                props.submitTasks(
                    taskUIContext,
                    props.taskResult,
                    props.mturkInfos
                )
            );
        });
    };
    return (
        <InnerComponent
            {...outerProps}
            {...innerProps}
            finalSubmit={finalSubmit}
        />
    );
};

const mapStateToProps = state => ({
    taskResult: getTaskResult(state),
    mturkInfos: getMTurkInfos(state)
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            submitTasks: submitTasksWithTaskUIContext,
            setNextGuiType: setNextGuiType
        },
        dispatch
    );
const ConnectedSubmitViewWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitWrapper);

// adds the function finalSubmit as prop to the WrappedComponent
// but expects taskUIContext to be present as prop!
const addSubmitFunctionality = WrappedComponent => {
    return props => (
        <ConnectedSubmitViewWrapper
            render={WrappedComponent}
            innerProps={{ ...props }}
        />
    );
};

export default addSubmitFunctionality;
