// eslint-disable-next-line
import { JSONSerializable } from "./JSONSerializable";

/**
 * @typedef {Object} ApiClientOptions
 * @property {string} endpoint - a url with protocol (http:// or https:// )
 */

export default class ApiClient {
    /**
     * @param {ApiClientOptions} options
     * @param {Object} authentication - TODO
     */
    constructor(options) {
        this.options = { ...options };
    }

    /**
     * TODO
     */
    authenticate() {}

    /**
     *
     * @param {string} path
     * @returns {URL} url object
     */
    getUrl(path) {
        return new URL(this.options.endpoint + path);
    }

    /**
     * @param {String} path - path of the url without ending slash e.g. /abc/def
     * @param {Object=} params - property is paramName and value its value
     * @returns {String} url string
     */
    getUrlWithParams(path, params = {}) {
        const url = this.getUrl(path);
        Object.entries(params).forEach(([key, value]) => {
            url.searchParams.set(key, value);
        });
        return url;
    }

    /**
     * @callback apiCallCallback
     * @param {Object} err - error
     * @param {Object} data - json response object
     */

    /**
     *
     * @param {String} url
     * @param {apiCallCallback} callback
     * @param {Object=} fechOptions - additional options for the fetch call
     */
    makeApiCall(url, callback, fetchOptions = {}) {
        return fetch(url, fetchOptions)
            .then(res => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                throw new Error("HTTP status " + res.status);
            })
            .then(json => callback(null, json))
            .catch(err => {
                callback(err, null);
            });
    }
}
