import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { DynamicModuleLoader } from "redux-dynamic-modules";
import { createStore } from "redux-dynamic-modules-core";
import { getThunkExtension } from "redux-dynamic-modules-thunk";

import { rootReducerCombiner } from "./modules/rootReducer";
import { getTaskUIModule } from "./modules/TaskUI/taskUIModule";
import TaskUI from "./containers/TaskUI/TaskUI";
import { getQueryParams, stringTruthiness } from "./lib/qm_cs_lib";
import { createMockQueryParams, createMockApiClient } from "./mock_data";
import taskUIContextFactory from "./lib/TaskUIStrategy/taskUIContextFactory";

import "./index.css";
import "./font.css";

const USE_MOCK_DATA = stringTruthiness(process.env.REACT_APP_USE_MOCK_DATA);

let params = null;
let apiClient = null;

if (USE_MOCK_DATA) {
    const guiTypeUnderTest = process.env.REACT_APP_MOCK_GUI_TYPE;
    const useGoliatBackend = stringTruthiness(
        process.env.REACT_APP_USE_GOLIAT_MOCKAPICLIENT
    );
    // guiType only necessary when using a mock for MTurk params
    // because then it will be part of the query params
    params = createMockQueryParams(useGoliatBackend, guiTypeUnderTest);
    // apiClient mock will return different mock tasks depending on desired guiType
    apiClient = createMockApiClient(guiTypeUnderTest);
} else {
    params = getQueryParams();
    // apiClient will be set by taskUIContext
}

const taskUIContext = taskUIContextFactory(params);
taskUIContext.init(apiClient);

const store = createStore({
    extensions: [getThunkExtension()],
    advancedCombineReducers: rootReducerCombiner
});

const taskUI = (
    <Provider store={store}>
        <DynamicModuleLoader modules={[getTaskUIModule()]}>
            <TaskUI taskUIContext={taskUIContext} />
        </DynamicModuleLoader>
    </Provider>
);

ReactDOM.render(taskUI, document.getElementById("root"));
