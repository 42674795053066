/**
 * Sample usage:
 *
 *   const imgContainer = document.getElementById("imgContainer");
 *   const img = new Image();
 *   img.crossOrigin = "Anonymous";
 *   img.onload = () => {
 *       imgContainer.appendChild(img);
 *       const pixels = convertImageToPixel3DArray(img);
 *       for (let y = 0; y < pixels.length; y++) {
 *           for (let x = 0; x < pixels[y].length; x++) {
 *               pixels[y][x][0] = 0; // red
 *               pixels[y][x][1] -= 30; // green
 *               pixels[y][x][2] = 0; // blue
 *           }
 *       }
 *       const newCanvas = drawPixelsOnCanvas(pixels);
 *       imgContainer.appendChild(newCanvas);
 *   }
 *   img.src = "https://placekitten.com/600/400";
 */

import { memoize } from "./qm_cs_lib";

/**
 *
 * @param {Image} img
 * @returns {Uint8ClampedArray}
 */
export const getImageData = img => {
    return getImageDataObject().data;
};

/**
 *
 * @param {Image} img
 * @returns {ImageData}
 */
export const getImageDataObject = img => {
    const width = img.width;
    const height = img.height;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.width = width;
    ctx.height = height;
    ctx.drawImage(img, 0, 0);
    return ctx.getImageData(0, 0, width, height);
};

/**
 * @callback getCachedImageDataObject
 * @param {Image} img
 * @returns {ImageData} getCopy
 */

/**
 * Returns a function that caches the image objects ImageData with
 * their src attribute as key.
 *
 * @default makeCopy=false
 * @param {Boolean=} makeCopy whether to create a copy of the ImageData object from the cache.
 *                            default behaviour false: don't create copy of the ImageData object in cache
 *                            and return the same object every time
 * @returns {getCachedImageDataObject}
 */
export const getCachedImageDataObjectCreator = (makeCopy = false) => {
    const f = memoize(getImageDataObject, img => img.src);
    if (makeCopy) {
        return img => {
            const imgData = f(img);
            return new ImageData(
                new Uint8ClampedArray(imgData.data), // constructor handles copying
                imgData.width
            );
        };
    }
    return f;
};

/**
 *
 * @param {Image} img
 * @returns {Number[][][]} dimensions: y, x, rgb
 */
export const convertImageToPixel3DArray = img => {
    const width = img.width;
    const height = img.height;
    const ctxImgData = getImageData(img);

    const yxrgb = new Array(height);
    const getRedIdxFor = (x, y) => y * (width * 4) + x * 4;
    for (let y = 0; y < height; y++) {
        yxrgb[y] = new Array(width);
        for (let x = 0; x < width; x++) {
            yxrgb[y][x] = new Array(3);
            const redIdx = getRedIdxFor(x, y);
            yxrgb[y][x][0] = ctxImgData[redIdx];
            yxrgb[y][x][1] = ctxImgData[redIdx + 1];
            yxrgb[y][x][2] = ctxImgData[redIdx + 2];
        }
    }
    return yxrgb;
};

/**
 *
 * @param {Number[][][]} pixels dimensions: y, x, rgb
 * @returns {HTMLCanvasElement}
 */
export const drawPixelsOnCanvas = pixels => {
    const height = pixels.length;
    const width = pixels[0].length;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    ctx.width = width;
    ctx.height = height;

    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            const r = pixels[y][x][0];
            const g = pixels[y][x][1];
            const b = pixels[y][x][2];
            ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
            ctx.fillRect(x, y, 1, 1);
        }
    }

    return canvas;
};

/**
 * Converts a RGB [0, 255] triplet to a grayscale [0, 1] scalar.
 * @param {Number} r [0, 255]
 * @param {Number} g [0, 255]
 * @param {Number} b [0, 255]
 */
export const rgb2gray = (r, g, b) => {
  return 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);
}

/**
 * Gets a pixel's grayscale color from within a context's imageData.
 *
 * @param {Uint8ClampedArray} pixel rgb values as flat array
 * @param {Number} x
 * @param {Number} y
 */
export const getGrayscaleColor = (imageData, x, y) => {
  const ix = (x + y * imageData.width) * 4;
  return rgb2gray(
    imageData.data[ix],
    imageData.data[ix + 1],
    imageData.data[ix + 2]
  );
}

/**
 * Gets the neighboring pixels of a single pixel considering a 4-neighborhood.
 * Returned is a list of triplets of [x, y, grayscale] values.
 */
export const getNeighbors = (imageData, x, y) => {
  return [
    [x, y - 1, getGrayscaleColor(imageData, x, y - 1)], // up
    [x + 1, y, getGrayscaleColor(imageData, x + 1, y)], // right
    [x, y + 1, getGrayscaleColor(imageData, x, y + 1)], // down
    [x - 1, y, getGrayscaleColor(imageData, x - 1, y)], // left
  ];
}
