import React, { useState } from "react";
import PropTypes from "prop-types";

import ActionButton from "../../components/TaskUI/ActionButtons/ActionButton";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import addTaskUIProps from "../TaskUI/addTaskUIProps";
import addSubmitFunctionality from "./addSubmitFunctionality";
import { WIDGETS } from "../../guiFactory";

import "./styles/SubmitView.css";

/**
 * @param {import("../TaskUI/addTaskUIProps").ConnectedTaskUIViewProps & import("./addSubmitFunctionality").SubmitFunctionalityProps} props
 */
let SubmitView = props => {
    const [actionsDisabled, setActionsDisabled] = useState(false);

    const onSubmit = e => {
        setActionsDisabled(true);
        props
            .finalSubmit()
            .then(() => {
                setActionsDisabled(false);
                props.setNextGuiType(WIDGETS.THANK_YOU);
            })
            .catch(err => console.error(err));
    };

    const onBackButton = () => {
        props.popAnswerFromStore();
        console.log("removed answer", props.answers[props.answers.length - 1]);
        props.captureTimestamp(Date.now());
        props.setShouldShowInstructions(false);
        props.setNextGuiType(props.guiType);
    };

    if (props.mturkInfos.runsOnMTurk && props.mturkInfos.isPreview) {
        return <h1>Can't submit in MTurk preview mode</h1>;
    }

    return (
        <div className="submitContainer container-qm">
            <div>
                <div className="header">
                    <p>All done?</p>
                </div>
                <div className="actions">
                    <ActionButton
                        actionKey="q"
                        onClick={onBackButton}
                        className="backButton button"
                        disabled={actionsDisabled}
                    >
                        Back
                    </ActionButton>

                    <ActionButton
                        onClick={onSubmit}
                        className="submitButton button is-primary"
                        disabled={actionsDisabled}
                    >
                        Submit
                    </ActionButton>
                </div>
            </div>
        </div>
    );
};

SubmitView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

SubmitView = addTaskUIProps(SubmitView);
SubmitView = addSubmitFunctionality(SubmitView);

export default SubmitView;
