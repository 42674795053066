import { JSONSerializable } from "../JSONSerializable";

export const ANSWER_KEYS = {
    YES: "yes",
    NO: "no",
    UNSURE: "unsure"
};

/**
 * Represents an answer built from the YesNoMaybe gui for the task api
 * @class
 * @augments {JSONSerializable}
 */
export class Answer extends JSONSerializable {
    /**
     * @param {String} imageUrl
     * @returns {Answer}
     */
    setImageUrl(imageUrl) {
        this.image_url = imageUrl;
        return this;
    }

    /**
     * @param {String} answerKey
     * @returns {Answer}
     */
    setAnswerKey(answerKey) {
        this.answer = answerKey;
        return this;
    }

    /**
     * @param {Number} timeMillis
     * @returns {Answer}
     */
    setTimeMillis(timeMillis) {
        this.time_ms = timeMillis;
        return this;
    }
}
