export const singlePageInstructions = [
    `<div style="width: 40%; margin: auto; border: 1px solid black; padding: 25px;">
        <div>
            <h1>Instructions</h1>
            <button onclick="document.dispatchEvent(new Event('closeInstructions'))">
                Close
            </button>
        </div>
        <div style="display: inline-block; margin-top: 10px;">
            <p>
                Press <b style="color: red;">No</b> when: No
            </p>
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/FF0000/FFFFFF/?text=No"
            />
        </div>
        <div style="display: inline-block; float: right;">
            <p>
                Press <b style="color: green;">Yes</b> when: Yes
            </p>
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/009900/FFFFFF/?text=Yes"
            />
        </div>
    </div>`
];

export const multiPageInstructions = [
    `<div>
        <h1>Tool Instructions</h1>
        <div style="margin: auto; padding: 25px;">
            <h3>
                This is how to use the tools of this task
            </h3>
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 1"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 2"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 3"
            />
        </div>
    </div>`,
    `<div>
        <h1>
            Examples for <b style="color: red;">No</b>
        </h1>
        <div style="margin: auto; padding: 25px;">
            <h3>
                Press <b style="color: red;">No</b> when: No
            </h3>
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/FF0000/FFFFFF/?text=Example 1"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/FF0000/FFFFFF/?text=Example 2"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/FF0000/FFFFFF/?text=Example 3"
            />
        </div>
    </div>`,
    `<div>
        <h1>
            Examples for <b style="color: green;">Yes</b>
        </h1>
        <div style="margin: auto; padding: 25px;">
            <h3>
                Press <b style="color: green;">Yes</b> when: Yes
            </h3>
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/009900/FFFFFF/?text=Example 1"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/009900/FFFFFF/?text=Example 2"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/009900/FFFFFF/?text=Example 3"
            />
        </div>
    </div>`,
    `<div>
        <h1>
            Examples for <b style="color: blue;">Unsure</b>
        </h1>
        <div style="margin: auto; padding: 25px;">
            <h3>
                Press <b style="color: blue;">Unsure</b> when: Unsure
            </h3>
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/0000FF/FFFFFF/?text=Example 1"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/0000FF/FFFFFF/?text=Example 2"
            />
            <img
                style="height: 300px; width: 270px;"
                src="https://via.placeholder.com/300/0000FF/FFFFFF/?text=Example 3"
            />
        </div>
    </div>`,
    `<div>
        <h1>Slide example with many images</h1>
        <div style="margin: auto; padding: 25px;">
            <h3>When you have lots of images, make sure they're small enough if you want to prevent scrolling</h3>
            <div style="margin-top: 20px;">
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 1"
                />
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 2"
                />
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 3"
                />
            </div>
            <div style="margin-top: 5px;">
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 4"
                />
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 5"
                />
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 6"
                />
            </div>
            <div style="margin-top: 5px;">
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 7"
                />
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 8"
                />
                <img
                    style="height: 200px; width: 200px;"
                    src="https://via.placeholder.com/300/000000/FFFFFF/?text=Example 9"
                />
            </div>
        </div>
    </div>`
];

export const yesNoMaybeButtonConfigs = [
    {
        action_key: "w",
        action_type: "answer",
        answer_key: "no",
        text: "No",
        class_name: "is-red button"
    },
    {
        action_key: "e",
        action_type: "answer",
        answer_key: "yes",
        text: "Yes",
        class_name: "is-green button"
    },
    {
        action_key: "r",
        action_type: "answer",
        answer_key: "unsure",
        text: "Unsure",
        class_name: "is-blue button"
    }
];

export const oneToNineButtonConfigs = [
    {
        action_key: "q",
        action_type: "back",
        background_color: "#777",
        text_color: "#FFF"
    }
];
for (let i = 0; i < 10; i++) {
    oneToNineButtonConfigs.push({
        action_key: i.toString(),
        action_type: "answer",
        answer_key: i.toString(),
        text: i.toString(),
        background_color: "#0099FF",
        text_color: "#FFF"
    });
}

export const placeholderImagesUrls = [
    "https://dummyimage.com/16:9x1080/FF0000/FFFFFF.png?text=Image1",
    "https://dummyimage.com/16:9x1080/009900/FFFFFF.png?text=Image2",
    "https://dummyimage.com/16:9x1080/0000FF/FFFFFF.png?text=Image3",
    "https://dummyimage.com/16:9x1080/444444/FFFFFF.png?text=Image4",
    "https://dummyimage.com/16:9x1080/000000/FFFFFF.png?text=Image5"
];

export const grappleImagesUrls = [
    "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/4e018aaf-341f-46c1-bae7-91d84bd96693.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D",
    "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/2b09f81b-5c33-4bda-aa1d-b0a18cdb4a23.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D",
    "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/f4ed54b1-8907-4168-bacd-66af482825b8.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D",
    "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/de269140-a62a-42f1-924a-3d9c5a9e8c0f.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D",
    "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/315caba7-b635-41a2-94b1-0a6c80c4bd24.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
];

export const placeHolderImagesObjects = [
    {
        image_url:
            "https://dummyimage.com/16:9x1080/FF0000/FFFFFF.png?text=Image1"
    },
    {
        image_url:
            "https://dummyimage.com/16:9x1080/009900/FFFFFF.png?text=Image2"
    },
    {
        image_url:
            "https://dummyimage.com/16:9x1080/0000FF/FFFFFF.png?text=Image3"
    },
    {
        image_url:
            "https://dummyimage.com/16:9x1080/444444/FFFFFF.png?text=Image4"
    },
    {
        image_url:
            "https://dummyimage.com/16:9x1080/000000/FFFFFF.png?text=Image5"
    }
];
export const grappleImagesObjects = [
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/4e018aaf-341f-46c1-bae7-91d84bd96693.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/2b09f81b-5c33-4bda-aa1d-b0a18cdb4a23.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/f4ed54b1-8907-4168-bacd-66af482825b8.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/de269140-a62a-42f1-924a-3d9c5a9e8c0f.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/315caba7-b635-41a2-94b1-0a6c80c4bd24.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/b91df0ef-f3e8-40fd-9996-49a2eb1937cb.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/0b7b833c-7c0d-4291-81f0-4e52c33e6d70.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/2741dc44-1eab-4a08-bbf1-ae9d8c78786d.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/00c30dbe-0b27-4fec-8c0f-9cc4b3c97eba.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    },
    {
        image_url:
            "https://autocranedevarchive.blob.core.windows.net/qm-export-2020-02-10-test/f0faaa68-b42c-43f4-963a-654f226fbda1.jpeg?se=2020-05-10T14%3A05%3A13Z&sp=r&sv=2018-03-28&sr=c&sig=krFQ7EwNhSSOg42I4LggEuWcGfM5NjqNSjP8UFxtK/8%3D"
    }
];

export const covidImagesObjects = [
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/0719ba5ffac530c6802ebf812c3e2a691f01a71d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/12dab82fd2bc2f9ab6a1dbf80ce2595030734d72.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/1e7083a0b6a1c13da3a60c3a92c98f343f66dad7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/2cf2d0bb709bced72c43ca47b41561513fde386c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/3f59694f8ea2bba1bf1deb05a1fbc077e030c41b.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/41d91855d99120eab21ae47e87950181cd578a05.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/488957f5c45bd4031ef489b7683b77832069467e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/4dc55d2d9eb588c3e1e81ca1abbf1310b9981fd6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/52de476a0c01c45a56108e6b111361f4306d09eb.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/57b70f09df86f59b3663e42c539c764be4a4a502.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/65306044eb32b3be53a26255def402bf19066dd3.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6c1a087308a6c1ae46795b58fbdafc8ebf9ad0c2.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/6dca53602f34a131419dd3689bed9302f38e18e1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/742ba608218437dad2daf541834b473b34081069.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/75ac74741363896810b4b7c2275035693f63727e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/92df17dd4f67b2ee19c592556dbce714d760ea9d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/9b14a6d2bbc596cfb954b5cef364ffb6ee5a9015.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/9d7a6933b9eff02ba09f5eebc93042c3fe49d078.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/a3bb4c2c9a1b53f47f7c4c631042fc65bf2d1bb0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/a52a7906b6b05f19e2afa2042cc4717ba091f973.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/a7f3d80bc7949f86a53240a7753ff06fd49cb842.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/b43dd83b4545c5434d6ec8ea94a950acc4573646.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/b4dc53fb22350ead050dace9f4117128fd5d0568.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/d10bb16cfc3ebf656c22b7f0f4c6ad05466b271d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/d111adc405fd97649b4c2c859c5f92e4c2c6d8a1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/e64b9b38f63cd21bb14104f635cc7badacaf7abd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/f6aa7d1f867ea4796e93cd3441e7dc7fcaecea5e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/f931115caafad6254ba3751308b1ad1defcd2577.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/f9e12beaffb208673a350409265886b557eb8ffc.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/left/feeec093978568463b761cd5fba53c501ab2d59d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/0719ba5ffac530c6802ebf812c3e2a691f01a71d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/12dab82fd2bc2f9ab6a1dbf80ce2595030734d72.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/1e7083a0b6a1c13da3a60c3a92c98f343f66dad7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/2cf2d0bb709bced72c43ca47b41561513fde386c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/3f59694f8ea2bba1bf1deb05a1fbc077e030c41b.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/41d91855d99120eab21ae47e87950181cd578a05.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/488957f5c45bd4031ef489b7683b77832069467e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/4dc55d2d9eb588c3e1e81ca1abbf1310b9981fd6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/52de476a0c01c45a56108e6b111361f4306d09eb.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/57b70f09df86f59b3663e42c539c764be4a4a502.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/65306044eb32b3be53a26255def402bf19066dd3.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/6c1a087308a6c1ae46795b58fbdafc8ebf9ad0c2.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/6dca53602f34a131419dd3689bed9302f38e18e1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/742ba608218437dad2daf541834b473b34081069.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/75ac74741363896810b4b7c2275035693f63727e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/92df17dd4f67b2ee19c592556dbce714d760ea9d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/9b14a6d2bbc596cfb954b5cef364ffb6ee5a9015.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/9d7a6933b9eff02ba09f5eebc93042c3fe49d078.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/a3bb4c2c9a1b53f47f7c4c631042fc65bf2d1bb0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/a52a7906b6b05f19e2afa2042cc4717ba091f973.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/a7f3d80bc7949f86a53240a7753ff06fd49cb842.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/b43dd83b4545c5434d6ec8ea94a950acc4573646.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/b4dc53fb22350ead050dace9f4117128fd5d0568.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/d10bb16cfc3ebf656c22b7f0f4c6ad05466b271d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/d111adc405fd97649b4c2c859c5f92e4c2c6d8a1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/e64b9b38f63cd21bb14104f635cc7badacaf7abd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/f6aa7d1f867ea4796e93cd3441e7dc7fcaecea5e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/f931115caafad6254ba3751308b1ad1defcd2577.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/f9e12beaffb208673a350409265886b557eb8ffc.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/neg/right/feeec093978568463b761cd5fba53c501ab2d59d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/01b4fe98b2e8c07bce04b9c55e19d0c53d544f4d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/0f8e2558598414c8400046badd8f5f517308a404.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/140593f51a50c9338ed5a5626abee804f3c5f198.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/26f3cb5ac8c85e8483959185a26dcaa6887454f0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/2b5f39e25757915f37eac34c614132524fd13a76.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/2dd4b6673f55bfcc2a627f9b081ff0baef4391ab.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/30fb48e0e72a3aff4156afc980422be150d9c410.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/445e9d2d27c79285321be41d4c0f0b64e731a110.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/4635b0933c7c8ac9db8933d8b2e9c24953966d38.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/5294827da50e7bfa4023340790d203ffbb2181d6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/52a6e36e2f40ea0a30ba1e7b7fce01a99ecc22d1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/600a6cc90acb34f3f9d5712244b1a9082c0965c8.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/76930afb1b8e3b008fdbb94856565bf9327b63ad.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7c22533a83250b1261fbec2ccde95b3217c028fa.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7c6fa6b1bf5b2661a4c2c97b99f6adb8bfcf3a44.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7db2a18c1247cc544306e932db5fa0336cb24de6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/7f15545fe9ad5cb2476a18949624d14a3f5b5725.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/8714b51c13c423e0311a4a7b37f0001b3e2109e7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/882fa57097d26c971ccafaa98c68ad2e753e5bae.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/8ac26fc35515be5c4aeb2fc9d7534b6d6f9a4292.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/8fbe9bde3c2430cc3e41364947f921237b78c107.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/94f037815d410d83e0fffbf75114b02478befc2e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/98e5eab59f002a818998af327b2ec7cbabff9520.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/a3f1cc05d54baf211bcd2d7c48d1718dc2fa28ce.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/a779d518b7c5d99b961d2555b1782bd7eb3bc2f1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/bacf9823dff12c3a90d148e3bd38375f8050fd3c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/d09b9f9d645b501305878a497a41ccf2defd5b66.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/d8d7ca941887b88db47ba090ed85164f8f1a3795.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/dcf8110f4facca21b0ffc1b5938e41097fcd77fd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/left/ffd564c9125a0ddfe443cab0e0aaee7920e4a0b7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/01b4fe98b2e8c07bce04b9c55e19d0c53d544f4d.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/0f8e2558598414c8400046badd8f5f517308a404.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/140593f51a50c9338ed5a5626abee804f3c5f198.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/26f3cb5ac8c85e8483959185a26dcaa6887454f0.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/2b5f39e25757915f37eac34c614132524fd13a76.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/2dd4b6673f55bfcc2a627f9b081ff0baef4391ab.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/30fb48e0e72a3aff4156afc980422be150d9c410.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/445e9d2d27c79285321be41d4c0f0b64e731a110.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/4635b0933c7c8ac9db8933d8b2e9c24953966d38.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/5294827da50e7bfa4023340790d203ffbb2181d6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/52a6e36e2f40ea0a30ba1e7b7fce01a99ecc22d1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/600a6cc90acb34f3f9d5712244b1a9082c0965c8.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/76930afb1b8e3b008fdbb94856565bf9327b63ad.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7c22533a83250b1261fbec2ccde95b3217c028fa.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7c6fa6b1bf5b2661a4c2c97b99f6adb8bfcf3a44.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7db2a18c1247cc544306e932db5fa0336cb24de6.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/7f15545fe9ad5cb2476a18949624d14a3f5b5725.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/8714b51c13c423e0311a4a7b37f0001b3e2109e7.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/882fa57097d26c971ccafaa98c68ad2e753e5bae.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/8ac26fc35515be5c4aeb2fc9d7534b6d6f9a4292.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/8fbe9bde3c2430cc3e41364947f921237b78c107.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/94f037815d410d83e0fffbf75114b02478befc2e.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/98e5eab59f002a818998af327b2ec7cbabff9520.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/a3f1cc05d54baf211bcd2d7c48d1718dc2fa28ce.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/a779d518b7c5d99b961d2555b1782bd7eb3bc2f1.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/bacf9823dff12c3a90d148e3bd38375f8050fd3c.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/d09b9f9d645b501305878a497a41ccf2defd5b66.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/d8d7ca941887b88db47ba090ed85164f8f1a3795.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/dcf8110f4facca21b0ffc1b5938e41097fcd77fd.png"
    },
    {
        image_url:
            "https://covidthon.blob.core.windows.net/dev/pos/right/ffd564c9125a0ddfe443cab0e0aaee7920e4a0b7.png"
    }
]
    .sort((a, b) => 0.5 - Math.random())
    .slice(0, 5);

export const grappleInstructions = [
    `<div>
        <img src="/mock-images/Instruction-1.svg" />

        <p>Press <strong class="text-is-red">No</strong> when: <br> grapple has no load</p>
    </div>`,
    `<div>
        <img src="/mock-images/Instruction-2.svg" />
        
        <p>Press <strong class="text-is-green">Yes</strong> when: <br> grapple has load</p>
    </div>`
];

export const mockGetTaskResult = {
    ok: true,
    task: {
        id: "666",
        task_inputs: {
            images_urls: grappleImagesUrls
        }
    },
    gui_settings: {
        question: "Does the grapple hold logs?",
        instructions: grappleInstructions,
        button_configs: yesNoMaybeButtonConfigs
    }
};

export const mockSubmitTaskResult = {
    ok: true
};

export const mockSubmitMTurkAssignmentResult = {
    ok: true
};

export const mockGetTaskGroupResult = {
    group_ack: {
        ack: [
            {
                ack_key: "AAAAA",
                input_id: "AAAAA"
            },
            {
                ack_key: "BBBBB",
                input_id: "BBBBB"
            },
            {
                ack_key: "CCCCC",
                input_id: "CCCCC"
            },
            {
                ack_key: "CCCCC",
                input_id: "CCCCC"
            },
            {
                ack_key: "DDDDD",
                input_id: "DDDDD"
            },
            {
                ack_key: "EEEEE",
                input_id: "EEEEE"
            },
            {
                ack_key: "FFFFF",
                input_id: "FFFFF"
            },
            {
                ack_key: "GGGGG",
                input_id: "GGGGG"
            },
            {
                ack_key: "HHHHH",
                input_id: "HHHHH"
            },
            {
                ack_key: "IIIII",
                input_id: "IIIII"
            },
            {
                ack_key: "JJJJJ",
                input_id: "JJJJJ"
            },
            {
                ack_key: "KKKKK",
                input_id: "KKKKK"
            }
        ],
        project_node_id: "ABCDEFGHIJK",
        user: "USER_ID_FROM_GOLIAT_0000"
    },
    gui_settings: {
        question: { text: "Does the grapple hold logs?" },
        buttons: yesNoMaybeButtonConfigs,
        instructions: grappleInstructions
    },
    gui_type: "discrete_answers_gui",
    task_inputs: grappleImagesObjects
};

export const task_inputs_TemplateUI = [
    {
        squares: [
            { text: "Salut" },
            { text: "Hello" },
            { text: "Hallo" },
            { text: "Hola", isBlue: true }
        ]
    },
    {
        squares: [
            { text: "Salut", isBlue: true },
            { text: "Hello" },
            { text: "Hallo" },
            { text: "Hola" }
        ]
    }
];

export const mockGetTaskResult_TemplateUI = {
    ok: true,
    task: {
        id: "666",
        task_inputs: task_inputs_TemplateUI
    },
    gui_settings: {
        question: "Select the blue square",
        instructions: multiPageInstructions
    }
};

export const mockGetTaskGroupResult_TemplateUI = {
    group_ack: {
        ack: [
            {
                ack_key: "AAAAA",
                input_id: "AAAAA"
            },
            {
                ack_key: "BBBBB",
                input_id: "BBBBB"
            }
        ],
        project_node_id: "ABCDE",
        user: "USER_ID_FROM_GOLIAT_0000"
    },
    gui_settings: {
        question: { text: "Select the blue square" }
    },
    gui_type: "template_ui",
    task_inputs: task_inputs_TemplateUI
};

export const mockSubmitTaskGroupResultsResult_TemplateUI = {
    n_acknowledged: 2,
    n_not_acknowledged: 0
};

export const mockSubmitTaskGroupResultsResult = {
    n_acknowledged: 5,
    n_not_acknowledged: 0
};

export const audioplayer_instructions = [
    `<div style="text-align: center;">
        <button style="background-color: #009900; color: #fff; border: none; font-size: 24px; padding: 7px 10px 7px 10px;" onclick="document.dispatchEvent(new Event('closeInstructions'))">
            Close Instructions
        </button>
        <div style="margin: auto; padding: 25px;">
            <div style="text-align: left; font-size: 15pt; width: 50%; margin: auto;">
                <p>
                    You are presented with multiple short audio tracks.
                    Listen to each one and answer whether you think that the given emotional label
                    describes the track well. The emotional label that you have to check may change for every track.
                </p>
                <p>
                    Select
                    <span style="color: #f00; font-weight: bold;">no</span> when
                    you're sure that the emotional label doesn't fit well.<br />
                    Select
                    <span style="color: #009900; font-weight: bold;">yes</span> when
                    you're sure that the emotional label fits well.<br />
                    Select
                    <span style="color: #00f; font-weight: bold;">unsure</span> when
                    you cannot decide whether the emotional label fits well or not.
                </p>
                <p>
                    You must listen to each audio track in order to answer it.
                    Click the "play" button to start each track. The tracks won't play automatically.
                </p>
                <p><b>Make sure that your speakers or headphones are plugged in and that they're not at full volume.</b></p>
            </div>
            <div>
                <div style="display: inline-block; position: absolute; font-size: 13pt; color: red;"><b>Demo</b></div>
                <img
                    style="margin-top: 20px; border: 1px solid black; height: 400px;"
                    src="https://qm-instructions.s3.eu-central-1.amazonaws.com/audio_player/instructions_audio_player_animated.gif"
                />
            </div>
        </div>
    </div>`
];
export const task_inputs_AudioPlayer = {
    records: [
        {
            end: 5,
            label: "sad",
            id: "4UKCKdYiLN6IMA5ZESUTL7",
            start: 0,
            spotify_url:
                "https://p.scdn.co/mp3-preview/9a60e4b55e9e97242ace679193e32c8bc082eaf3?cid=5c59a60ddaa8470ab724d9b4c53f954b"
        },
        {
            end: 5,
            label: "angry",
            id: "4tXdTwla80gq4CgnkB4vwe",
            start: 0,
            spotify_url:
                "https://p.scdn.co/mp3-preview/688ee7fc080763d3a8715d4f431b718839a1a39b?cid=5c59a60ddaa8470ab724d9b4c53f954b"
        },
        {
            end: 5,
            label: "relaxed",
            id: "6t51lhyY4oss31lgIZsbkJ",
            start: 0,
            spotify_url:
                "https://p.scdn.co/mp3-preview/c3a5528d2409d1704f00d8a7ab40456129f40143?cid=5c59a60ddaa8470ab724d9b4c53f954b"
        },
        {
            end: 5,
            label: "euphoric",
            id: "6vPJvkEuWZPcv58NnQ3dBR",
            start: 0,
            spotify_url:
                "https://p.scdn.co/mp3-preview/c4497002f6989796c5172cfc0aeb08b302a19b42?cid=5c59a60ddaa8470ab724d9b4c53f954b"
        }
    ]
};
export const mockGetTaskResult_AudioPlayer = {
    ok: true,
    task: {
        id: "666",
        task_inputs: task_inputs_AudioPlayer
    },
    gui_settings: {
        question: "Does this track sound {emotional_label}?",
        instructions: audioplayer_instructions
    }
};
export const mockGetTaskGroupResult_AudioPlayer = {};
export const mockSubmitTaskGroupResultsResult_AudioPlayer = {};

export const task_inputs_KeypointSelection = [
    {
        image_url:
            "https://dummyimage.com/16:9x1080/FF0000/FFFFFF.png?text=Click_Top_Right"
    },
    {
        image_url:
            "https://dummyimage.com/16:9x1080/00AA00/FFFFFF.png?text=Click_Center"
    }
];
// instructions has to always be an array of HTML strings
// --> you need at least two slides to display the instructions carousel
// --> if you only have one slide then you're responsible to add the instructions close button by yourself
export const keypointSelectionInstructions = [
    `<div style="text-align: center;">
        <h1>1: Click on the point of interest for every image</h1>
    </div>`,
    `<div style="text-align: center;">
        <h1>2: Click Submit</h1>
        <p>Then grab a beer and feel proud of yourself</p>
    </div>`
];
export const mockGetTaskGroupResult_KeypointSelection = {
    group_ack: {
        ack: [
            {
                ack_key: "AAAAA",
                input_id: "AAAAA"
            },
            {
                ack_key: "BBBBB",
                input_id: "BBBBB"
            }
        ],
        project_node_id: "AB-KEYPOINT-BA",
        user: "USER_ID_FROM_GOLIAT_0000"
    },
    gui_settings: {
        instructions: keypointSelectionInstructions,
        question: { text: "Select the point of interest" } // specific question title will depend on the project
    },
    gui_type: "keypoint_selection",
    task_inputs: task_inputs_KeypointSelection
};
export const mockSubmitTaskGroupResultsResult_KeypointSelection = {
    n_acknowledged: 2,
    n_not_acknowledged: 0
};

export const task_inputs_ZoomKeypointSelection = [
    {
        image_url:
            "https://qm-public-cvd-ct-dataset1.s3.eu-central-1.amazonaws.com/1426787398_01.jpg"
    },
    {
        image_url:
            "https://qm-public-cvd-ct-dataset1.s3.eu-central-1.amazonaws.com/1426787398_02.jpg"
    },
    {
        image_url:
            "https://qm-public-cvd-ct-dataset1.s3.eu-central-1.amazonaws.com/1426787398_03.jpg"
    },
    {
        image_url:
            "https://qm-public-cvd-ct-dataset1.s3.eu-central-1.amazonaws.com/1426787398_04.jpg"
    },
    {
        image_url:
            "https://qm-public-cvd-ct-dataset1.s3.eu-central-1.amazonaws.com/1426787398_05.jpg"
    },
    {
        image_url:
            "https://qm-public-cvd-ct-dataset1.s3.eu-central-1.amazonaws.com/1426787398_06.jpg"
    }
];
// instructions has to always be an array of HTML strings
// --> you need at least two slides to display the instructions carousel
// --> if you only have one slide then you're responsible to add the instructions close button by yourself
export const zoomKeypointSelectionInstructions = [
    `<div style="text-align: center;">
        <h1>1: Click on the point of interest for every image</h1>
    </div>`,
    `<div style="text-align: center;">
        <h1>2: Click Submit</h1>
        <p>Then grab a beer and feel proud of yourself</p>
    </div>`
];
export const mockGetTaskGroupResult_ZoomKeypointSelection = {
    group_ack: {
        ack: [
            {
                ack_key: "AAAAA",
                input_id: "AAAAA"
            },
            {
                ack_key: "BBBBB",
                input_id: "BBBBB"
            }
        ],
        project_node_id: "AB-ZOOM-KEYPOINT-BA",
        user: "USER_ID_FROM_GOLIAT_0000"
    },
    gui_settings: {
        zoom_sequence: [1, 3, 10],
        instructions: zoomKeypointSelectionInstructions,
        question: { text: "Select the point of interest" } // specific question title will depend on the project
    },
    gui_type: "zoom_keypoint_selection",
    task_inputs: task_inputs_ZoomKeypointSelection
};
export const mockSubmitTaskGroupResultsResult_ZoomKeypointSelection = {
    n_acknowledged: 2,
    n_not_acknowledged: 0
};

export const task_inputs_PointcloudProjection = [
    {
        image_url:
            "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.amazonaws.com/image_url/c58baac37a8a4aa0bb673baf68b22331.png",
        pb_data_url:
            "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.amazonaws.com/pb_data_url_light/c58baac37a8a4aa0bb673baf68b22331_light.pb",
        // image_url:
        //     "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.eu-central-1.amazonaws.com/image_url/000bfd04df9f47d6bc586754d1e8e138.png",
        // pb_data_url:
        //     "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.eu-central-1.amazonaws.com/pb_data_url_light/000bfd04df9f47d6bc586754d1e8e138_light.pb",
        box_para: {
            center_l: -34.45926707577836,
            center_w: -0.2689901290922867,
            height_0: 1,
            height_1: 0,
            length: 4.49,
            orientation_rad: -1.58,
            width: 1.81
        },
        // slider_config: {
        //     orientation: true,
        //     width: true,
        //     height: false,
        //     length: true
        // }
        // slider_config: {
        //     orientation: false,
        //     width: false,
        //     height: true,
        //     length: false
        // }
        // slider_config: {
        //     orientation: true,
        //     width: true,
        //     height: true,
        //     length: true
        // },
        slider_config: {
            orientation: false,
            width: false,
            height: false,
            length: false
        }
    }
    // {
    //     image_url:
    //         "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.eu-central-1.amazonaws.com/image_url/000bfd04df9f47d6bc586754d1e8e138.png",
    //     pb_data_url:
    //         "https://cd873718-34ad-4b23-aceb-66e59ae1ef12.s3.eu-central-1.amazonaws.com/pb_data_url_light/000bfd04df9f47d6bc586754d1e8e138_light.pb",

    //     box_para: {
    //         center_l: -36.05748796121921,
    //         center_w: -2.775595127249772,
    //         height_0: 1,
    //         height_1: 0,
    //         length: 5.06,
    //         orientation_rad: -1.6929693744344996,
    //         width: 3.74
    //     },
    //     // slider_config: {
    //     //     orientation: true,
    //     //     width: true,
    //     //     height: true,
    //     //     length: true
    //     // }
    //     slider_config: {
    //         orientation: false,
    //         width: false,
    //         height: false,
    //         length: false
    //     }
    // }
];
export const pointcloudProjectionInstructions = [
    `<div style="text-align: center;">
        <h1>1: Hover around and enjoy the beauty</h1>
    </div>`,
    `<div style="text-align: center;">
        <h1>2: Click Submit</h1>
        <p>Then grab a beer and feel proud of yourself</p>
    </div>`
];
export const mockGetTaskGroupResult_PointcloudProjection = {
    group_ack: {
        ack: [
            {
                ack_key: "AAAAA",
                input_id: "AAAAA"
            },
            {
                ack_key: "BBBBB",
                input_id: "BBBBB"
            }
        ],
        project_node_id: "AB-POINTCLOUD-PROJECTION-BA",
        user: "USER_ID_FROM_GOLIAT_0000"
    },
    gui_settings: {
        instructions: pointcloudProjectionInstructions,
        initial_bev_zoom: 180,
        // sub_gui_type: "button_gui"
        // sub_gui_type: "cog_gui"
        sub_gui_type: "slider_gui"
    },
    gui_type: "pointcloud_projection",
    task_inputs: task_inputs_PointcloudProjection
};
export const mockSubmitTaskGroupResultsResult_PointcloudProjection = {
    n_acknowledged: 2,
    n_not_acknowledged: 0
};

function has(key) {
    return this.hasOwnProperty(key);
}

function get(key) {
    return this[key];
}

/**
 * @type MockQueryParams
 */
export const mockQueryParams = {
    task_id: "666",
    task_endpoint: "https://localhost:5000",
    gui_type: "yes_no_maybe"
};
mockQueryParams.has = has.bind(mockQueryParams);
mockQueryParams.get = get.bind(mockQueryParams);

export const mockQueryParamsFromMturk = {
    ...mockQueryParams,
    from_mturk: 1,
    assignmentId: "ABCDEFG",
    workerId: "ABCDEFG",
    hitId: "ABCDEFG",
    turkSubmitTo: "http://localhost:5000"
};
mockQueryParamsFromMturk.has = has.bind(mockQueryParamsFromMturk);
mockQueryParamsFromMturk.get = get.bind(mockQueryParamsFromMturk);

export const mockQueryParamsGoliat = {
    task_endpoint: "http://localhost:8080",
    vendor_id: "SOME_VENDOR_A",
    vendor_user_id: "SOME_USER_OF_VENDOR_A"
};
mockQueryParamsGoliat.has = has.bind(mockQueryParamsGoliat);
mockQueryParamsGoliat.get = get.bind(mockQueryParamsGoliat);

export const createMockQueryParams = (forGoliat = true, guiType = null) => {
    if (forGoliat) {
        if (guiType === "pointcloud_projection_results") {
            mockQueryParamsGoliat.gui_type = "pointcloud_projection_results";
            // results_url indicates that the usual getTaskGroup from backend should be bypassed
            mockQueryParamsGoliat.results_url =
                "https://qm-pcr.s3.eu-central-1.amazonaws.com/result_json/B1newvis_run3_cars_bbox_annotation.json";
            // "https://qm-pcr.s3.eu-central-1.amazonaws.com/result_json/merged_bb_report.json";
        } else if (guiType === "pointcloud_projection") {
            // mockQueryParamsGoliat.debug_mode = 1;
        }
        return mockQueryParamsGoliat;
    } else {
        // when mocking query params from mturk the gui_type is part of the query params
        const queryParams = mockQueryParamsFromMturk;
        if (guiType !== null) {
            queryParams.gui_type = guiType;
        }
        return queryParams;
    }
};

// per default guiType discrete_answers_gui is assumed
export const createMockApiClient = guiType => {
    let mockGetTaskResult_inner = mockGetTaskResult;
    let mockGetTaskGroupResult_inner = mockGetTaskGroupResult;

    let mockSubmitTaskResult_inner = mockSubmitTaskResult;
    let mockSubmitMTurkAssignmentResult_inner = mockSubmitMTurkAssignmentResult;
    let mockSubmitTaskGroupResultsResult_inner = mockSubmitTaskGroupResultsResult;

    switch (guiType) {
        case "template_ui":
            mockGetTaskResult_inner = mockGetTaskResult_TemplateUI;
            mockGetTaskGroupResult_inner = mockGetTaskGroupResult_TemplateUI;
            mockSubmitTaskGroupResultsResult_inner = mockSubmitTaskGroupResultsResult_TemplateUI;
            break;
        case "spotify_audio_player":
            mockGetTaskResult_inner = mockGetTaskResult_AudioPlayer;
            mockGetTaskGroupResult_inner = mockGetTaskGroupResult_AudioPlayer;
            mockSubmitTaskGroupResultsResult_inner = mockSubmitTaskGroupResultsResult_AudioPlayer;
            break;
        case "keypoint_selection":
            // only supported for goliat
            mockGetTaskResult_inner = null; // python api response
            mockGetTaskGroupResult_inner = mockGetTaskGroupResult_KeypointSelection; // goliat api response
            mockSubmitTaskGroupResultsResult_inner = mockSubmitTaskGroupResultsResult_KeypointSelection; // goliat api response
            break;
        case "zoom_keypoint_selection":
            // only supported for goliat
            mockGetTaskResult_inner = null;
            mockGetTaskGroupResult_inner = mockGetTaskGroupResult_ZoomKeypointSelection;
            mockSubmitTaskGroupResultsResult_inner = mockSubmitTaskGroupResultsResult_ZoomKeypointSelection;
            break;
        case "pointcloud_projection":
            // only supported for goliat
            mockGetTaskResult_inner = null;
            mockGetTaskGroupResult_inner = mockGetTaskGroupResult_PointcloudProjection;
            mockSubmitTaskGroupResultsResult_inner = mockSubmitTaskGroupResultsResult_PointcloudProjection;
            break;
        default:
            break;
    }

    mockGetTaskGroupResult_inner.has_tasks = true;

    return {
        // python task-api
        getTask: (taskId, callback) => {
            callback(null, mockGetTaskResult_inner);
        },
        getTaskWithError: (taskId, callback) => {
            callback({ ok: false, error: "some error" }, null);
        },
        submitTask: (taskResult, callback) => {
            callback(null, mockSubmitTaskResult_inner);
        },
        submitTaskWithError: (taskResult, callback) => {
            callback({ ok: false, error: "some error" }, null);
        },
        submitMTurkAssignment: (mturkAssignment, callback) => {
            callback(null, mockSubmitMTurkAssignmentResult_inner);
        },
        submitMTurkAssignmentWithError: (mturkAssignment, callback) => {
            callback({ ok: false, error: "some error" }, null);
        },

        // goliat api
        getTaskGroup: callback => {
            callback(null, mockGetTaskGroupResult_inner);
        },
        submitTaskGroupResults: (submitTaskGroupRequest, callback) => {
            callback(null, mockSubmitTaskGroupResultsResult_inner);
        }
    };
};
