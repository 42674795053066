import {
    taskFetch,
    taskReceived,
    taskFetchError,
    setGuiSettings,
    taskSubmit,
    taskSubmitSuccess,
    taskSubmitError,
    setNextGuiType,
    setQuestionsAmount,
    setGuiType,
    addResourceToCache
} from "../../modules/TaskUI/actions";
import { GoliatApiClient } from "../ApiClient/GoliatApiClient";
import { checkRequiredQueryParams as doRequiredQueryParamCheck } from "../qm_cs_lib";
import { loadImagesAction } from "../../modules/TaskUI/actions/asyncActions";
// eslint-disable-next-line
import TaskUIContext from "./taskUIContext";
import { WIDGETS } from "../../guiFactory";
import DiscreteAnswersGuiTaskOutput from "../ApiClient/models/DiscreteAnswersGuiTaskOutput";
import SubmitTaskGroupRequest from "../ApiClient/models/SubmitTaskGroupRequest";
import { TasksSDKResult } from "../TasksSdk/TasksSDKResult";
import { TasksSDKReward } from "../TasksSdk/TasksSDKReward";
import {
    templateUICreateTaskOutput,
    createPreloadableResourceMappingPointCloudProjection,
    allResourcesForCurrentTaskInputInResourceCachePointCloudProjection
} from "./sharedStrategies";

// all functions in this file expect to be run in a TaskUIContext object

export const LOADING_TIME_RESOURCE_PREFIX = "loadingTimeMillis_";

/**
 * @this TaskUIContext
 */
export function checkRequiredQueryParams() {
    // the mturk specific query params are handled directly by the mturkHelper
    const requiredQueryParamNames = [
        "task_endpoint",
        "vendor_id",
        "vendor_user_id"
    ];
    return doRequiredQueryParamCheck(this._params, requiredQueryParamNames);
}

/**
 * @this TaskUIContext
 */
export function init(apiClient) {
    const allParamsOk = this.checkRequiredQueryParams();
    if (allParamsOk && apiClient === null) {
        this._apiClient = new GoliatApiClient({
            endpoint: this._params.get("task_endpoint"),
            vendor_id: this._params.get("vendor_id"),
            vendor_user_id: this._params.get("vendor_user_id"),
            // optional
            project_id: this._params.get("project_id"),
            project_node_id: this._params.get("project_node_id")
        });
    } else if (!allParamsOk) {
        console.error("Missing parameters in", this._params);
    } else {
        this._apiClient = apiClient;
    }

    if (allParamsOk) {
        this.setSpecificVar(
            "vendor_user_id",
            this._params.get("vendor_user_id")
        );
        this.setSpecificVar("vendor_id", this._params.get("vendor_id"));
    }
}

/**
 * @this TaskUIContext
 */
export function getInitialGuiType() {
    return WIDGETS.LOADING;
}

/**
 * @this TaskUIContext
 */
export function createTaskOutput(data) {
    const taskOutput = new DiscreteAnswersGuiTaskOutput(
        data.answer,
        data.timeMillis
    );
    return taskOutput;
}

/**
 * @this TaskUIContext
 */
export function createResultsSubmitObject(data) {
    const groupAck = data.globalState.taskRequest.task.group_ack;
    const submitTaskGroupRequest = new SubmitTaskGroupRequest(
        groupAck,
        data.answers
    );

    return submitTaskGroupRequest;
}

/**
 * @this TaskUIContext
 */
export function hasImagesToPreload(task_inputs) {
    return task_inputs.some(t => t.hasOwnProperty("image_url"));
}

/**
 *
 * @this {TaskUIContext}
 */
export function getQuestionsAmount(task_inputs) {
    return task_inputs.length;
}

/**
 * @this TaskUIContext
 */
export function fetchTasks(resolve, reject, dispatch) {
    // when results_url and gui_type are set, then the rest of fetchTasks can be skipped
    if (this._params.has("results_url") && this._params.has("gui_type")) {
        this.setSpecificVar("results_url", this._params.get("results_url"));
        const guiType = this._params.get("gui_type");
        dispatch(setGuiType(guiType));
        dispatch(setNextGuiType(guiType));
        resolve();
        return;
    }

    const vendor_id = this._params.get("vendor_id");
    const vendor_user_id = this._params.get("vendor_user_id");
    // enforce vendor_id and vendor_user_id have sensible values
    const defaultValues = ["CHANGE_ME", "CHANGE_THIS"];
    if (
        !vendor_id ||
        !vendor_user_id ||
        defaultValues.includes(vendor_id.toUpperCase()) ||
        defaultValues.includes(vendor_user_id.toUpperCase())
    ) {
        dispatch(setNextGuiType(WIDGETS.MALFORMED_USER_IDS));
        return;
    }

    dispatch(taskFetch());

    this._apiClient.getTaskGroup((err, data) => {
        if (err !== null) {
            dispatch(taskFetchError(err));
            dispatch(setNextGuiType(WIDGETS.GENERIC_ERROR));
            reject(err);
        } else {
            dispatch(taskReceived(data));

            // check if there are any task_inputs
            if (!data.has_tasks) {
                dispatch(setNextGuiType(WIDGETS.NO_TASKS_AVAILABLE));
                return;
            }

            this.reconfigureStrategiesBasedOnGuiType(data.gui_type);

            // remember user_id from goliat backend
            this.setSpecificVar("user_id", data.group_ack.user);

            // enables legacy transforms of gui_settings like: change location of question.text gui_settings
            const gui_settings = this.transformGuiSettings(data.gui_settings);
            dispatch(setGuiSettings(gui_settings));

            // the guiType for the task
            dispatch(setGuiType(data.gui_type));
            // the guiType that the ui should show next
            dispatch(setNextGuiType(data.gui_type));

            if (!this.useGenericResourceLoading) {
                if (this.hasImagesToPreload(data.task_inputs)) {
                    // TODO: this is a terrible hack, allowing all top-level keys (non-recursive) ending with _url to be preloaded
                    // no check is done whether these are strings with image URLs though
                    const images_urls = [];
                    for (let i = 0; i < data.task_inputs.length; ++i) {
                        const task_input = data.task_inputs[i];
                        for (
                            let k = 0;
                            k < Object.keys(task_input).length;
                            ++k
                        ) {
                            const key = Object.keys(task_input)[k];
                            if (key.endsWith("_url")) {
                                images_urls.push(task_input[key]);
                            }
                        }
                    }
                    dispatch(loadImagesAction(images_urls));
                }
            } else {
                let resourceLoaders = {};
                for (const task_input of data.task_inputs) {
                    const taskInputResourceLoaders = this.createPreloadableResourceToLoaderMapping(
                        task_input
                    );
                    resourceLoaders = {
                        ...resourceLoaders,
                        ...taskInputResourceLoaders
                    };
                }
                for (const url in resourceLoaders) {
                    resourceLoaders[url]
                        .loadAndDecode()
                        .then(resource => {
                            const loadingTimeMillis = resourceLoaders[
                                url
                            ].calcLoadingTimeMillis();
                            dispatch(addResourceToCache(url, resource));
                            dispatch(
                                addResourceToCache(
                                    LOADING_TIME_RESOURCE_PREFIX + url,
                                    loadingTimeMillis
                                )
                            );
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            }

            const questionsAmount = this.getQuestionsAmount(data.task_inputs);
            dispatch(setQuestionsAmount(questionsAmount));

            resolve();
        }
    });
}

/**
 * @this TaskUIContext
 */
export function submitTaskResults(resolve, reject, dispatch, taskResults) {
    dispatch(taskSubmit());

    this._apiClient.submitTaskGroupResults(taskResults, (err, data) => {
        if (err) {
            dispatch(taskSubmitError(err));
            console.error(err);
            reject(err);
        } else {
            dispatch(taskSubmitSuccess(data));
            resolve(data);
        }
    });
}

/**
 * @this TaskUIContext
 */
export function handleTasksSDKResult() {
    // window.qualitymatch = {
    //     tasks: {
    //         completed: data => {
    //             console.log("done", data);
    //         }
    //     }
    // };

    // check whether tasks-sdk is being used and a TaskResult for it should be submitted
    if (
        window.qualitymatch === undefined ||
        window.qualitymatch.tasks === undefined ||
        window.qualitymatch.tasks.completed === undefined
    ) {
        return;
    }

    console.log("notifying tasks-sdk that task-ui is done now");

    const taskSdkResult = new TasksSDKResult();
    const taskSdkRewards = [];
    taskSdkRewards.push(
        new TasksSDKReward().setRewardId("SOME_REWARD_ID_0").setAmount(666)
    );
    taskSdkRewards.push(
        new TasksSDKReward().setRewardId("SOME_REWARD_ID_1").setAmount(666)
    );
    taskSdkResult
        // there's no taskId for goliat's TaskGroups!
        .setTaskId("THIS_IS_AN_OUTDATED_CONCEPT")
        .setUserId(this.getSpecificVar("user_id"))
        .setRewards(taskSdkRewards);
    window.qualitymatch.tasks.completed(taskSdkResult);
}

/**
 * @this TaskUIContext
 */
export function reconfigureStrategiesBasedOnGuiType(guiType) {
    switch (guiType) {
        case WIDGETS.DISCRETE_ANSWERS_GUI:
            this.getCurrentImageUrl = getCurrentImageUrl;
            this.transformGuiSettings = guiSettings => {
                const transformed = { ...guiSettings };
                transformed.question = transformed.question.text;
                transformed.button_configs = transformed.buttons;
                return transformed;
            };
            break;
        case WIDGETS.TEMPLATE_UI:
            this.hasImagesToPreload = () => false;
            this.createTaskOutput = templateUICreateTaskOutput;
            this.transformGuiSettings = guiSettings => {
                const transformed = { ...guiSettings };
                transformed.question = transformed.question.text;
                return transformed;
            };
            break;
        case WIDGETS.KEYPOINT_SELECTION:
            this.getCurrentImageUrl = getCurrentImageUrl;
            // might want to add this as a generalized function
            // since this seems to be the default guiSettings transformation
            this.transformGuiSettings = guiSettings => {
                const transformed = { ...guiSettings };
                transformed.question = transformed.question.text;
                return transformed;
            };
            break;
        case WIDGETS.ZOOM_KEYPOINT_SELECTION:
            this.getCurrentImageUrl = getCurrentImageUrl;
            // might want to add this as a generalized function
            // since this seems to be the default guiSettings transformation
            this.transformGuiSettings = guiSettings => {
                const transformed = { ...guiSettings };
                transformed.question = transformed.question.text;
                return transformed;
            };
            break;
        case WIDGETS.POINTCLOUD_PROJECTION:
            this.getCurrentImageUrl = getCurrentImageUrl;
            // TODO: remove this backwards compatibility in the next refactoring, when all views use the same approach
            this.useGenericResourceLoading = true;
            this._createPreloadableResourceToLoaderMappingStrategy = createPreloadableResourceMappingPointCloudProjection;
            this._allResourcesForCurrentTaskInputInResourceCacheStrategy = allResourcesForCurrentTaskInputInResourceCachePointCloudProjection;
            break;
        default:
            break;
    }
}

// specifics for the discrete answers gui with images

/**
 *
 * @this TaskUIContext
 */
function getCurrentImageUrl(data) {
    const image_urls = data.taskRequest.task.task_inputs.reduce(
        (list, task_input) => list.concat(task_input.image_url),
        []
    );
    return image_urls[data.currentQuestionIdx];
}
