import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import DomNode from "../../components/DomNode/DomNode";
import { createViewWithInstructions } from "../TaskUI/ViewWithInstructions";
import ProgressBar from "../../components/ProgressBar/ProgressBar";

import { WIDGETS } from "../../guiFactory";

import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";
import { getTimestamp } from "../../modules/TaskUI/reducers/timestamp";
import { getAnswers } from "../../modules/TaskUI/reducers/answers";
import { getImgCache } from "../../modules/TaskUI/reducers/imgCache";
import {
    captureTimestamp,
    setNextGuiType,
    addAnswerForQuestion,
    questionNext,
    setTaskResult,
    popAnswerForQuestion,
    questionPrevious,
    allImagesLoaded
} from "../../modules/TaskUI/actions";

import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./ZoomKeypointSelection.css";

/**
 * @augments {React.Component<Props, State>}
 */
class ZoomKeypointSelectionView extends React.Component {
    constructor(props) {
        super(props);

        this.default_state = {
            zoom_index: 0,
            img_mouse_x: 0,
            img_mouse_y: 0,
            img_feature_x: 0,
            img_feature_y: 0,
            img_scale: 1,
            img_tx: 0,
            img_ty: 0,
            show_marker: false,
            enable_next: false
        };
        this.state = { ...this.default_state };
    }

    resetState() {
        this.setState(this.default_state);
    }

    componentDidMount() {
        this.props.captureTimestamp(Date.now());
    }

    getCurrentTaskInput() {
        if (this.isReady()) {
            return this.props.task.task_inputs.records[
                this.props.currentQuestionIdx
            ];
        }
        return null;
    }

    getTitleFromGuiSettings() {
        if (this.props.guiSettings !== null) {
            return this.props.guiSettings.question;
        }
    }

    getInstructionsButton() {
        // the callback openInstructions and the bool hasInstructions will be injected by the higher order component ViewWithInstructions
        if (this.props.hasInstructions) {
            return (
                <button
                    className="button is-sm"
                    onClick={this.props.openInstructions}
                >
                    Instructions
                </button>
            );
        } else {
            return <></>;
        }
    }

    isReady() {
        return this.props.task !== null && this.props.guiSettings !== null;
    }

    getCurrentImageObject() {
        return this.props.imgCache[
            this.props.taskUIContext.getCurrentImageUrl(this.props.globalState)
        ];
    }

    getCanvasWithCurrentImage() {
        if (this.props.allImagesLoaded) {
            const currentImage = this.getCurrentImageObject();

            return <DomNode node={currentImage} />;
        } else {
            return <Spinner animation={"border"} variant={"secondary"} />;
        }
    }

    addAnswer(answer) {
        const answerObject = this.props.taskUIContext.createTaskOutput({
            globalState: this.props.globalState,
            timeMillis:
                Date.now() - this.props.currentAnswerStartTimestampMillis,
            answer: answer
        });

        this.props.addAnswerToStore(answerObject);
        console.log("added answer", answerObject);
        return answerObject;
    }

    addKeypoint() {
        if (this.props.currentQuestionIdx < this.props.questionsAmount) {
            const answer = {
                x: this.state.img_feature_x,
                y: this.state.img_feature_y
            };
            const latestAnswer = this.addAnswer(answer);

            if (
                this.props.currentQuestionIdx + 1 ===
                this.props.questionsAmount
            ) {
                const resultsSubmitObject = this.props.taskUIContext.createResultsSubmitObject(
                    {
                        globalState: this.props.globalState,
                        answers: [...this.props.answers, latestAnswer]
                    }
                );
                this.props.setSubmitResultsObject(resultsSubmitObject);
                this.props.setNextGuiType(WIDGETS.SUBMIT);
            } else {
                this.props.captureTimestamp(Date.now());
                this.resetState();
                this.props.questionNext();
            }
        }
    }

    onBackClicked() {
        // - pop the previous task_input's answer from the answer stack
        // - load the previous task_input
        if (this.props.answers.length > 0) {
            const removedAnswer = this.props.answers[
                this.props.answers.length - 1
            ];
            console.log("removed answer", removedAnswer);
            this.props.popAnswerFromStore();
            this.props.questionPrevious();
            this.props.captureTimestamp(Date.now());
        }
    }

    onImgClicked() {
        const img = this.getCurrentImageObject();
        const cx = img.width / 2; // constant, even if scaled transform
        const cy = img.height / 2;

        this.setState({
            img_feature_x: this.state.img_mouse_x,
            img_feature_y: this.state.img_mouse_y,
            img_tx: cx - this.state.img_mouse_x,
            img_ty: cy - this.state.img_mouse_y
        });

        if (
            this.state.zoom_index >=
            this.props.guiSettings.zoom_sequence.length - 1
        ) {
            this.setState({
                enable_next: true,
                show_marker: true
            });
        } else {
            this.setState({
                zoom_index: this.state.zoom_index + 1,
                img_scale: this.props.guiSettings.zoom_sequence[
                    this.state.zoom_index + 1
                ]
            });
        }
    }

    onImgMouseMoved(e) {
        this.setState({
            img_mouse_x: e.nativeEvent.offsetX, //image coordinate, ignoring translation and scaling transforms
            img_mouse_y: e.nativeEvent.offsetY
        });
    }

    render() {
        if (!this.isReady()) {
            return <></>;
        }

        const marker_visible =
            this.state.show_marker === false
                ? { display: "none" }
                : { display: "block" };
        const img = this.getCurrentImageObject();
        const marker_view_box = "0 0 " + img.width + " " + img.height;

        const img_transform =
            "scale(" +
            this.state.img_scale +
            ") translate(" +
            this.state.img_tx +
            "px, " +
            this.state.img_ty +
            "px)";
        return (
            <div className={"container-qm container_zks"}>
                <div className="image_container_zks">
                    <div
                        className="img-overlay-wrap"
                        onClick={this.onImgClicked.bind(this)}
                        onMouseMove={this.onImgMouseMoved.bind(this)}
                        style={{ transform: img_transform }}
                    >
                        {this.getCanvasWithCurrentImage()}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox={marker_view_box}
                            style={marker_visible}
                        >
                            <circle
                                cx={this.state.img_feature_x}
                                cy={this.state.img_feature_y}
                                r="5"
                                stroke="red"
                                strokeWidth="1"
                                fillOpacity="0"
                            />
                            <circle
                                cx={this.state.img_feature_x}
                                cy={this.state.img_feature_y}
                                r="0.5"
                                stroke="red"
                                strokeWidth="0.1"
                                fillOpacity="0"
                            />
                        </svg>
                    </div>
                </div>
                <div className="title_zks">
                    <h1>{this.getTitleFromGuiSettings()}</h1>
                </div>
                <div className="progress_container_zks">
                    <ProgressBar
                        index={
                            this.props.allImagesLoaded
                                ? this.props.currentQuestionIdx + 1
                                : 0
                        }
                        amount={this.props.questionsAmount || 0}
                    />
                </div>
                <div className="actions_container_zks">
                    <div>
                        <button
                            className="button is-sm"
                            style={{ backgroundColor: "#E11", color: "#EEE" }}
                            disabled={!this.state.enable_next}
                            onClick={this.addKeypoint.bind(this)}
                        >
                            Next
                        </button>
                    </div>
                    <div>
                        <button
                            className="button is-sm"
                            onClick={this.onBackClicked.bind(this)}
                        >
                            Undo
                        </button>
                        {this.getInstructionsButton()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    task: getTask(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    questionsAmount: getQuestionsAmount(state),
    guiSettings: getGuiSettings(state),
    allImagesLoaded: allImagesLoaded(state),
    currentAnswerStartTimestampMillis: getTimestamp(state),
    answers: getAnswers(state),
    imgCache: getImgCache(state),
    globalState: state.global
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            addAnswerToStore: addAnswerForQuestion,
            popAnswerFromStore: popAnswerForQuestion,
            questionNext: questionNext,
            questionPrevious: questionPrevious,
            setSubmitResultsObject: setTaskResult,
            setNextGuiType: setNextGuiType
        },
        dispatch
    );

ZoomKeypointSelectionView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

ZoomKeypointSelectionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(ZoomKeypointSelectionView);
ZoomKeypointSelectionView = createViewWithInstructions(
    ZoomKeypointSelectionView
);

export default ZoomKeypointSelectionView;
