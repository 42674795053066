import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { thankYouReached } from "../../modules/TaskUI/actions";
import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";
import { sleep } from "../../lib/qm_cs_lib";

import "./styles/ThankYouView.css";

class ThankYouView extends React.Component {
    componentDidMount() {
        this.props.thankYouReached();
        sleep(2000).then(() => {
            this.props.taskUIContext.handleTasksSDKResult();
        });
    }

    render() {
        return (
            <div className="container-qm thankyouContainer">
                <div>
                    <div className="thankyou-icon"></div>
                    <p className="header">Thanks for your submission!</p>
                    <button
                        className="continue-button"
                        type="button"
                        onClick={refreshPage}
                    >
                        {" "}
                        <span>Continue</span>{" "}
                    </button>
                </div>
            </div>
        );
    }
}

const refreshPage = () => {
    window.location.reload();
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            thankYouReached: thankYouReached
        },
        dispatch
    );

ThankYouView.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext)
};

ThankYouView = connect(null, mapDispatchToProps)(ThankYouView);

export default ThankYouView;
