// eslint-disable-next-line
import TaskUIContext from "./taskUIContext";
import { WIDGETS } from "../../guiFactory";
import { Loader, fetchLoad, loadImageObject } from "../DataLoader/loaders";
import {
    decodeAny,
    decodeProtobufFromResponseCreator
} from "../DataLoader/decoders";

const PointsTable = require("../../lib/proto/points_table_pb");

/**
 * @this {TaskUIContext}
 */
export function canSubmit(reject, taskResults) {
    if (taskResults === undefined || taskResults === null) {
        reject(new Error("can't submit empty answers"));
        return false;
    }
    return true;
}

/**
 * @this {TaskUIContext}
 */
export function canSwitchToGui(guiType, data) {
    switch (guiType) {
        case WIDGETS.SUBMIT:
            return this.canSubmit(() => {}, data.globalState.taskResult);
        default:
            return true;
    }
}

/**
 * @this {TaskUIContext}
 */
export function templateUICreateTaskOutput(data) {
    // for better separation and documentation: you should create a new class for the created object
    return { square_id: data.id, time_ms: data.timeMillis };
}

/**
 * @this {TaskUIContext}
 * @returns {Map<String, Loader>}
 */
export function createPreloadableResourceMappingPointCloudProjection(
    task_input
) {
    const imgLoader = new Loader(
        task_input.image_url,
        loadImageObject,
        decodeAny
    );
    const protobufLoader = new Loader(
        task_input.pb_data_url,
        fetchLoad,
        decodeProtobufFromResponseCreator(PointsTable.Table)
    );
    const map = {
        [protobufLoader.url]: protobufLoader,
        [imgLoader.url]: imgLoader
    };
    return map;
}

/**
 * @this {TaskUIContext}
 * @param {Object} globalState
 * @param {Object} task_input
 * @returns {Boolean}
 */
export function allResourcesForCurrentTaskInputInResourceCachePointCloudProjection(
    resourceCache,
    task_input
) {
    let err = false;
    for (const key of ["image_url", "pb_data_url"]) {
        if (resourceCache[task_input[key]] === undefined) {
            err = true;
        }
    }
    return !err;
}
