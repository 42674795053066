export default class TaskUIContext {
    constructor(
        params,
        fetchTasksStrategy,
        submitTaskResultsStrategy,
        checkRequiredQueryParamsStrategy,
        initStrategy,
        getInitialGuiTypeStrategy,
        createTaskOutputStrategy,
        createResultSubmitObjectStrategy,
        reconfigureStrategiesBasedOnGuiTypeStrategy,
        hasImagesToPreloadStrategy,
        canSubmitStrategy,
        canSwitchToGuiStrategy,
        getQuestionsAmountStrategy,
        handleTasksSDKResultStrategy,
        createPreloadableResourceToLoaderMappingStrategy,
        allResourcesForCurrentTaskInputInResourceCacheStrategy
    ) {
        this._fetchTasksStrategy = fetchTasksStrategy;
        this._submitTaskResultsStrategy = submitTaskResultsStrategy;
        this._checkRequiredQueryParamsStrategy = checkRequiredQueryParamsStrategy;
        this._initStrategy = initStrategy;
        this._getInitialGuiTypeStrategy = getInitialGuiTypeStrategy;
        this._createTaskOutputStrategy = createTaskOutputStrategy;
        this._createResultsSubmitObjectStrategy = createResultSubmitObjectStrategy;
        this._reconfigureStrategiesBasedOnGuiTypeStrategy = reconfigureStrategiesBasedOnGuiTypeStrategy;
        this._hasImagesToPreloadStrategy = hasImagesToPreloadStrategy;
        this._canSubmitStrategy = canSubmitStrategy;
        this._canSwitchToGuiStrategy = canSwitchToGuiStrategy;
        this._getQuestionsAmountStrategy = getQuestionsAmountStrategy;
        this._handleTasksSDKResultStrategy = handleTasksSDKResultStrategy;
        this._createPreloadableResourceToLoaderMappingStrategy = createPreloadableResourceToLoaderMappingStrategy;
        this._allResourcesForCurrentTaskInputInResourceCacheStrategy = allResourcesForCurrentTaskInputInResourceCacheStrategy;

        this._params = params;
    }

    /**
     * This method will run in an async redux action.
     * This method is expected to set the questionsAmount via a redux action.
     */
    fetchTasks(resolve, reject, dispatch) {
        this._fetchTasksStrategy(resolve, reject, dispatch);
    }

    /**
     * This method will run in an async redux action.
     */
    submitTaskResults(resolve, reject, dispatch, taskResults, mturkInfos) {
        if (!this.canSubmit(reject, taskResults, mturkInfos)) {
            return;
        }

        this._submitTaskResultsStrategy(
            resolve,
            reject,
            dispatch,
            taskResults,
            mturkInfos
        );
    }

    canSubmit(reject, taskResults, mturkInfos) {
        return this._canSubmitStrategy(reject, taskResults, mturkInfos);
    }

    checkRequiredQueryParams() {
        return this._checkRequiredQueryParamsStrategy();
    }

    init(apiClient) {
        this._initStrategy(apiClient);
    }

    getInitialGuiType() {
        return this._getInitialGuiTypeStrategy();
    }

    /**
     *
     * @param {Object | Object[]} data an arbitrary object or array of objects with which the specified strategy will create a single taskOutput
     */
    createTaskOutput(data) {
        return this._createTaskOutputStrategy(data);
    }

    /**
     *
     * @param {Object | Object[]} data an arbitrary object or array of objects with which the specified strategy will create the object that will be submitted as result
     */
    createResultsSubmitObject(data) {
        return this._createResultsSubmitObjectStrategy(data);
    }

    /**
     *
     * @param {Object | Object[]} data an arbitrary object or array of objects with which the specified strategy will decide whether images need to be preloaded
     */
    hasImagesToPreload(data) {
        return this._hasImagesToPreloadStrategy(data);
    }

    /**
     *
     * @param {import("../../guiFactory").WIDGET} guiType
     */
    reconfigureStrategiesBasedOnGuiType(guiType) {
        this._reconfigureStrategiesBasedOnGuiTypeStrategy(guiType);
    }

    /**
     *
     * @param {import("../../guiFactory").WIDGET} guiType
     * @param {Object | Object[]} data an arbitrary object or array of objects with which the specified strategy will decide whether gui switch is allowed
     */
    canSwitchToGui(guiType, data) {
        return this._canSwitchToGuiStrategy(guiType, data);
    }

    /**
     *
     * @param {Object | Object[]} data an arbitrary object or array of objects with which the specified strategy will decide how many questions there are
     */
    getQuestionsAmount(data) {
        return this._getQuestionsAmountStrategy(data);
    }

    transformGuiSettings(guiSettings) {
        return guiSettings;
    }

    handleTasksSDKResult() {
        this._handleTasksSDKResultStrategy();
    }

    /**
     * @param {Object} task_input a single task_input object
     */
    createPreloadableResourceToLoaderMapping(task_input) {
        return this._createPreloadableResourceToLoaderMappingStrategy(task_input);
    }

    allResourcesForCurrentTaskInputInResourceCache(resourceCache, task_input) {
        return this._allResourcesForCurrentTaskInputInResourceCacheStrategy(resourceCache, task_input);
    }

    // general context variables
    _params;

    /**
     * @type {import("../ApiClient/TaskApiClient").TaskApiClient | import("../ApiClient/GoliatApiClient").GoliatApiClient}
     */
    _apiClient;
    get apiClient() {
        return this._apiClient;
    }

    // specific context variables
    _specificVars = {};
    getSpecificVar(name) {
        if (this.hasSpecificVar(name)) {
            return this._specificVars[name];
        } else {
            return undefined;
        }
    }
    setSpecificVar(name, value) {
        if (this._specificVars === undefined || this._specificVars === null) {
            this._specificVars = {};
        }
        this._specificVars[name] = value;
    }
    hasSpecificVar(name) {
        return (
            this._specificVars.hasOwnProperty(name) &&
            this._specificVars[name] !== undefined
        );
    }
}
