import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import TaskUIContext from "../../lib/TaskUIStrategy/taskUIContext";

// our own components
import ProgressBar from "../../components/ProgressBar/ProgressBar";

// state accessor methods
import { getTask } from "../../modules/TaskUI/reducers/taskRequest";
import { getCurrentQuestionIdx } from "../../modules/TaskUI/reducers/currentQuestionIdx";
import { getGuiSettings } from "../../modules/TaskUI/reducers/guiSettings";
import SelectableSquare from "../../components/TaskUI/TemplateUI/SelectableSquare";
import { getQuestionsAmount } from "../../modules/TaskUI/reducers/questionsAmount";

// actions that can alter the store
import {
    captureTimestamp,
    setNextGuiType,
    addAnswerForQuestion,
    questionNext,
    setTaskResult,
    popAnswerForQuestion,
    questionPrevious
} from "../../modules/TaskUI/actions";

// import css
import "./TemplateUIView.css";
import { getTimestamp } from "../../modules/TaskUI/reducers/timestamp";
import { WIDGETS } from "../../guiFactory";
import { getAnswers } from "../../modules/TaskUI/reducers/answers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { createViewWithInstructions } from "../TaskUI/ViewWithInstructions";

/**
 * @augments {Component<Props, State>}
 */
class TemplateUI extends Component {
    constructor() {
        super();
        this.state = { selectedSquareId: -1 };
    }

    componentDidMount() {
        this.props.captureTimestamp(Date.now());
    }

    onSquareSelected(id) {
        let newId = id;
        if (this.state.selectedSquareId === newId) {
            newId = -1;
        }
        this.setState({ selectedSquareId: newId });
    }

    getCurrentTaskInput() {
        return this.props.task.task_inputs[this.props.currentQuestionIdx];
    }

    getProgressBarIndex() {
        if (this.props.taskUIContext.hasImagesToPreload()) {
            return this.props.allImagesLoaded
                ? this.props.currentQuestionIdx + 1
                : 0;
        } else {
            return this.props.currentQuestionIdx + 1;
        }
    }

    addAnswer() {
        // add the task_output for the current task_input
        const nowMillis = Date.now();
        const answerObject = this.props.taskUIContext.createTaskOutput({
            id: this.state.selectedSquareId,
            timeMillis: nowMillis - this.props.currentAnswerStartTimestampMillis
        });
        this.props.addAnswerToStore(answerObject);
        console.log("added answer", answerObject);
        return answerObject;
    }

    onBackClicked() {
        const removedAnswer = this.props.answers[this.props.answers.length - 1];
        console.log("removed answer", removedAnswer);
        this.setState({ selectedSquareId: -1 });
        // this actually removed the latest answer
        this.props.popAnswerFromStore();
        this.props.questionPrevious();
    }

    onOkClicked() {
        if (this.state.selectedSquareId === -1) {
            return;
        }

        // add an answer, capture beginning timestamp for the next task_input
        // and go to the next task_input
        // or
        // create submitResultsObject and go to SubmitView
        if (this.props.currentQuestionIdx < this.props.questionsAmount) {
            const latestAnswer = this.addAnswer();

            // check if we're finished
            if (
                this.props.currentQuestionIdx + 1 ===
                this.props.questionsAmount
            ) {
                // taskUIContext needs access to the state from the store
                // in order to build the resultsSubmitObject
                const resultsSubmitObject = this.props.taskUIContext.createResultsSubmitObject(
                    {
                        globalState: this.props.globalState,
                        answers: [...this.props.answers, latestAnswer]
                    }
                );
                this.props.setSubmitResultsObject(resultsSubmitObject);
                // initiate gui change to SubmitView
                this.props.setNextGuiType(WIDGETS.SUBMIT);
            } else {
                // go to next task_input
                this.props.captureTimestamp(Date.now());
                this.setState({ selectedSquareId: -1 });
                this.props.questionNext();
            }
        }
    }

    // renders the question title/text from the guiSettings
    renderText() {
        return (
            <h1>
                {this.props.guiSettings.question}
                {/* this prop will be injected by the higher order component ViewWithInstructions */}
                {this.props.showInstructionsButton}
            </h1>
        );
    }

    // renders the squares for the current task_input
    renderSquares() {
        const currentTaskInput = this.getCurrentTaskInput();
        const squares = [];
        for (let i = 0; i < currentTaskInput.squares.length; i++) {
            const square = currentTaskInput.squares[i];
            squares.push(
                <SelectableSquare
                    key={i}
                    id={i}
                    text={square.text}
                    isBlue={square.isBlue}
                    isSelected={
                        this.state.selectedSquareId === i ? true : false
                    }
                    onSelect={this.onSquareSelected.bind(this)}
                />
            );
        }
        return squares;
    }

    // actions that will save the current answer and lead the ui to the next task_input
    renderActions() {
        let backButton = <></>;
        if (this.props.currentQuestionIdx > 0) {
            backButton = (
                <button
                    className="templateUIBackButton"
                    onClick={this.onBackClicked.bind(this)}
                >
                    <FontAwesomeIcon icon={faUndo} />
                </button>
            );
        }

        let okButton = <></>;
        if (this.state.selectedSquareId !== -1) {
            okButton = (
                <button
                    className="templateUIOkButton"
                    onClick={this.onOkClicked.bind(this)}
                >
                    OK
                </button>
            );
        }

        return (
            <>
                {backButton}
                {okButton}
            </>
        );
    }

    isReady() {
        return this.props.task !== null && this.props.guiSettings !== null;
    }

    // try to keep this function short and basic for better readability of the overall View
    // you could also put these different parts in different new components: one component for the square group, one for the actions, etc.
    render() {
        if (!this.isReady()) {
            return <></>;
        }

        return (
            <>
                <div className="questionText">{this.renderText()}</div>
                <div className="questionContent">{this.renderSquares()}</div>
                {/* add a progress bar if you want */}
                <div className="progressBar_tui">
                    <ProgressBar
                        index={this.getProgressBarIndex()}
                        amount={this.props.questionsAmount || 0}
                    />
                </div>
                <div className="questionActions">{this.renderActions()}</div>
            </>
        );
    }
}

// maps state from the store to the connected Component's props
// by using state accessor methods
const mapStateToProps = state => ({
    task: getTask(state),
    currentQuestionIdx: getCurrentQuestionIdx(state),
    questionsAmount: getQuestionsAmount(state),
    guiSettings: getGuiSettings(state),
    currentAnswerStartTimestampMillis: getTimestamp(state),
    answers: getAnswers(state),
    globalState: state.global
});

// maps actions to the connected Component's props.
// --> this.props.captureTimeStamp(Date.now()) would save that timestamp in the store
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            captureTimestamp: captureTimestamp,
            setNextGuiType: setNextGuiType,
            addAnswerToStore: addAnswerForQuestion,
            popAnswerFromStore: popAnswerForQuestion,
            questionNext: questionNext,
            questionPrevious: questionPrevious,
            setSubmitResultsObject: setTaskResult
        },
        dispatch
    );

// define the types of the props this component wants to receive
TemplateUI.propTypes = {
    taskUIContext: PropTypes.instanceOf(TaskUIContext).isRequired
};

// connects this component to the redux store
TemplateUI = connect(mapStateToProps, mapDispatchToProps)(TemplateUI);

// add instructions to the view
TemplateUI = createViewWithInstructions(TemplateUI);

// export for usage
export default TemplateUI;
