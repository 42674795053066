import { ActionTypes } from "../actions";
import generateReducer from "../../generateReducer";

const initialTaskRequest = {
    pending: false,
    task: null,
    error: null
};

export const taskRequestReducer = generateReducer(initialTaskRequest, {
    [ActionTypes.TASK_FETCH]: (taskRequest, action) => {
        return { ...taskRequest, pending: true };
    },
    [ActionTypes.TASK_RECEIVED]: (taskRequest, action) => {
        return { ...taskRequest, pending: false, task: action.task };
    },
    [ActionTypes.TASK_FETCH_ERROR]: (taskRequest, action) => {
        return { ...taskRequest, pending: false, error: action.error };
    }
});

export const getTaskRequest = state => state.global.taskRequest;
export const getTask = state => {
    return state.global.taskRequest.task;
};
