import { ActionTypes } from "../actions";

const initialCurrentQuestionIdx = 0;
const getCurrentQuestionIdxFromGlobalState = globalState => {
    if (globalState.currentQuestionIdx === undefined) {
        return initialCurrentQuestionIdx;
    } else {
        return globalState.currentQuestionIdx;
    }
};

export const currentQuestionIdxReducer = (globalState, action) => {
    const currentQuestionIdx = getCurrentQuestionIdxFromGlobalState(
        globalState
    );

    let questionIdx = currentQuestionIdx;
    switch (action.type) {
        case ActionTypes.QUESTION_NEXT:
            if (currentQuestionIdx + 1 < globalState.questionsAmount) {
                questionIdx++;
            }
            break;
        case ActionTypes.QUESTION_PREVIOUS:
            if (currentQuestionIdx > 0) {
                questionIdx--;
            }
            break;
        default:
            break;
    }
    return questionIdx;
};

export const getCurrentQuestionIdx = state => state.global.currentQuestionIdx;
